import React, { useState, useEffect } from "react";
import { Form, FormCheck } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { default as ReactSelect } from "react-select";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { SlCalender } from "react-icons/sl";
import moment from "moment";
import { getRandomColor, createImageFromInitials } from "../Utils";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "../Meeting.css";
import {
  getFunctionalGoalReviewMeetingData,
  postFunctionalGoalReviewMeetingData,
} from "../../store/actions";
import QuillEditor from "../../utils/QuillEditor";

const FunctionalGoalMeeting = (props) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [meetingId, setMeetingId] = useState(false);
  const [funReviewMeetingId, setFunReviewMeetingId] = useState(false);
  const [meetingName, setMeetingName] = useState("");
  const [owner, setOwner] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [isShared, setIsShared] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [agenda, setAgenda] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState();
  const [ownerIcon, setOwnerIcon] = useState("");
  const [notesId, setNotesId] = useState("");
  const [goalReviewId, setGoalReviewId] = useState(false);
  //   const [selectedBI, setSelectedBI] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const isMeetingPostFetched = useSelector(
    (state) => state.isMeetingPostFetched
  );

  const postedMeetingData = useSelector((state) => state.postedMeetingData);
  const fecthPostFunGoalMeetingReviewResponse = useSelector(
    (state) => state.fecthPostFunGoalMeetingReviewResponse
  );
  const fetchFunGoalMeetingReviewData = useSelector(
    (state) => state.fetchFunGoalMeetingReviewData
  );

  const [fetchResponse, setFetchResponse] = useState(false);

  const [meetings, setMeetings] = useState([]);
  console.log("meetings", meetings);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (fecthPostFunGoalMeetingReviewResponse && fetchResponse) {
      setFetchResponse(false);
      localStorage.setItem(
        "goal_review_id",
        fecthPostFunGoalMeetingReviewResponse.goal_review_id
      );
      localStorage.setItem(
        "note_id",
        fecthPostFunGoalMeetingReviewResponse.note_id
      );
      navigate("/bireview");
      window.location.reload();
    }
    dispatch(
      getFunctionalGoalReviewMeetingData(
        localStorage.getItem("functional_goal_id"),
        localStorage.getItem("bi_id")
      )
    );
  }, [fecthPostFunGoalMeetingReviewResponse]);

  useEffect(() => {
    if (fetchFunGoalMeetingReviewData) {
      setMeetings(fetchFunGoalMeetingReviewData.review_dt);
      setEmpData(fetchFunGoalMeetingReviewData.emp_data);
      console.log("review_dt", fetchFunGoalMeetingReviewData.review_dt);
    }
  }, [fetchFunGoalMeetingReviewData]);

  const handleAgendaOnChange = (content) => {
    setAgenda(content);
  };

  const handleCloseMeetingPopup = () => {
    onClose();
  };
  const handleIssharedClick = () => {
    setIsShared(!isShared);
    setIsPrivate(false);
  };
  useEffect(() => {
    const fullname = localStorage.getItem("full_name");
    if (fullname) {
      setOwner(fullname);
      setOwnerIcon(
        createImageFromInitials(30, fullname, getRandomColor(fullname))
      );
    }
  }, []);

  useEffect(() => {
    if (props.selectedMeeting !== "") {
      setSelectedRow(props.selectedMeeting);
    }
  }, []);
  const handleEmployeeChange = (selectedOptions) => {
    const updatedOptions = selectedOptions.filter((option) =>
      empData.some((emp) => emp.value === option.value)
    );
    setSelectedEmployees(updatedOptions);
  };
  const handleIsprivateClick = () => {
    setIsPrivate(!isPrivate);
    if (!isPrivate) {
      setIsShared(false);
    }
  };
  const handleRowClick = (row) => {
    setNotesId(row.meeting_details[0].notes_id);
    setMeetingId(row.meeting_details[0].meeting_id);
    setGoalReviewId(row.meeting_details[0].goal_review_id);
    setOwner(row.meeting_details[0].owner);
    setOwnerIcon(
      createImageFromInitials(
        30,
        row.meeting_details[0].owner,
        getRandomColor(row.meeting_details[0].owner)
      )
    );
    setSelectedRow(row.meeting_details[0].goal_review_id);
    setMeetingName(row.meeting_details[0].meeting);
    setAgenda(row.meeting_details[0].agenda);
    setMeetingDate(row.meeting_details[0].date);
    setMeetingTime(row.meeting_details[0].time);
    setFromDate(row.from_date);
    setToDate(row.to_date);
    const parsedDate = moment(
      row.meeting_details[0].date + " " + row.meeting_details[0].time,
      "YYYY-MM-DD HH:mm:ss"
    );
    if (parsedDate.isValid()) {
      setSelectedDate(parsedDate.toDate());
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleNewMeeting = () => {
    let emp_list = [];
    if (!isShared && !isPrivate) {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: selectedEmployees.some(
          (selectedEmp) => selectedEmp.value === emp.value
        ),
      }));
    } else if (!isPrivate) {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: true,
      }));
    }
    const date = formatDate(selectedDate);
    const time = formatTime(selectedDate);
    if (meetingName !== "") {
      let post_meeting_data = {
        bi_id: localStorage.getItem("bi_id"),
        title: meetingName,
        agenda: agenda,
        meeting_id: meetingId,
        date: date,
        time: time,
        is_private: isPrivate,
        is_shared: isShared,
        emp: emp_list,
        from_date: fromDate,
        to_date: toDate || "",
        goal_review_id: goalReviewId,
      };
      dispatch(
        postFunctionalGoalReviewMeetingData(
          localStorage.getItem("functional_goal_id"),
          post_meeting_data
        )
      );
    }

    // const fullname = localStorage.getItem("full_name");
    // if (fullname) {
    //   setOwner(fullname);
    //   setOwnerIcon(createImageFromInitials(30, owner, getRandomColor(owner)));
    // } else {
    //   setOwner("");
    //   setOwnerIcon("");
    // }
    // setMeetingName("New Meeting");
    // setMeetingDate("");
    // setMeetingTime("");
    // setAgenda("");
    // setSelectedRow(null);
    // setMeetingId(false);
    // setGoalReviewId(false);
    // setFromDate("");
    // setToDate("");
  };
  const handleNotesClick = () => {
    let emp_list = [];
    if (!isShared && !isPrivate) {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: selectedEmployees.some(
          (selectedEmp) => selectedEmp.value === emp.value
        ),
      }));
    } else if (!isPrivate) {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: true,
      }));
    }
    const date = formatDate(selectedDate);
    const time = formatTime(selectedDate);
    if (meetingName !== "") {
      let post_meeting_data = {
        bi_id: localStorage.getItem("bi_id"),
        title: meetingName,
        agenda: agenda,
        meeting_id: meetingId,
        date: date,
        time: time,
        is_private: isPrivate,
        is_shared: isShared,
        emp: emp_list,
        from_date: fromDate,
        to_date: toDate || "",
        goal_review_id: goalReviewId,
      };
      dispatch(
        postFunctionalGoalReviewMeetingData(
          localStorage.getItem("functional_goal_id"),
          post_meeting_data
        )
      );
      setFetchResponse(true);
    }
  };

  const CustomInput = ({ value, onClick }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: 8,
        border: "1px solid #ced4da",
        padding: "0px 8px",
        cursor: "pointer",
        width: "220px",
      }}
      onClick={onClick}
    >
      <input
        type="text"
        value={value}
        readOnly
        placeholder="yyyy-MM-dd HH:mm:ss"
        style={{
          flex: 1,
          border: "none",
          outline: "none",
          background: "transparent",
          margin: "5px 0",
        }}
      />
      <SlCalender />
    </div>
  );

  return (
    <div className="modal-overlay">
      <div
        className="modal-content meeting-popover"
        style={{
          padding: "20px",
          height: "90%",
          overflowY: "auto",
        }}
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              paddingTop: "5px",
              fontSize: "var(--main-heading)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            Functional Goal Meeting Planner
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <AiOutlineClose
              size={24}
              onClick={handleCloseMeetingPopup}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <hr style={{ borderTop: "1px solid" }} />
        <div className="modal-body" style={{ display: "flex" }}>
          <div
            className="modal-body-left"
            style={{
              width: "50%",
              border: "1px solid lightgray",
              background: "#ecf4f3",
              fontSize: "var(--sub-heading-font-size)",
            }}
          >
            <table
              className="meeting-table"
              style={{ width: "100%", textAlign: "center" }}
            >
              <thead
                style={{
                  backgroundColor: "#0A5F59",
                  color: "white",
                }}
              >
                <tr>
                  <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                    Meeting
                  </th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "10px" }}></tr>
                {meetings?.length > 0 ? (
                  meetings.map((val, key) => (
                    <tr
                      key={key}
                      onClick={() => handleRowClick(val)}
                      style={{
                        border:
                          selectedRow === val.meeting_details[0].goal_review_id
                            ? "2px solid #0A5F59"
                            : "none",
                        backgroundColor:
                          selectedRow === val.meeting_details[0].goal_review_id
                            ? "#D9F6E4"
                            : "transparent",
                        color:
                          selectedRow === val.meeting_details[0].goal_review_id
                            ? "#0A5F59"
                            : "inherit",
                      }}
                    >
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        {val.meeting_details[0].meeting}
                      </td>
                      <td>{val.meeting_details[0].date}</td>
                      <td>{val.meeting_details[0].time}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        height: "250px",
                        verticalAlign: "middle",
                        backgroundColor: "rgb(236, 244, 243)",
                      }}
                    >
                      No meetings available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="modal-body-right"
            style={{ width: "50%", paddingLeft: "20px" }}
          >
            <div className="row">
              <div className="col-12">
                <Form.Group
                  controlId="formMeetingName"
                  style={{
                    marginTop: 0,
                  }}
                >
                  <Form.Control
                    type="text"
                    value={meetingName}
                    onChange={(e) => setMeetingName(e.target.value)}
                    placeholder="Add Meeting Title"
                    required
                  />
                </Form.Group>
              </div>
            </div>
            {/* <h6>{meetingName}</h6> */}
            <div className="row">
              <div className="col-12 col-lg-7 col-sm-12">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <b
                    style={{
                      paddingTop: "5px",
                      marginRight: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      textWrap: "nowrap",
                    }}
                  >
                    Schedule Date:
                  </b>
                  <div style={{ zIndex: 11 }}>
                    <DatePicker
                      placeholderText="yyyy-MM-dd HH:mm:ss"
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      showYearDropdown
                      scrollableYearDropdown
                      customInput={<CustomInput />}
                      showMonthDropdown
                      className="custom-datepicker"
                      yearDropdownItemNumber={1000}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-sm-12">
                <div
                  // className="custom-datepicker"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      paddingRight: "5px",
                    }}
                  >
                    Owner:
                  </div>

                  <img alt="" src={ownerIcon} />
                  {owner}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6 col-sm-12">
                <div
                  className="custom-datepicker"
                  style={{
                    // position: "relative",
                    // zIndex: 999,
                    display: "flex",
                  }}
                >
                  <b
                    style={{
                      paddingRight: "5px",
                      paddingTop: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    From&nbsp;Date:
                  </b>
                  <input
                    style={{ width: "190px" }}
                    type="date"
                    className="form-control"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-sm-12">
                <div
                  className="custom-datepicker"
                  style={{
                    // position: "relative",
                    // zIndex: 999,
                    display: "flex",
                  }}
                >
                  <b
                    style={{
                      paddingRight: "5px",
                      paddingTop: "5px",
                      paddingLeft: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    To&nbsp;Date:
                  </b>
                  <input
                    style={{ width: "190px" }}
                    type="date"
                    className="form-control"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Form.Group controlId="formAdditionalField">
              <strong>
                <Form.Label
                  style={{
                    marginBottom: "0px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Agenda:
                </Form.Label>
              </strong>
              <div style={{ height: "380px" }}>
                <QuillEditor
                  value={agenda}
                  onChange={handleAgendaOnChange}
                  height={300}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formCheckbox" style={{ marginTop: "10px" }}>
              <Form.Check
                type="checkbox"
                label="Share with everyone"
                checked={isShared}
                onChange={() => handleIssharedClick()}
              />
            </Form.Group>
            {!isShared && !isPrivate && (
              <Form.Group>
                <span
                  data-toggle="popover"
                  data-trigger="focus"
                  data-content="Please selecet employee(s)"
                >
                  <Select
                    options={empData}
                    value={selectedEmployees}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={e.icon} />
                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                      </div>
                    )}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={handleEmployeeChange}
                    allowSelectAll
                  />
                </span>
              </Form.Group>
            )}
            <Form.Group
              controlId="formCheckbox"
              style={{ paddingTop: "10px", paddingBottom: "30px" }}
            >
              <Form.Check
                type="checkbox"
                label="Private"
                checked={isPrivate}
                onChange={() => handleIsprivateClick()}
              />
            </Form.Group>
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            marginTop: 20,
            flexWrap: "wrap",
            justifyContent: "space-between",
            bottom: 20,
            paddingLeft: "15px",
            paddingRight: "15px",
            zIndex: 3,
          }}
        >
          <button
            style={{
              width: "40%",
              backgroundColor: "#0A5F59",
              color: "white",
              borderRadius: 10,
              height: 48,
              border: "thin ridge",
              marginRight: 20,
              fontSize: "var(--sub-heading-font-size)",
            }}
            onClick={() => handleNewMeeting()}
          >
            Schedule Meeting
          </button>
          <button
            style={{
              width: "40%",
              backgroundColor: "#0A5F59",
              borderColor: "#0A5F59",
              color: "white",
              borderRadius: 10,
              height: 48,
              border: 0,
              fontSize: "var(--sub-heading-font-size)",
            }}
            onClick={(e) => handleNotesClick()}
          >
            Go to meeting
            <FaArrowRight style={{ marginLeft: "5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FunctionalGoalMeeting;
