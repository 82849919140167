import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEcosystemMappingSectionDetails } from "../../store/actions";
import keys from "../../utils/helpInfoKeys.json";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";

const Ecosystemsummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [communityData, setCommunityData] = useState([]);
  const fetchEcosystemMappingSectionData = useSelector(
    (state) => state.fetchEcosystemMappingSectionData
  );
  useEffect(() => {
    dispatch(getEcosystemMappingSectionDetails(localStorage.getItem("bi_id")));
  }, []);
  useEffect(() => {
    if (
      fetchEcosystemMappingSectionData &&
      fetchEcosystemMappingSectionData.sections.length > 0
    ) {
      setCommunityData(fetchEcosystemMappingSectionData.sections);
    }
  }, [fetchEcosystemMappingSectionData]);

  const handleBack = () => {
    navigate("/dashboard", { replace: true });
  };

  const handleClick = () => {
    navigate("/strategyplansummary", { replace: true });
  };
  const content = (
    <>
      <div>
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
        <div class="eco-column-container">
          {communityData.map((root) => (
            <>
              <div key={root.id} className="eco_reports_root_name">
                {root.name}
              </div>
              {root.sub_section.length > 0 &&
                root.sub_section.map((subSection) => (
                  <>
                    <ol
                      key={subSection.id}
                      className="eco_reports_list eco_reports_subsection"
                    >
                      <li className="name">{subSection.name}</li>
                    </ol>
                    {subSection.childrens.length > 0 &&
                      subSection.childrens.map((child) => (
                        <>
                          <div>
                            <ol
                              key={child.id}
                              className="eco_reports_list  eco_reports_child"
                            >
                              <li className="name">{child.name}</li>
                            </ol>
                          </div>

                          {child.childrens.length > 0 ? (
                            child.childrens.map((subChild) => (
                              <>
                                <ol
                                  key={subChild.id}
                                  className="eco_reports_list  eco_reports_subchild"
                                >
                                  <li>{subChild.name}</li>
                                </ol>
                              </>
                            ))
                          ) : (
                            <div className="eco_reports_no_data">no data</div>
                          )}
                        </>
                      ))}
                  </>
                ))}
            </>
          ))}
        </div>
      </div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Reports"}
        breadcrumbChild={"Ecosystem Summary"}
        documentName={"Ecosystem Summary"}
        helpKey={keys["Ecosystem Summary"].Name}
        image={"question"}
      />
    );
  }
};

export default Ecosystemsummary;
