import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import the js-cookie library

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      Cookies.remove("sid");
      localStorage.clear();
      // Navigate to home or login page
      navigate("/");
    };
    handleLogout();
  }, [navigate]);

};

export default Logout;
