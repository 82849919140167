import { useState, useEffect } from "react";
import { url } from "../store/sagas/API.js";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./SecondSideBarComponent.css";

function SecondSideBarComponent({
  item,
  index,
  childclass,
  setIsSecSideBarOpen,
  parentID,
  ischild = false,
  onMenuOpen,
}) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const screen_id = useSelector((state) => state.screen_id);

  useEffect(() => {
    if (
      location.pathname === item.url ||
      (item.children &&
        item.children.some((child) => location.pathname === child.url)) ||
      (item.children &&
        item.children.some((child) =>
          child.children.some(
            (grandchild) => location.pathname === grandchild.url
          )
        ))
    ) {
      setOpen(true);
      if (onMenuOpen) {
        onMenuOpen(parentID);
      }
    }
  }, [item.url, item.children, location.pathname, onMenuOpen, parentID]);

  const handleNestedItemClick = (event) => {
    event.stopPropagation();
    if (!open && onMenuOpen) {
      onMenuOpen(parentID);
    }
  };

  const isActive = parentID === item.id || (ischild && open);
  const isItemActive = location.pathname === item.url;

  const hasActiveChild =
    item.children &&
    item.children.some((child) => {
      if (location.pathname.startsWith(child.url)) {
        return true;
      }
      if (child.children) {
        return child.children.some((grandchild) =>
          location.pathname.startsWith(grandchild.url)
        );
      }
      return false;
    });

  const navigateToFirstChild = () => {
    const firstChild = item.children && item.children[0];
    if (firstChild && firstChild.url) {
      navigate(firstChild.url);
    }
  };

  const handleNavLinkClick = () => {
    localStorage.setItem("old_screen_id", screen_id);
  };

  if (item.children && item.children.length > 0) {
    return (
      <div
        id={item.id}
        className={`secondsidebar-item ${open ? "open" : ""} ${
          isActive ? "active" : ""
        }`}
        onClick={() => {
          setOpen(!open);
          // setIsSecSideBarOpen(false);
          if (!open && onMenuOpen) {
            onMenuOpen(parentID);
          }
          navigateToFirstChild();
        }}
      >
        <div
          className={`${
            isActive || hasActiveChild
              ? "active linkfirstsidebar-item"
              : "secondsidebar-title linkfirstsidebar-item"
          }`}
        >
          <span>{item.icon && <img src={url + item.icon} alt="" />}</span>
          <div
            className={`link_text ${
              isActive || hasActiveChild ? "active" : ""
            } ${isItemActive ? "active-item" : ""}`}
          >
            {item.title}
          </div>
        </div>
        <div
          style={{ marginLeft: 40 }}
          className={`sidebar-content ${open ? "open" : ""}`}
        >
          {item.children.map((child, index) => (
            <div key={index} onClick={handleNestedItemClick}>
              <SecondSideBarComponent
                item={child}
                index={index}
                childclass="child-link"
                setIsSecSideBarOpen={(value) => {
                  setIsSecSideBarOpen(value);
                }}
                parentID={item.id}
                ischild={true}
                onMenuOpen={onMenuOpen}
              />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        to={item.url}
        key={index}
        className={childclass}
        style={{ lineHeight: "normal" }}
        activeclassname="active"
        onClick={handleNavLinkClick}
      >
        <span>{item.icon && <img src={url + item.icon} />}</span>
        <div style={{ display: "block" }} className="link_text">
          {item.title}
        </div>
      </NavLink>
    );
  }
}

export default SecondSideBarComponent;
