import React from "react";
import Select from "react-select";

const BISelector = ({ showSelector, selectedBI, biData, handleSelect }) => {
  const newbuttonClicked = JSON.parse(localStorage.getItem("newbuttonClicked"));

  const customStyles = (borderStyle) => ({
    control: (provided, state) => ({
      ...provided,
      ...borderStyle,
      borderRadius: "10px",
      marginRight: "20px",
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 99999,
      backgroundColor: state.isSelected
        ? "#ecf4f3"
        : state.isFocused
        ? "#ecf4f3"
        : "white",
      color: "black",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
  });

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const redBorderStyle = {
    border: newbuttonClicked ? "" : "2px solid red",
  };

  return (
    <div style={{minWidth:'250px'}}>
      <Select
        styles={customStyles(!selectedBI ? redBorderStyle : {})}
        required
        name="bi_id"
        className={showSelector ? "selectbir" : "selectbir hide"}
        placeholder="Select BI"
        value={selectedBI}
        options={biData}
        onChange={handleSelect}
        isSearchable={true}
        filterOption={customFilter}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                marginLeft: 5,
                fontSize: "var(--sub-heading-font-size)",
              }}
            >
              {e.label}
            </span>
          </div>
        )}
      />
    </div>
  );
};

export default BISelector;
