import { useEffect, useReducer, useState } from "react";
import {
  ValueChainInitialState,
  ValueChainMappingReducer,
} from "../../../store/state/ValueChainReducer";
import {
  InitValueChain,
  OnColumnsSelectionChanged,
  OnMappingSelectionChanged,
  OnMenuChange,
  OnProductSelectionChanged,
  OnVCChange,
  OnVCUpdate,
  toggleEditModal,
} from "../../../store/state/ValueChainActions";
import keys from "../../../utils/helpInfoKeys.json";
import VCTabs from "../../VCTabs";
import "./Mapping.css";
import VCTable from "../../VCTable";
import VCEditModal from "../../VCEditModalTest";
import MainComponent from "../../MainComponent";
import { useNavigate } from "react-router-dom";
import {
  addCustomerVCSelection,
  getCustomerVCSelection,
  vcChangeTableData,
} from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { BsPencil } from "react-icons/bs";
import CommentSection from "../../../utils/CommentSection";
import Login from "../../Login";
import SideNavHelpInfo from "../../../utils/SideNavHelpInfo";

const ValueChainMapping = (props) => {
  const dispatch = useDispatch();
  const [mappingState, dispatchVcAction] = useReducer(
    ValueChainMappingReducer,
    ValueChainInitialState
  );
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const navigate = useNavigate();
  const getCustomerVCSelectionData = useSelector(
    (state) => state.getCustomerVCSelectionData
  );
  const isAddCustomerVCSelection = useSelector(
    (state) => state.isAddCustomerVCSelection
  );
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const isInitialVCFetched = useSelector((state) => state.isInitialVCFetched);
  const isUpdatedVCFetched = useSelector((state) => state.isUpdatedVCFetched);
  const isVCEdited = useSelector((state) => state.isVCEdited);
  const isVCFetched = useSelector((state) => state.isVCFetched);
  const addCustomerVCSelectionData = useSelector(
    (state) => state.addCustomerVCSelectionData
  );

  const [showEditModal, setShowEditModal] = useState(false);
  const [vcEdited, setVCEdited] = useState(false);
  const [vcFetched, setVCFetched] = useState(false);
  useEffect(() => {
    dispatch(
      getCustomerVCSelection(localStorage.getItem("bi_id"), null, true, false)
    );
  }, []);
  useEffect(() => {
    if (isInitialVCFetched) {
      if (getCustomerVCSelectionData) {
        if (getCustomerVCSelectionData.ok) {
          dispatchVcAction(
            InitValueChain(getCustomerVCSelectionData.data.message)
          );
        }
      }
    }
  }, [isInitialVCFetched]);

  useEffect(() => {
    if (isUpdatedVCFetched) {
      if (getCustomerVCSelectionData) {
        if (getCustomerVCSelectionData.ok) {
          dispatchVcAction(
            OnVCUpdate(getCustomerVCSelectionData.data.message[0])
          );
        }
      }
    }
  }, [isUpdatedVCFetched, isVCFetched]);

  useEffect(() => {
    if (isAddCustomerVCSelection) {
      if (addCustomerVCSelectionData.ok) {
        setVCFetched(!vcFetched);
        if (mappingState.valueChains.activeValueChain) {
          dispatch(
            getCustomerVCSelection(
              localStorage.getItem("bi_id"),
              mappingState.valueChains.activeValueChain.key,
              false,
              true,
              vcFetched
            )
          );
        }
      } else {
        return <div>{alert(addCustomerVCSelectionData.data)}</div>;
      }
    }
  }, [isAddCustomerVCSelection, isVCEdited]);

  const handleVCChange = (e) => {
    dispatchVcAction(OnVCChange(e));
  };

  const onProductSelectionChanged = (
    productId,
    columnKey,
    menuId,
    is_selected,
    valueChain
  ) => {
    let updatedValueChain = { ...valueChain };
    console.log(updatedValueChain);
    let updatedCategories = [...updatedValueChain.categories];
    updatedCategories = updatedCategories.map((obj) => {
      if (obj.key === menuId) {
        console.log(obj);
        let menuData = [...obj.data];
        let updated = menuData.map((d) => {
          if (d.id === productId) {
            d = { ...d, [columnKey]: !d[columnKey] };
          }
          return d;
        });
        obj = { ...obj, data: updated };
      }
      return obj;
    });
    updatedValueChain = { ...updatedValueChain, categories: updatedCategories };

    dispatchVcAction(OnMappingSelectionChanged(updatedValueChain));

    dispatch(
      vcChangeTableData(
        sessionStorage.getItem("bi_id"),
        menuId,
        productId,
        columnKey,
        is_selected
      )
    );
  };

  const onEdit = (vc) => {
    dispatchVcAction(toggleEditModal(vc));
    setShowEditModal(!showEditModal);
  };

  const onMenuChanged = (category, vcKey) => {
    dispatchVcAction(OnMenuChange(category, vcKey));
  };
  const onSave = (updatedValueChain) => {
    dispatchVcAction(OnColumnsSelectionChanged(updatedValueChain));
    setShowEditModal(!showEditModal);
    setVCEdited(!vcEdited);
    dispatch(
      addCustomerVCSelection(
        localStorage.getItem("bi_id"),
        [updatedValueChain],
        false,
        vcEdited
      )
    );
  };
  const handleClick = () => {
    navigate("/dashboard", { replace: true });
  };
  const handleBack = () => {
    navigate("/vcselection");
    window.location.reload();
  };
  const onClose = () => {
    console.log("sffsd");
    setShowEditModal(!showEditModal);
  };
  var content = (
    <div>
      <div>
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Value Chain Mapping
        </div>
        <br />
        <div
          style={{
            fontSize: "var(--text-font-size)",
            paddingBottom: "10px",
          }}
        >
          Please identify and map the areas where the product operates in the
          selected value chain.
        </div>
        {mappingState.valueChains.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                overflow: "auto",
                whiteSpace: "nowrap",
              }}
            >
              {mappingState.valueChains.map((vc) => (
                <div
                  className={
                    mappingState.valueChains.activeValueChain.key === vc.key
                      ? "addedvc-menu active"
                      : "addedvc-menu"
                  }
                >
                  <div
                    style={{
                      fontSize: "var(--text-font-size)",
                    }}
                    key={vc.key}
                    data-menu-id={vc.key}
                    data-selector={vc.is_master}
                    onClick={() => handleVCChange(vc)}
                  >
                    {vc.value}
                  </div>
                  {/* {(selectedPermission === "write" || selectedPermission === "admin") &&
                  <button
                    style={{
                      marginLeft: 10,
                      background: "white",
                      height: 17,
                      width: 17,
                      borderRadius: 17,
                      border: "thin ridge",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 3,
                    }}
                    type="button"
                    onClick={(e) => onEdit(vc)}
                  >
                    <span style={{ fontSize: 10, paddingBottom: 2 }}>
                      <BsPencil />
                    </span>
                  </button>} */}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div style={{ height: "70%" }}>
        {mappingState.valueChains.activeValueChain && (
          <div style={{ height: "100%" }}>
            {showEditModal && (
              <VCEditModal
                key={mappingState.valueChains.activeValueChain.key}
                onSave={onSave}
                onClose={onClose}
                selectedMenu={
                  mappingState.valueChains.activeValueChain.activeMenu
                }
                vcKey={mappingState.valueChains.activeValueChain.key}
                vcTitle={mappingState.valueChains.activeValueChain.title}
                valueChain={mappingState.valueChains.activeValueChain}
              />
            )}
            {mappingState.valueChains.activeValueChain.activeMenu && (
              <VCTabs
                menus={mappingState.valueChains.activeValueChain.categories}
                selectedMenu={
                  mappingState.valueChains.activeValueChain.activeMenu
                }
                onMenuChanged={onMenuChanged}
                vcKey={mappingState.valueChains.activeValueChain.key}
              />
            )}
            {mappingState.valueChains.activeValueChain.activeMenu && (
              <div className="mapping-table">
                <VCTable
                  selectedMenu={
                    mappingState.valueChains.activeValueChain.activeMenu
                  }
                  previewvc={false}
                  valueChain={mappingState.valueChains.activeValueChain}
                  productData={
                    mappingState.valueChains.activeValueChain.activeMenu.data
                  }
                  onProductSelectionChanged={onProductSelectionChanged}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <CommentSection />
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
    </div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          handleNext={handleClick}
          handleBackButton={handleBack}
          showSelector={true}
          showButtons={true}
          breadcrumbParent={"Onboarding"}
          breadcrumbChild={"Mapping"}
          documentName={"Mapping"}
          helpKey={keys["Mapping"].Name}
          image={"question"}
        />
      </>
    );
  }
};

export default ValueChainMapping;
