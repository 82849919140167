import React, { useEffect, useState } from "react";
import Category from "./strategyReadiness/Category";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import { FINAL_DATA, SALES_FINAL_DATA } from "./Data";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import keys from "../../utils/helpInfoKeys.json";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allStrategyQuestionsList = useSelector(
    (state) => state.allStrategyQuestionsList
  );
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [categoryList, setCategoryList] = useState([]);
  const [functionName, setFunctionName] = useState("");
  const fetchStrategyReadinessFunAreaData = useSelector(
    (state) => state.fetchStrategyReadinessFunAreaData
  );
  const fetchpostStrategyReadinessCustomerInputDetails = useSelector(
    (state) => state.fetchpostStrategyReadinessCustomerInputDetails
  );
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (
      fetchpostStrategyReadinessCustomerInputDetails &&
      (fetchpostStrategyReadinessCustomerInputDetails.action === "add" ||
        fetchpostStrategyReadinessCustomerInputDetails.action === "delete")
    ) {
      window.location.reload();
    }
  }, [fetchpostStrategyReadinessCustomerInputDetails]);

  useEffect(() => {
    dispatch(
      Actions.getStrategyReadinessFunctionalAreaDetails(
        localStorage.getItem("function_id"),
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        localStorage.getItem('main_screen_id'),
        localStorage.getItem('screen_id')
      )
    );
  }, []);

  useEffect(() => {
    if (
      fetchStrategyReadinessFunAreaData &&
      fetchStrategyReadinessFunAreaData.functions &&
      fetchStrategyReadinessFunAreaData.functions.length > 0
    ) {
      setFunctionName(fetchStrategyReadinessFunAreaData.functions[0].name);
      dispatch(
        Actions.setAllStrategyQuestions(
          fetchStrategyReadinessFunAreaData.functions[0].sections
        )
      );
      // dispatch(Actions.setAllStrategyQuestions(FINAL_DATA))
      // dispatch(Actions.setAllQuestions(SALES_FINAL_DATA));
    }
  }, [fetchStrategyReadinessFunAreaData]);

  useEffect(() => {
    if (allStrategyQuestionsList && allStrategyQuestionsList.length > 0) {
      setCategoryList(allStrategyQuestionsList);
    }
  }, [allStrategyQuestionsList]);
  const handleBack = () => {
    navigate("/strategyreadiness", { replace: true });
  };

  const content = (
    <>
      <div
        className="App"
        style={{ textAlign: "left", fontFamily: "Source Sans Pro" }}
      >
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          {functionName}
        </div>
        <br />
        <Category category={categoryList} />
        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      </div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showProducts={true}
        breadcrumbShowGt={true}
        showSubGt={true}
        handleBackButton={handleBack}
        NextButtonDisabled={true}
        breadcrumbParent={"Enterprise Readiness"}
        breadcrumbSubChild={functionName}
        breadcrumbChild={"Strategy Readiness"}
        breadcrumbChildLink={"/strategyreadiness"}
        documentName={"Strategy Readiness Function"}
        helpKey={keys["Strategy Readiness Function"].Name}
        image={"question"}
      />
    );
  }
}
