import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { getXLExecutionPlanDetails } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import keys from "../../utils/helpInfoKeys.json";

import "./reports.css";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";
const Strategyplansummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const strategyPlanData = useSelector((state) => state.strategyPlanData);

  const [objective, setObjective] = useState("");
  const [goalList, setGoalList] = useState([]);
  const [functionsList, setFunctionList] = useState([]);
  useEffect(() => {
    dispatch(
      getXLExecutionPlanDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);
  useEffect(() => {
    if (strategyPlanData) {
      setObjective(strategyPlanData?.objective[0]?.name);
      setGoalList(strategyPlanData.goals);
      setFunctionList(strategyPlanData.functions);
    }
  }, [strategyPlanData]);
  const handleBack = () => {
    navigate("/ecosystemsummary", { replace: true });
  };

  const handleClick = () => {
    navigate("/projectsummary", { replace: true });
  };
  const content = (
    <>
      <div
        style={{
          margin: "0px 0px 20px 20px",
          fontSize: "var(--main-heading)",
          fontWeight: "var(--heading-font-weight)",
        }}
      >
        Strategy Plan Summary Report
      </div>
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      <div
        style={{ width: "90%", marginLeft: "20px" }}
        className="strategyplansummary"
      >
        <table>
          <tbody>
            <tr>
              <td width={300}>
                <div
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  OBJECTIVE:
                </div>
                <div style={{ fontSize: "var(--text-font-size)" }}>
                  {objective}
                </div>
              </td>
              <td>
                <div
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  GOALS:
                </div>
                {goalList.map((goal, index) => (
                  <div
                    style={{
                      marginLeft: "20px",
                      fontSize: "var(--text-font-size)",
                    }}
                  >
                    {index + 1}
                    {"."}
                    {goal.name}
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                FUNCTIONS:
              </td>
              <td
                style={{
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                FUNCTIONAL GOALS & MILESTONES:
              </td>
            </tr>
            {functionsList &&
              functionsList.length > 0 &&
              functionsList.map((obj, index) => (
                <>
                  <tr width={300}>
                    <td style={{ fontSize: "var(--text-font-size)" }}>
                      {obj.name}
                    </td>
                    <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                      {obj &&
                        obj.strategic_imperative.map((stImp, id) => (
                          <>
                            <div
                              style={{
                                fontSize: "var(--text-font-size)",
                              }}
                            >
                              {stImp.name}
                            </div>
                            {stImp &&
                              stImp.milestones &&
                              stImp.milestones.map((mile) => (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: "var(--text-font-size)",
                                    }}
                                  >
                                    <div style={{ marginLeft: "20px" }}>
                                      {mile.name}
                                    </div>
                                    {mile.assigned_to &&
                                      mile.assigned_to
                                        .filter(
                                          (item) => item.is_active === true
                                        )
                                        .map((activeItem) => (
                                          <span key={activeItem.value}>
                                            ({activeItem.label})
                                          </span>
                                        ))}
                                    {mile.exp_end_date && (
                                      <div>({mile.exp_end_date})</div>
                                    )}
                                  </div>
                                </>
                              ))}
                          </>
                        ))}
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Reports"}
        breadcrumbChild={"Strategy Plan Summary"}
        showProducts={true}
        documentName={"Strategy Plan Summary"}
        helpKey={keys["Strategy Plan Summary"].Name}
        image={"question"}
      />
    );
  }
};

export default Strategyplansummary;
