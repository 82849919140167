import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHelpInfo } from "../store/sagas/API";

const SideNavHelpInfo = () => {
  const selectedHelpInfo = useSelector((state) => state.selectedHelpInfo);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const closeModal = () => {
    dispatch({ type: "SET_SELECTED_HELP_INFO_SIDEBAR", value: false });
  };
console.log('selectedHelpInfo',selectedHelpInfo)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHelpInfo(
          selectedHelpInfo.documentName,
          selectedHelpInfo.helpKey
        );
        setData(response.info[0]);
      } catch (error) {
        console.error("Error fetching help information:", error);
      }
    };
    fetchData();
  }, [selectedHelpInfo.documentName, selectedHelpInfo.helpKey]);

  return (
    <div>
      <div
        style={{
          width: "50%",
          zIndex:9999
        }}
        className={"sidenav"}
      >
        <div
          className="gtm-sidebar-header"
          style={{
            borderBottom: "1px solid lightgrey",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              fontSize: "var(--sub-heading-font-size)",
              fontWeight: "var( --heading-font-weight)",
            }}
          >
            Learn More &gt; {data.name}
          </h5>
          <a href="#" className="closebtn" onClick={closeModal}>
            &times;
          </a>
        </div>
        <div className="gtm-sidebar-body">
          <div
            style={{
              fontSize: "var(--sub-heading-font-size)",
            }}
          >
            {data.info}
          </div>
          <div
            style={{
              fontSize: "var(--sub-heading-font-size)",
            }}
            dangerouslySetInnerHTML={{ __html: data.extra_info }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SideNavHelpInfo;
