export const InitValueChain = (chains) => {
  return {
    type: "INIT",
    payload: { valueChains: chains },
  };
};

export const OnVCChange = (valueChain) => {
  return { type: "VALUE_CHAIN_CHANGE", payload: { valueChain: valueChain } };
};

export const OnVCRemove = (vcKey) => {
  return { type: "VALUE_CHAIN_REMOVE", payload: { vcKey: vcKey } };
};
export const OnVCAdd = (valueChain) => {
  return { type: "VALUE_CHAIN_ADD", payload: { valueChain: valueChain } };
};

export const OnMenuChange = (category, vcKey) => {
  return { type: "MENU_CHANGE", payload: { category: category, vcKey: vcKey } };
};

export const toggleEditModal = (vcKey) => {
  return {
    type: "TOGGLE_EDIT_MODAL",
    payload: { vcKey: vcKey },
  };
};

export const OnMappingSelectionChanged = (valueChain) => {
  return {
    type: "ON_SELECTION_CHANGE",
    payload: {
      valueChain: valueChain,
    },
  };
};

export const OnColumnsSelectionChanged = (valueChain) => {
  return {
    type: "COLUMNS_CHANGE",
    payload: {
      updated: valueChain,
    },
  };
};
export const OnProductSelectionChanged = (valueChain) => {
  return {
    type: "PRODUCTS_CHANGE",
    payload: {
      valueChain: valueChain,
    },
  };
};

export const OnVCUpdate = (valueChain) => {
  return {
    type: "VC_UPDATE",
    payload: {
      valueChain: valueChain,
    },
  };
};
