import React from "react";
import Select from "react-select";
import { useState } from "react";
import { useSelector } from "react-redux";

const GoalSelector = ({
  options,
  selectedGoals,
  handleGoalChange,
  disabled,
}) => {
  const customStyles = (borderStyle) => ({
    control: (provided, state) => ({
      ...provided,
      ...borderStyle,
      borderRadius: "10px",
      marginRight: "20px",
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: state.isSelected ? "#ecf4f3" : "white",
      color: "black",
    }),
  });

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Select
      className="goal-selector"
      classNamePrefix="custom-goal-select"
      options={options}
      value={selectedGoals}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions
      getOptionLabel={(e) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              marginLeft: 5,
              fontSize: "var(--sub-heading-font-size)",
            }}
          >
            {e.label}
          </span>
        </div>
      )}
      placeholder={
        <span style={{ fontSize: "var(--text-font-size)" }}>
          Select Goal
        </span>
      }
      onChange={handleGoalChange}
      allowSelectAll
      styles={{
        // Remove padding and background for selected options
        multiValue: (base) => ({
          ...base,
          // background: "transparent",
          padding: "0 2px",
          margin: 1,
          textAlign: "center",
        }),
        multiValueLabel: (base) => ({
          ...base,
          // padding: 0,
        }),
        multiValueGeneric: (base) => ({
          ...base,
          paddingLeft: 0, // Set padding-left to 0px to remove it
        }),
      }}
      components={
        {
          // DropdownIndicator: () => null,
          // ClearIndicator: () => null,
          // IndicatorSeparator: () => null,
          // MultiValueRemove: () => null,
          // Option: CustomOption,
        }
      }
      isDisabled={disabled}
    />
  );
};
export default GoalSelector;
