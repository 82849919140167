import React, { useEffect, useState, forwardRef, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import "./Comment.css";

import { getRandomColor, createImageFromInitials } from "../Utils";
import { Button } from "react-bootstrap";
import EditIcon from "../../assets/images/Edit.svg";
import DeleteIcon from "../../assets/images/trash.svg";
import { postTaskComments } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const CommentItemComponent = ({
  comment,
  onDelete,
  onSaveEdit,
  textAreaRef,
  currentUser,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(comment.content);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const username = localStorage.getItem("full_name");
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedText(comment.content);
    setMenuOpen(false); // Close the menu
  };

  const handleSaveEdit = (id, desc) => {
    onSaveEdit(id, desc);
    setIsEditing(false);
    setMenuOpen(false); // Close the menu
  };

  return (
    <div
      className="comment-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* <div className="comment-author">
        {comment.comment_by && comment.comment_by[0].name}
      </div> */}
      <div>
        {currentUser === comment?.comment_by[0].id
          ? "You commented on this" + " " + comment.creation
          : comment?.comment_by[0].name +
            " commented on this " +
            comment.creation}
      </div>
      {isEditing ? (
        <>
          <div className="textarea" style={{ position: "relative" }}>
            <textarea
              className="comment-textarea"
              rows={3}
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                style={{
                  width: "fit-content",
                  backgroundColor: "transparent",
                  color: "black",
                  fontSize: "var(--sub-heading-font-size)",
                  border: "1px solid #ccc",
                  padding: "5px 10px",
                  marginRight: "10px",
                }}
                onClick={handleCancelEdit}
                type="submit"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleSaveEdit(comment.name, editedText)}
                type="submit"
                style={{
                  width: "fit-content",
                  backgroundColor: "rgb(10, 95, 89)",
                  fontSize: "var(--sub-heading-font-size)",
                  padding: "5px 10px",
                  marginRight: "10px",
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="comment">
          <div className="comment-text" ref={textAreaRef}>
            {comment.content}
          </div>
          <div className="comment-icons">
            {comment.comment_by &&
              currentUser === comment.comment_by[0].id &&
              isHovered && (
                <FontAwesomeIcon icon={faEllipsisH} onClick={handleMenuClick} />
              )}
            {isMenuOpen && (
              <div className="menu-options">
                <div className="Edit">
                  <div className="EditOption" onClick={handleEditClick}>
                    <img
                      src={EditIcon}
                      width={"18px"}
                      style={{ marginRight: "10px" }}
                      alt="Edit Icon"
                    />
                    Edit
                  </div>
                  <div
                    className="DeleteOption"
                    onClick={() => onDelete(comment.name)}
                  >
                    <img
                      src={DeleteIcon}
                      width={"18px"}
                      style={{ marginRight: "10px" }}
                      alt="Edit Icon"
                    />
                    Delete
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const CommentSection = ({
  logo,
  fullScreenMode,
  taskId,
  commentsData,
  onAddComment,
  onEditComment,
  onDeleteComment,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const postTaskCommentsData = useSelector(
    (state) => state.postTaskCommentsData
  );
  const username = localStorage.getItem("full_name");
  const [commentedBy, setCommentedBy] = useState("");
  const [content, setContent] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const textAreaRef = useRef(null);
  useEffect(() => {
    if (postTaskCommentsData && postTaskCommentsData.action === "add") {
      textAreaRef.current.scrollIntoView({ behavior: "smooth" });
      textAreaRef.current.focus();
    }
  }, [commentsData]);
  useEffect(() => {
    if (
      postTaskCommentsData &&
      postTaskCommentsData.comment &&
      postTaskCommentsData.action === "add"
    ) {
      const newComment = {
        name: postTaskCommentsData.comment[0].name,
        comment_by: [{ name: username, id: 5 }],
        content: postTaskCommentsData.comment[0].content,
        icon: logo,
      };

      onAddComment(newComment);
    }
  }, [postTaskCommentsData]);

  const handleEdit = (name) => {
    const commentToEdit = commentsData.find((item) => item.name === name);

    setEditingCommentId(name);
    setContent(commentToEdit.content);
  };

  const handleSaveEditComment = (commentId, newContent) => {
    dispatch(postTaskComments(newContent, false, "edit", commentId));
    onEditComment(commentId, newContent);
    setEditingCommentId(null);
  };

  const handleDelete = (commentId) => {
    dispatch(postTaskComments(false, false, "delete", commentId));
    onDeleteComment(commentId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postTaskComments(content, taskId, "add", false));
    setCommentedBy("");
    setContent("");
  };

  return (
    <div className="comment-section-container ">
      <div className="comment-section-heading" style={{padding:'5px'}}>Comment Section</div>
      <div>
        <div style={{ position: "relative" }}>
          <div
            style={{
              backgroundColor: "#f8fafb",
            }}
          >
            <form
              onSubmit={handleSubmit}
              // style={{ display: "flex", justifyContent: "left" }}
            >
              <div style={{ width: "100%" }}>
                <textarea
                  disabled={
                    selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin"
                      ? false
                      : true
                  }
                  rows={3}
                  className="comment-textarea"
                  placeholder="Add a comment..."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  ref={textAreaRef}
                />
              </div>
              <Button
                disabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
                style={{
                  backgroundColor: "rgb(10, 95, 89)",
                  width: "fit-content",
                  marginLeft: "10px",
                }}
                className="submit-buttons"
                type="submit"
              >
                Comment
              </Button>
            </form>
            <div className="comment-list">
              {commentsData.length > 0 &&
                commentsData.map((commentItem) => (
                  <CommentItemComponent
                    textAreaRef={textAreaRef}
                    key={commentItem.name}
                    comment={commentItem}
                    onDelete={handleDelete}
                    onSaveEdit={handleSaveEditComment}
                    currentUser={currentUser}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
