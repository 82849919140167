// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import FolderIcon from "../assets/images/folder.svg";
// import { getMSDocs } from "../store/actions";

// const FolderManager = ({ onClose }) => {
//   const dispatch = useDispatch();
//   const [isURLMatching, setIsURLMatching] = useState(false);
//   const getMSDocsdata = useSelector((state) => state.getMSDocsdata);
//   const [folderData, setfolderData] = useState([]);
//   useEffect(() => {
//     if (getMSDocsdata) {
//       setfolderData(getMSDocsdata.message);
//     }
//   }, [getMSDocsdata]);
//   useEffect(() => {
//     dispatch(getMSDocs());
//   }, []);
//   useEffect(() => {
//     const currentURL = window.location.href;
//     if (currentURL.includes("/dashboard")) {
//       setIsURLMatching(true);
//     }
//   }, []);
//   return (
//     <div
//       style={{
//         width: "50%",
//         boxShadow: "-6px 1px 4px 0px #32302c1a",
//         top: isURLMatching ? 63 : 95,
//         height: isURLMatching ? "calc(100vh - 63px)" : "calc(100vh - 95px)",
//         zIndex: "999",
//       }}
//       className={"sidenav"}
//     >
//       <div
//         className="gtm-sidebar-header"
//         style={{
//           borderBottom: "1px solid lightgrey",
//           alignItems: "center",
//         }}
//       >
//         <h6
//           style={{
//             marginLeft: 20,
//             fontSize: "var(--sub-heading-font-size)",
//             fontWeight: "var(--heading-font-weight)",
//           }}
//         >
//           Upload File &gt; Folder Management
//         </h6>
//         <a href="#" className="closebtn" onClick={onClose}>
//           &times;
//         </a>
//       </div>
//       <div
//         className="gtm-sidebar-body"
//         style={{ display: "flex", flexDirection: "column" }}
//       >
//         <label
//           style={{
//             marginLeft: 20,
//             fontSize: "var(--sub-heading-font-size)",
//             fontWeight: "var(--heading-font-weight)",
//           }}
//         >
//           Folder Management
//         </label>
//         <div>
//           {folderData && <FolderStructure rootFolder={folderData[0]} />}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FolderManager;

// const FolderItem = ({ folder, level }) => {
//   const [isExpanded, setIsExpanded] = useState(false);
//   const handleToggle = () => {
//     setIsExpanded(!isExpanded);
//   };
//   const padding = `${level * 20}px`;

//   return (
//     <div style={{ margin: "10px 0px 0px 20px" }}>
//       <div style={{ display: "flex", flexDirection: "column" }}>
//         <div
//           style={{ display: "flex", flexDirection: "column", height: "20px" }}
//         >
//           <p
//             onClick={() => handleToggle(folder.id)}
//             style={{ cursor: "pointer", paddingLeft: padding }}
//           >
//             <button
//               style={{
//                 color: "black",
//                 fontSize: "18px",
//                 backgroundColor: "transparent",
//                 border: "none",
//                 fontSize: "var(--sub-heading-font-size)",
//               }}
//             >
//               <img src={FolderIcon} width={19} style={{ marginRight: "5px" }} />
//               {folder.name}
//             </button>
//           </p>
//         </div>
//       </div>
//       {isExpanded && folder.children && folder.children.length > 0 && (
//         <ul>
//           {folder.children.map((child, index) => (
//             <li key={index}>
//               {child.type === "application/vnd.google-apps.folder" ? (
//                 <FolderItem folder={child} level={level + 1} />
//               ) : (
//                 <a
//                   href={child.web_link}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   style={{
//                     paddingLeft: padding,
//                     margin: "10px 0px 0px 30px",
//                     fontSize: "16px",
//                     color: "#0d6efd",
//                     fontSize: "var(--sub-heading-font-size)",
//                   }}
//                 >
//                   {child.name}
//                 </a>
//               )}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// const FolderStructure = ({ rootFolder }) => {
//   return (
//     <div>{rootFolder && <FolderItem folder={rootFolder} level={0} />}</div>
//   );
// };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FolderIcon from "../assets/images/folder.svg";
import { getLocalFolderManager, getMSDocs } from "../store/actions";
import { url } from "../store/sagas/API.js";
import SheetImage from "../assets/images/icons8-excel.svg";
import DocImage from "../assets/images/icons8-microsoft-word.svg";
import PDFImage from "../assets/images/pdf-svgrepo-com.svg";
import PPTImage from "../assets/images/icons8-powerpoint.svg";
import TXTImage from "../assets/images/icons8-text.svg";
import OtherImage from "../assets/images/icons8-document.svg";
import NoDocImage from "../assets/images/nodoc.svg";

const FolderManager = ({ onClose }) => {
  const dispatch = useDispatch();
  const [isURLMatching, setIsURLMatching] = useState(false);
  const getMSDocsdata = useSelector((state) => state.getMSDocsdata);
  const [folderData, setfolderData] = useState([]);
  const fetchLocalManagerData = useSelector(
    (state) => state.fetchLocalManagerData
  );
  // useEffect(() => {
  //   if (getMSDocsdata) {
  //     setfolderData(getMSDocsdata.message);
  //   }
  // }, [getMSDocsdata]);

  useEffect(() => {
    if (fetchLocalManagerData) {
      setfolderData(fetchLocalManagerData.files);
    }
  }, [fetchLocalManagerData]);
  // useEffect(() => {
  //   dispatch(getMSDocs());
  // }, []);
  // useEffect(() => {
  //   const currentURL = window.location.href;
  //   if (currentURL.includes("/dashboard")) {
  //     setIsURLMatching(true);
  //   }
  // }, []);
  useEffect(() => {
    dispatch(getLocalFolderManager("", "", "", true));
  }, []);
  return (
    <div
      style={{
        boxShadow: "-6px 1px 4px 0px #32302c1a",
        top: 63,
        height: "calc(100vh - 63px)",
        zIndex: "999",
      }}
      className={"sidenav"}
    >
      <div
        className="gtm-sidebar-header"
        style={{
          borderBottom: "1px solid lightgrey",
          alignItems: "center",
        }}
      >
        <h6
          style={{
            marginLeft: 20,
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Upload File &gt; Folder Management
        </h6>
        <a href="#" className="closebtn" onClick={onClose}>
          &times;
        </a>
      </div>
      <div
        className="gtm-sidebar-body"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label
          style={{
            marginLeft: 20,
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Folder Management
        </label>
        <div>{folderData && <FolderStructure rootFolder={folderData} />}</div>
      </div>
    </div>
  );
};

export default FolderManager;

const FolderItem = ({ folder, level }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const padding = `${level * 10}px`;

  return (
    <div style={{ margin: "10px 0px 0px 20px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "20px" }}
        >
          <p
            onClick={handleToggle}
            style={{ cursor: "pointer", paddingLeft: padding }}
          >
            <button
              style={{
                color: "black",
                fontSize: "18px",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "var(--sub-heading-font-size)",
              }}
            >
              <img src={FolderIcon} width={19} style={{ marginRight: "5px" }} />
              {folder.name}
            </button>
          </p>
        </div>
      </div>
      {isExpanded && folder.subfolders && folder.subfolders.length > 0 && (
        <ul>
          {folder.subfolders.map((subfolder) => (
            <li key={subfolder.name}>
              <FolderItem folder={subfolder} level={level + 1} />
            </li>
          ))}
        </ul>
      )}
      {/* {isExpanded && folder.files && folder.files.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>File</th>
              <th>Modified</th>
              <th>Modified By</th>
            </tr>
          </thead>
          <tbody>
            {folder.files?.length > 0 &&
              folder.files?.map((obj) => (
                <tr key={obj.name}>
                  <td>
                    <a
                      href={obj.file_url}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        paddingLeft: "50px",
                      }}
                    >
                      <img
                        src={
                          obj.img
                            ? url + obj.img
                            : obj.file_name.split(".").pop() === "xls" ||
                              obj.file_name.split(".").pop() === "xlsx" ||
                              obj.file_name.split(".").pop() === "csv"
                            ? SheetImage
                            : obj.file_name.split(".").pop() === "pdf"
                            ? PDFImage
                            : obj.file_name.split(".").pop() === "doc" ||
                              obj.file_name.split(".").pop() === "docx"
                            ? DocImage
                            : obj.file_name.split(".").pop() === "ppt"
                            ? PPTImage
                            : OtherImage
                        }
                        style={{
                          width: 25,
                          height: 40,
                          marginRight: 15,
                        }}
                      />
                      <label
                        style={{
                          color: "black",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: "var(--text-font-size)",
                        }}
                      >
                        {obj.file_name}
                      </label>
                    </a>
                  </td>
                  <td style={{ fontSize: "var(--text-font-size)" }}>
                    {obj.modified}
                  </td>
                  <td style={{ fontSize: "var(--text-font-size)" }}>
                    {obj.modified_by}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )} */}
      {isExpanded && folder.files && folder.files.length > 0 && (
        <table
          className="bordered-table-upload-files"
          style={{ width: "95%", marginTop: "20px", marginLeft: "40px" }}
        >
          <thead
            style={{
              backgroundColor: "#F6F8FA",
            }}
          >
            <tr>
              <th>File</th>
              <th>Modified</th>
              <th>Modified By</th>
            </tr>
          </thead>
          <tbody>
            {folder?.files?.length > 0 &&
              folder?.files?.map((obj) => (
                <tr key={obj.name}>
                  <td style={{ textAlign: "left" }}>
                    <a
                      href={obj.file_url}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                      }}
                    >
                      <img
                        src={
                          obj.img
                            ? url + obj.img
                            : obj.file_name.split(".").pop() === "xls" ||
                              obj.file_name.split(".").pop() === "xlsx" ||
                              obj.file_name.split(".").pop() === "csv"
                            ? SheetImage
                            : obj.file_name.split(".").pop() === "pdf"
                            ? PDFImage
                            : obj.file_name.split(".").pop() === "doc" ||
                              obj.file_name.split(".").pop() === "docx"
                            ? DocImage
                            : obj.file_name.split(".").pop() === "ppt"
                            ? PPTImage
                            : OtherImage
                        }
                        style={{
                          width: 25,
                          height: 25,
                        }}
                      />
                      <label
                        style={{
                          color: "black",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: "var(--text-font-size)",
                          width: "300px",
                        }}
                      >
                        {obj.file_name}
                      </label>
                    </a>
                  </td>
                  <td style={{ fontSize: "var(--text-font-size)" }}>
                    {obj.modified}
                  </td>
                  <td style={{ fontSize: "var(--text-font-size)" }}>
                    {obj.modified_by}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const FolderStructure = ({ rootFolder }) => {
  return (
    <div>{rootFolder && <FolderItem folder={rootFolder} level={0} />}</div>
  );
};
