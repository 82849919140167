import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postFunctionalGoalReviewMeetingNextStepsData,
  postFunctionalGoalReviewMeetingProgressData,
} from "../../store/actions";
import EditIcon from "../../assets/images/Edit.svg";
import DeleteIcon from "../../assets/images/trash.svg";

function InputList({ type, data }) {
  const selectedSubMenuPermission = useSelector((state) => state.selectedSubMenuPermission);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [editedValue, setEditedValue] = useState("");
  const fecthPostFunGoalMeetingProgressResponse = useSelector(
    (state) => state.fecthPostFunGoalMeetingProgressResponse
  );
  const fecthPostFunGoalMeetingNextStepsResponse = useSelector(
    (state) => state.fecthPostFunGoalMeetingNextStepsResponse
  );
  const getFunctionalGoalDetails = useSelector(
    (state) => state.getFunctionalGoalDetails
  );

  const handleInputChange = (e) => {
    setNewItem(e.target.value);
  };

  useEffect(() => {
    if (getFunctionalGoalDetails) {
      if (type === "progress") {
        setItems(getFunctionalGoalDetails.prg_data);
      } else {
        setItems(getFunctionalGoalDetails.next_data);
      }
    }
  }, [getFunctionalGoalDetails]);

  useEffect(() => {
    if (type === "progress") {
      if (fecthPostFunGoalMeetingProgressResponse) {
        if (fecthPostFunGoalMeetingProgressResponse.action === "add") {
          setItems([
            ...items,
            {
              key: fecthPostFunGoalMeetingProgressResponse.key,
              value: fecthPostFunGoalMeetingProgressResponse.value,
            },
          ]);
        } else if (fecthPostFunGoalMeetingProgressResponse.action === "edit") {
          const updatedItems = items.map((item) => {
            if (item.key === fecthPostFunGoalMeetingProgressResponse.key) {
              return {
                ...item,
                value: fecthPostFunGoalMeetingProgressResponse.value,
              };
            }
            return item;
          });
          setItems(updatedItems);
        }
      }
    }
  }, [fecthPostFunGoalMeetingProgressResponse]);

  useEffect(() => {
    if (type === "nextsteps") {
      if (fecthPostFunGoalMeetingNextStepsResponse) {
        if (fecthPostFunGoalMeetingNextStepsResponse.action === "add") {
          setItems([
            ...items,
            {
              key: fecthPostFunGoalMeetingNextStepsResponse.key,
              value: fecthPostFunGoalMeetingNextStepsResponse.value,
            },
          ]);
        } else if (fecthPostFunGoalMeetingNextStepsResponse.action === "edit") {
          const updatedItems = items.map((item) => {
            if (item.key === fecthPostFunGoalMeetingNextStepsResponse.key) {
              return {
                ...item,
                value: fecthPostFunGoalMeetingNextStepsResponse.value,
              };
            }
            return item;
          });
          setItems(updatedItems);
        }
      }
    }
  }, [fecthPostFunGoalMeetingNextStepsResponse]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && newItem.trim() !== "") {
      setNewItem("");
      if (type === "progress") {
        dispatch(
          postFunctionalGoalReviewMeetingProgressData(
            localStorage.getItem("functional_goal_id"),
            newItem,
            "add",
            localStorage.getItem("goal_review_id"),
            false
          )
        );
      } else {
        dispatch(
          postFunctionalGoalReviewMeetingNextStepsData(
            localStorage.getItem("functional_goal_id"),
            newItem,
            "add",
            false,
            localStorage.getItem("goal_review_id")
          )
        );
      }
    }
  };
  const handleEditKeyPress = (event, key) => {
    if (event.key === "Enter") {
      setEditingIndex(-1);
      setEditedValue("");
      if (type === "progress") {
        dispatch(
          postFunctionalGoalReviewMeetingProgressData(
            false,
            editedValue,
            "edit",
            false,
            key
          )
        );
      } else {
        dispatch(
          postFunctionalGoalReviewMeetingNextStepsData(
            false,
            editedValue,
            "edit",
            key,
            false
          )
        );
      }
    }
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedValue(items[index].value);
  };
  const handleDelete = (key) => {
    if (type === "progress") {
      const updatedItems = items.filter((item) => item.key !== key);
      setItems(updatedItems);
      dispatch(
        postFunctionalGoalReviewMeetingProgressData(
          false,
          false,
          "delete",
          false,
          key
        )
      );
    } else {
      const updatedItems = items.filter((item) => item.key !== key);
      setItems(updatedItems);
      dispatch(
        postFunctionalGoalReviewMeetingNextStepsData(
          false,
          false,
          "delete",
          key,
          false
        )
      );
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <ul style={{ listStyleType: "disc" }}>
        {items.length > 0 &&
          items.map((item, index) => (
            <div
              key={item.key}
              style={{ display: "flex", justifyContent: "space-between" }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              {editingIndex === index ? (
                <input
                  style={{
                    width: "90%",
                    outline: "none",
                  }}
                  type="text"
                  value={editedValue}
                  onKeyDown={(event) => handleEditKeyPress(event, item.key)}
                  onChange={(e) => setEditedValue(e.target.value)}
                />
              ) : (
                <li style={{ marginBottom: "5px" }}>{item.value}</li>
              )}
              {hoveredIndex === index && (
                <div style={{ display: "flex", marginRight: "10px" }}>
                  <img
                    src={EditIcon}
                    width={"14px"}
                    alt="Edit Icon"
                    style={{ marginRight: "15px", cursor: "pointer" }}
                    onClick={() => handleEdit(index)}
                  />
                  <img
                    src={DeleteIcon}
                    width={"14px"}
                    alt="Delete Icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(item.key)}
                  />
                </div>
              )}
            </div>
          ))}
      </ul>
      <input
        disabled={
          selectedSubMenuPermission === "write" || selectedSubMenuPermission === "admin"
            ? false
            : true
        }
        style={{
          width: "100%",
          outline: "none",
          border: "none",
        }}
        type="text"
        value={newItem}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        placeholder="Enter data"
      />
    </div>
  );
}

export default InputList;
