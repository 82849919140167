import React from "react";
import EditIcon from "../../assets/images/Edit.svg";
import DeleteIcon from "../../assets/images/trash.svg";
import { useSelector } from "react-redux";

const ManageOptions = ({
  handleEditClick,
  handleDeleteClick,
  showIcons,
  showDelete = true,
}) => {
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  return (
    <div style={{ paddingRight: "20px" }}>
      <div style={{ display: "flex" }}>
        <div className="EditIcon" style={{ opacity: showIcons ? 1 : 0 }}>
          <img
            src={EditIcon}
            width={"18px"}
            alt="Edit icon"
            onClick={() => {
              handleEditClick();
            }}
          />
        </div>
        {showDelete && selectedSubMenuPermission === "admin" && (
          <div className="DeleteIcon" style={{ opacity: showIcons ? 1 : 0 }}>
            <img
              src={DeleteIcon}
              width={"24px"}
              alt="Delete icon"
              onClick={() => {
                handleDeleteClick();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageOptions;
