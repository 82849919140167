import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  getExistingGoals,
  getProductsDetails,
  postStrategyGoals,
} from "../../store/actions";
import ProductSelector from "../ProductSelector";
import BISelector from "../BISelector";

const AddExistingpopup = ({
  Title,
  handleClose,
  show,
  AddFolder,
  handleShow,
  ModalTitle,
}) => {
  const dispatch = useDispatch();
  const [biData, setBiData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedBI, setSelectedBI] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const isBIFetched = useSelector((state) => state.isBIFetched);
  const biDetails = useSelector((state) => state.biDetails);
  const getBIProductsData = useSelector((state) => state.getBIProductsData);
  const getExistingGoalsData = useSelector(
    (state) => state.getExistingGoalsData
  );
  const getExistingFunctionsData = useSelector(
    (state) => state.getExistingFunctionsData
  );
  const isProductDetailsFetched = useSelector(
    (state) => state.isProductDetailsFetched
  );
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(false);

  useEffect(() => {
    if (isBIFetched) {
      if (biDetails) {
        if (biDetails.ok) {
          if (biDetails.data) {
            setBiData(
              biDetails.data.bi_data.map((obj, i) => {
                return {
                  value: obj.name,
                  label: obj.bi_name,
                };
              })
            );
          }
        } else if (biDetails.status === "403") {
          return <div>{alert("Session Expired")}</div>;
        }
      }
    }
    return () => {};
  }, [isBIFetched]);

  useEffect(() => {
    if (show) {
      const closeModalOnOutsideClick = (event) => {
        if (event.target.classList.contains("modal-overlay")) {
          handleClose();
        }
      };
      document.addEventListener("mousedown", closeModalOnOutsideClick);

      return () => {
        document.removeEventListener("mousedown", closeModalOnOutsideClick);
      };
    }
  }, [show, handleClose]);

  useEffect(() => {
    if (getBIProductsData) {
      if (getBIProductsData.ok) {
        if (getBIProductsData.data) {
          setProductData(getBIProductsData.data.data);
          setSelectedProduct(null);
        }
      }
    }
  }, [getBIProductsData]);

  const handleCheckboxChange = (optionValue) => {
    const newCheckboxStates = {
      ...checkboxStates,
      [optionValue]: !checkboxStates[optionValue],
    };
    setCheckboxStates(newCheckboxStates);

    const selectedLabel = getExistingGoalsData.ttl_goals.find(
      (label) => label.id === optionValue
    );

    if (newCheckboxStates[optionValue]) {
      setSelectedLabels([...selectedLabels, selectedLabel]);
    } else {
      setSelectedLabels(
        selectedLabels.filter((label) => label.id !== optionValue)
      );
    }

    // Check if at least one checkbox is checked
    const hasCheckedCheckbox = Object.values(newCheckboxStates).some(
      (isChecked) => isChecked
    );
    setIsAddButtonEnabled(hasCheckedCheckbox);
  };

  const handleSelectBI = (e) => {
    setSelectedBI(e);
    dispatch(getProductsDetails(e.value));
    //e.value
  };

  const handleClickAdd = () => {
    const selectedLabelsData = selectedLabels.map((label) => ({
      bi_id: localStorage.getItem("bi_id"),
      product_id: localStorage.getItem("product_id"),
      goal_dt: label.name,
      is_active: true,
    }));
    // const selectedLabelIds = selectedLabels.map((label) => label.id);

    dispatch(postStrategyGoals(selectedLabelsData));

    setSelectedLabels([]);
    setCheckboxStates({});
    setIsAddButtonEnabled(false);
  };

  const handleSelectProduct = (e) => {
    setSelectedProduct(e);
    dispatch(getExistingGoals(selectedBI.value, e.value));
  };

  return (
    <div>
      <button
        type="button"
        className="Addobjstyle"
        data-toggle="modal"
        data-target="#exampleModalCenter"
        onClick={handleShow}
      >
        <img src={AddFolder} width={"24px"}></img>
        <span style={{ paddingLeft: "10px" }}>{Title}</span>
      </button>

      {show && (
        <div className="modal-overlay">
          <div
            className="modal-content"
            style={{
              height: "70vh",
              width: "90%",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <div className="modal-header">
              <div
                className="modal-title"
                style={{
                  fontSize: "var(--main-heading)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                {ModalTitle}
              </div>
              <button type="button" className="close" onClick={handleClose}>
                <span style={{ fontSize: "35px", opacity: ".6" }}>&times;</span>
              </button>
            </div>
            <hr />
            {/* <div>
              <input
                type="search"
                className="form-control search_input"
                placeholder="Search"
                style={{ width: "100%" }}
              ></input>
            </div> */}

            <div className="modal-body">
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      paddingTop: "5px",
                      paddingRight: "10px",
                      width: "10%",
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    Filter by:
                  </p>
                  <div style={{ display: "flex", width: "90%" }}>
                    <div>
                      <BISelector
                        showSelector={true}
                        selectedBI={selectedBI}
                        biData={biData}
                        handleSelect={handleSelectBI}
                      />
                    </div>
                    <div style={{ marginLeft: "20" }}>
                      <ProductSelector
                        showSelector={true}
                        selectedProduct={selectedProduct}
                        productData={productData}
                        handleSelectProduct={handleSelectProduct}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ height: "34vh", overflowY: "auto" }}>
                  {getExistingGoalsData &&
                  getExistingGoalsData.ttl_goals.length > 0 ? (
                    getExistingGoalsData.ttl_goals.map((option) => (
                      <div
                        key={option.id}
                        style={{
                          boxShadow: "0px 2px 4px 0px #32302c1a",
                          padding: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={option.id}
                          checked={checkboxStates[option.id] || false}
                          onChange={() => handleCheckboxChange(option.id)}
                        />
                        <label
                          className="form-check-label"
                          style={{
                            paddingLeft: "10px",
                            fontSize: "var(--sub-heading-font-size)",
                          }}
                          htmlFor={option.id}
                        >
                          {option.name}
                        </label>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      No Goals Created yet !!!
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                display: "flex",
                padding: "5px 0",
                justifyContent: "right",
              }}
            >
              <Button
                disabled={!isAddButtonEnabled}
                style={{
                  width: "fit-content",
                  backgroundColor: "#0A5F59",
                  borderColor: "#0A5F59",
                }}
                onClick={() => {
                  handleClickAdd();
                  handleClose();
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddExistingpopup;
