import React, { useState } from "react";
import Sections from "../Sections";
import Questions from "../Questions";
import "../ExecutionReadinessFunction.css";
import Arrow from "../../../assets/images/arrow-circle-down.svg";

export default function Category({ category, selectedPermission }) {
  return (
    <div style={{ marginLeft: 15 }}>
      {category &&
        category.length > 0 &&
        category.map((node, index) => (
          <CategoryList
            categoryData={node}
            categoryIndex={index}
            key={node.key}
            selectedPermission={selectedPermission}
          />
        ))}
    </div>
  );
}

const CategoryList = ({ categoryData, categoryIndex, selectedPermission }) => {
  const {
    key = "001",
    category = "",
    title = "",
    sections = [],
    questions = [],
  } = categoryData;
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  const toggleSections = () => {
    setIsOpen(!isOpen);
    setIsCollapse(!isCollapse);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <div
        style={{ display: "flex", width: "fit-content", cursor: "pointer" }}
        onClick={toggleSections}
      >
        <img
          src={Arrow}
          width={"24px"}
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            transition: "transform 0.3s ease",
            marginRight: "5px",
            transform: !isCollapse ? "rotate(180deg)" : "rotate(0)",
          }}
          // onClick={() => handleCollapseToggle(functionIndex)}
        />
        <div
          className={`color${(categoryIndex % 3) + 1}`}
          style={{
            width: "fit-content",
            padding: "3px 5px",
            color: "white",
            borderRadius: "4px",
            fontSize: "var(--sub-heading-font-size)",
            alignItems: "center",
          }}
        >
          {category}
        </div>
      </div>
      {(questions.length > 0 || sections.length > 0) && (
        <p
          style={{
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
            marginLeft: "40px",
            marginBottom: "0px",
          }}
        >
          {title}
        </p>
      )}
      {isOpen && questions.length > 0 && (
        <Questions
          categoryId={key}
          sectionId={key}
          questions={questions}
          selectedPermission={selectedPermission}
        />
      )}
      {isOpen && sections.length > 0 && (
        <>
          {/* <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              marginLeft: "40px",
              marginBottom: "0px",
            }}
          >
            {title}
          </p> */}
          <Sections categoryId={key} sections={sections} />
        </>
      )}
    </div>
  );
};
