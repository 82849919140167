import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../MainComponent";
import "./ExecutionReadiness.css";
import { url } from "../../store/sagas/API.js";
import { getRandomColor, createImageFromInitials } from "../Utils";
import UserProfile from "../../assets/images/user-profile.svg";
import { getExectionReadinessReviewDetails } from "../../store/actions";
import { useNavigate } from "react-router-dom";
import ProgressBar from "react-customizable-progressbar";
import Login from "../Login.js";

function ExecutionReadiness() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emp_data, setEmpData] = useState(null);
  const fetchExecutionReadinessReviewDetails = useSelector(
    (state) => state.fetchExecutionReadinessReviewDetails
  );
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const [functionList, setFunctionList] = useState([]);

  useEffect(() => {
    if (fetchExecutionReadinessReviewDetails) {
      setFunctionList(fetchExecutionReadinessReviewDetails.functions);
    }
  }, [fetchExecutionReadinessReviewDetails]);

  useEffect(() => {
    dispatch(
      getExectionReadinessReviewDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);

  useEffect(() => {
    if (
      getEmployeeDetails &&
      getEmployeeDetails.emp_data &&
      getEmployeeDetails.emp_data.length > 0
    ) {
      setEmpData(
        getEmployeeDetails.emp_data.map((obj) => {
          return {
            value: obj.id,
            label: obj.prop_name,
            icon: createImageFromInitials(
              30,
              obj.prop_name,
              getRandomColor(obj.prop_name)
            ),
          };
        })
      );
    }
  }, [getEmployeeDetails]);

  const handleBack = () => {
    navigate("/executionreadiness", { replace: true });
  };
  const handleClick = () => {
    navigate("/dashboard", { replace: true });
  };

  const handleFunctionCardClick = (function_id) => {
    localStorage.setItem("function_id", function_id);
    navigate("/executionreadinessfunction");
  };

  const [progress, setProgress] = useState(30);

  const getColorClass = (progress) => {
    if (progress <= 33) {
      return "red";
    } else if (progress <= 66) {
      return "orange";
    } else {
      return "green";
    }
  };

  const colorClass = getColorClass(30);

  const content = (
    <>
      <div className="Container">
        <div
          style={{
            display: "flex",
            width: "85%",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <div>
            <div style={{ marginTop: "30px", marginLeft: "20px" }}>
              <h3>Execution Readiness Review</h3>
              <p style={{ color: "#646464" }}>Progress: 00%</p>
            </div>
          </div>

          <ProgressBar
            radius={70}
            children={120}
            progress={progress}
            steps={100}
            strokeColor={colorClass}
            pointerStrokeColor={colorClass}
            cut={180}
            rotate={180}
            strokeWidth={8}
            trackStrokeWidth={8}
            pointerRadius={8}
            pointerStrokeWidth={5}
          >
            <div className="indicator">
              <div className="indicator-number">{progress}</div>
              <div className="indicator-text">XL ERM Score:</div>
            </div>
          </ProgressBar>
        </div>
        <div style={{ position: "relative", top: "-60px" }}>
          <div className="exe-readiness-card-list">
            {functionList.map((functionItem) => (
              <>
                <div
                  className="card exe-readiness-card"
                  onClick={() => handleFunctionCardClick(functionItem.id)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <img
                        alt="icon"
                        src={url + functionItem.icon}
                        style={{ paddingBottom: "5px", width: "45px" }}
                      />
                    </div>
                    <div>
                      {functionItem.assigned_to &&
                      functionItem.assigned_to.length > 0 &&
                      functionItem.assigned_to.find((j) => j.is_active) ? (
                        <img
                          alt="owner"
                          src={createImageFromInitials(
                            30,
                            functionItem.assigned_to.find((j) => j.is_active)
                              .label,
                            getRandomColor(
                              functionItem.assigned_to.find((j) => j.is_active)
                                .label
                            )
                          )}
                        />
                      ) : (
                        <img alt="" src={UserProfile} />
                      )}
                    </div>
                  </div>
                  <div
                    key={functionItem.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h6 className="exe-readiness-card-title">
                      <div>{functionItem.name}</div>
                    </h6>
                  </div>
                  <div className="exe-readiness-progress-card">
                    <div className="exe-readiness-inner-progress"></div>
                  </div>
                  <p style={{ fontSize: "12px", paddingTop: "10px" }}>
                    Required
                  </p>
                  <div className="exe-readiness-children">
                    {functionItem.children.slice(0, 3).map((child, index) => (
                      <div key={index} className="exe-readiness-children-child">
                        {child}
                      </div>
                    ))}
                    {functionItem.children.length > 3 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "15px",
                          backgroundColor: "#E2E2E2",
                          padding: "2px 12px",
                          fontSize: "12px",
                        }}
                      >
                        +{functionItem.children.length - 3}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showProducts={true}
        breadcrumbParent={"Enterprise Readiness"}
        breadcrumbChild={"Execution Readiness Review"}
        handleNext={handleClick}
        handleBackButton={handleBack}
      />
    );
  }
}

export default ExecutionReadiness;
