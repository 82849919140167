import React, { useState, useEffect } from "react";
import Questions from "../Questions";
import ArrowSIMP from "../../../../assets/images/arrow-bottom.svg";

export default function Sections({ sections, categoryId }) {
  return (
    <div style={{ marginLeft: 15, marginTop: "10px" }}>
      {sections.map((node, index) => (
        <SectionList
          sectionData={node}
          key={node.key}
          categoryId={categoryId}
          index={index}
          sections={sections}
        />
      ))}
    </div>
  );
}

const SectionList = ({ sections, sectionData, categoryId, index }) => {
  const {
    key = "0",
    category = "",
    questions = [],
  } = sectionData;
  const sectionlist = sectionData.sections
  const [isOpen, setIsOpen] = useState(true);
  const [isCollapse, setIsCollapse] = useState(false);

  const toggleSections = () => {
    setIsOpen(!isOpen);
    setIsCollapse(!isCollapse);
  };
  return (
    <div
      style={{
        marginBottom: "10px",
        // marginLeft:
        //   index > 0 && sections[index - 1].questions.length === 0
        //     ? 15 * index
        //     : 0,
      }}
    >
      <div
        style={{ display: "flex", width: "100%", cursor: "pointer" }}
        onClick={toggleSections}
      >
        <h6 className={category === "" ? "" : "fun-subsection"}>
          {category !== "" && (
            <img
              src={ArrowSIMP}
              width={"24px"}
              style={{
                cursor: "pointer",
                transition: "transform 0.3s ease",
                marginRight: "5px",
                transform: !isCollapse ? "rotate(180deg)" : "rotate(0)",
              }}
              // onClick={() => handleCollapseToggle(functionIndex)}
            />
          )}
          {category}
        </h6>
      </div>

      {isOpen && questions.length > 0 && (
        <Questions
          categoryId={categoryId}
          sectionId={key}
          questions={questions}
        />
      )}
      {isOpen && sectionlist && sectionlist.length > 0 && (
        <Sections categoryId={categoryId} sections={sectionlist} />
      )}
    </div>
  );
};
