import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Example from "../../Card.js";
import MainComponent from "../../MainComponent.js";
import {
  bibusinesstypeGet,
  bibusinesstypePost,
} from "../../../store/actions/index.js";
import { useNavigate } from "react-router-dom";
import Login from "../../Login.js";

function BIBusinessType() {
  const dispatch = useDispatch();
  const isBIBusinessTypeFetched = useSelector(
    (state) => state.isBIBusinessTypeFetched
  );
  const biBusinessTypeGetDetails = useSelector(
    (state) => state.biBusinessTypeGetDetails
  );
  const isBIBusinessTypeSubmitted = useSelector(
    (state) => state.isBIBusinessTypeSubmitted
  );
  const biBusinessTypePostDetails = useSelector(
    (state) => state.biBusinessTypePostDetails
  );
  const isBIFetched = useSelector((state) => state.isBIFetched);
  const navigate = useNavigate();

  const [biBusinessTypeData, setBiBusinessTypeData] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  var bi_id = localStorage.getItem("bi_id");
  console.log(bi_id);
  useEffect(() => {
    dispatch(bibusinesstypeGet(bi_id));
  }, []);
  useEffect(() => {
    console.log(isBIFetched);
    if (isBIBusinessTypeFetched) {
      console.log(biBusinessTypeGetDetails);
      if (biBusinessTypeGetDetails) {
        if (biBusinessTypeGetDetails.ok) {
          setBiBusinessTypeData(biBusinessTypeGetDetails.data.data);
        } else {
          return <div>alert({biBusinessTypeGetDetails.data})</div>;
        }
      } else {
        return <div>alert('Not able to fetch the data')</div>;
      }
    }
  }, [isBIBusinessTypeFetched, isBIFetched]);
  useEffect(() => {
    console.log();
    if (isBIBusinessTypeSubmitted && isClicked) {
      if (biBusinessTypePostDetails) {
        if (biBusinessTypePostDetails.ok) {
          navigate("/biproductdetails");
          window.location.reload();
        }
      }
    }
  }, [isBIBusinessTypeSubmitted, isClicked]);
  var teams = [];
  if (biBusinessTypeData) {
    teams = biBusinessTypeData;
  }
  const handleClick = () => {
    if (localStorage.getItem("bi_id")) {
      var selected_business_type = document.getElementsByClassName("selected");
      if (selected_business_type.length > 0) {
        setIsClicked(true);
        var selected_business_type_id =
          selected_business_type[0].getAttribute("data-id");
        var bi_id = localStorage.getItem("bi_id");
        dispatch(bibusinesstypePost(bi_id, selected_business_type_id));
      } else {
        return <div>{alert("Please select Business Type")}</div>;
      }
    } else {
      return <div>{alert("Please select Business Initiative")}</div>;
    }
  };
  const handleBack = () => {
    navigate("/bidetails");
    window.location.reload();
  };
  const content = (
    <>
      <Example title="Select type of Business for BI" cardContents={teams} />
      <div style={{ float: "right", marginTop: "5%" }}></div>
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          handleNext={handleClick}
          handleBackButton={handleBack}
          showSelector={true}
          showButtons={true}
          breadcrumbParent={"Onboarding"}
          breadcrumbChild={"BI Business Type"}
        />
      </>
    );
  }
}

export default BIBusinessType;
