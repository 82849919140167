import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "./helpInfoProviderComponent.css";
import { getHelpInfo } from "../store/sagas/API";
import Info from "../assets/images/info-black.svg";
import InfoColor from "../assets/images/infocolor.svg";
import QuestionMark from "../assets/images/question.svg";

function HelpInfo({ documentName, helpKey, image }) {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [extraHelp, setExtraHelp] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    return () => {
      setText("");
    };
  }, []);

  const fetchTextFromAPI = async () => {
    if (!tooltipOpen) {
      try {
        const response = await getHelpInfo(documentName, helpKey);
        // Let the API call be made once and if the user modified the content the page has to be reloaded
        setTooltipOpen(true);
        if (response && response.info && response.info.length > 0) {
          setText(response.info[0].info);
          setExtraHelp(response.info[0].extra_info);
        }
      } catch (error) {
        console.error("Error fetching text from the API", error);
      }
    }
  };

  const handleButtonClicked = () => {
    setTooltipOpen(false);
    console.log("data clicked");
    const payload = { documentName: documentName, helpKey: helpKey };
    console.log("payload", payload);
    dispatch({
      type: "SET_SELECTED_HELP_INFO_SIDEBAR",
      value: true,
      payload,
    });
  };

  const tooltip = (
    <Tooltip id={`tooltip-${documentName}-${helpKey}`}>
      <div>
        <p>{text || "Loading..."}</p>
        {extraHelp && (
          <Button
            variant="link"
            onClick={() => handleButtonClicked(documentName, helpKey)}
          >
            Learn more
          </Button>
        )}
      </div>
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement={image === "question" ? "left" : "right"}
        overlay={tooltip}
        trigger="click"
        rootClose="true"
      >
        <span className="help-icon">
          {image === "flashy" ? (
            <img
              alt="help"
              src={InfoColor}
              onClick={fetchTextFromAPI}
              width={13}
            />
          ) : image === "question" ? (
            <div style={{ marginRight: "40px" }}>
              <img
                alt="help"
                src={QuestionMark}
                onClick={fetchTextFromAPI}
              />
            </div>
          ) : (
            <img
              width={13}
              alt="help"
              src={Info}
              style={{ marginRight: "20px", cursor: "pointer" }}
              onClick={fetchTextFromAPI}
            />
          )}
        </span>
      </OverlayTrigger>
    </>
  );
}

export default HelpInfo;
