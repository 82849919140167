import data from "../../sites.json";

export const getApiEndpoint = () => {
  let apiEndPoint = "";
  const url = new URL(window.location.href);
  const protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port ? `:${url.port}` : "";
  const clientUrl = port ? `${hostname}${port}` : hostname;
  const site = data.sites.find(
    (site) => site.client === clientUrl
  );
  if (site) {
    apiEndPoint = `${protocol}//${site.backend}`;
  }
  return apiEndPoint;
};

export const EmployeePortal = () => {
  let apiEmpPortalurl = "";
  const url = new URL(window.location.href);
  const protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port ? `:${url.port}` : "";
  const clientUrl = port ? `${hostname}${port}` : hostname;
  const site = data.sites.find(
    (site) => site.client === clientUrl
  );
  if (site) {
    apiEmpPortalurl = `${protocol}//${site.client}`;
  }
  return apiEmpPortalurl;
};