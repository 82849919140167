export const getRandomColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = ((hash >> (i * 8)) & 0xff) + 127; // Ensure the color is light by adding 127
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

const getInitials = (name) => {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const createImageFromInitials = (size = 30, name, color) => {
  size = 30;
  if (name === null) return;
  name = getInitials(name);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = canvas.borderRadius = size;

  context.fillStyle = "#ffffff";
  //   context.fillRect(0, 0, size, size);

  context.fillStyle = `${color}50`;
  //   context.fillRect(0, 0, size, size);
  context.beginPath();
  context.roundRect(0, 0, size, size, size);
  context.fill();
  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};

export const createProgressBar = (status) => {
  return (
    <div className="progress-card">
      {status === "Off track" ? (
        <div className="progress-card-danger">
          <div className="danger-progress"></div>
        </div>
      ) : status === "At risk" ? (
        <div className="progress-card-risk">
          <div className="risk-progress"></div>
        </div>
      ) : status === "On track" ? (
        <div className="progress-card-ontrack">
          <div className="ontrack-progress"></div>
        </div>
      ) : status === "Completed" ? (
        <div className="progress-card-complete">
          <div className="complete-progress"></div>
        </div>
      ) : status === "On hold" ? (
        <div className="progress-card-onhold">
          <div className="onhold-progress"></div>
        </div>
      ) : (
        <div className="progress-card-info">
          {/* <div className="info-progress"></div> */}
        </div>
      )}
    </div>
  );
};
export const createStatus = (status) => {
  return (
    <div
      style={{
        paddingBottom: "15px",
        marginLeft: "15px",
        fontSize: "var(--captions-font-size)",
      }}
    >
      {status === "Off track" ? (
        <span className="danger">{status}</span>
      ) : status === "At risk" ? (
        <span className="risk">{status}</span>
      ) : status === "On track" ? (
        <span className="success">{status}</span>
      ) : status === "Completed" ? (
        <span className="complete">{status}</span>
      ) : status === "On hold" ? (
        <span className="hold">{status}</span>
      ) : (
        <span className="info">{status}</span>
      )}
    </div>
  );
};

export const StepProgressBar = ({
  steps,
  currentStep,
  onStepClick,
  selectedSubMenuPermission,
}) => {
  return (
    <div className="step-progress-bar" style={{ textAlign: "center" }}>
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${index <= currentStep ? "active" : ""}`}
          onClick={() => {
            if (
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
            ) {
              onStepClick(index);
            }
          }}
        ></div>
      ))}
      <div className="line-indicator-container">
        <div
          className="line-indicator"
          style={{ width: `${currentStep * (100 / (steps.length - 1))}%` }}
        ></div>
      </div>
    </div>
  );
};
