import { useEffect, useRef, useState } from "react";
import "./SearchableDropDown.css";
import Image from "../assets/images/XL.png";
import { Popover, Overlay } from "react-bootstrap";
import VCTable from "./VCTable";
import VCTabs from "./VCTabs";

const SearchableDropdown = ({
  options,
  label,
  id,
  selector,
  selectedVal,
  handleChange,
  selectedPermission
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [targetElement, setTargetElement] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [valueChain, setValueChain] = useState([]);
  const divRef = useRef(null);
  const popoverRef = useRef(null);

  const selectOption = (option) => {
    setShowPopover(false);
    setQuery(() => "");
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false);
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  function toggle() {
    setIsOpen(!isOpen);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };
  const filter = (options) => {
    return options.filter((option) =>
      option[label]
        ? option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        : ""
    );
  };
  const handleOnHover = (e, option) => {
    setShowPopover(true);
    setTargetElement(document.getElementsByClassName("options")[0]);
    setCategories(option.categories);
    setSelectedMenu(option.categories[0]);
    setValueChain(option);
  };
  const onMenuChanged = (menu) => {
    setSelectedMenu(menu);
  };
  function expand() {
    setIsOpen(true);
  }

  function close() {
    setShowPopover(false);
    setIsOpen(false);
  }

  return (
    <div className="dropdown" ref={divRef}>
      <div className="control">
        <div className="selected-value">
          <input
            style={{ fontSize: "var(--text-font-size)" }}
            className="dropdown-input"
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            placeholder="Select Value Chain"
            onChange={(e) => {
              setQuery(e.target.value);
              // handleChange(e);
            }}
            onClick={toggle}
            disabled={
              selectedPermission === "write" ||
              selectedPermission === "admin"
                ? false
                : true
            }
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div
        style={{ fontSize: "var(--text-font-size)" }}
        className={`options ${isOpen ? "open" : ""}`}
      >
        {filter(options).map((option, index) => {
          return (
            <>
              <div
                onClick={() => selectOption(option)}
                onMouseOver={(e) => handleOnHover(e, option)}
                className={`option ${
                  option[label] === selectedVal ? "selected" : ""
                }`}
                key={`${id}-${index}`}
              >
                {option[selector] ? (
                  <>
                    <img style={{ marginRight: 10 }} src={Image} />
                    {option[label]}
                  </>
                ) : (
                  option[label]
                )}
              </div>
            </>
          );
        })}
        <Overlay
          show={showPopover}
          target={targetElement}
          ref={popoverRef}
          placement="right"
          rootClose
          onHide={() => setShowPopover(false)}
        >
          <Popover
            id="popover-contained"
            title="Popover right"
            style={{ padding: 20 }}
          >
            <h6>{valueChain.value}</h6>
            <hr style={{ borderTop: "1px solid" }} />
            <VCTabs
              menus={categories}
              selectedMenu={selectedMenu}
              onMenuChanged={onMenuChanged}
              preview={true}
            />
            <VCTable selectedMenu={selectedMenu} preview={true} />
          </Popover>
        </Overlay>
      </div>
    </div>
  );
};

export default SearchableDropdown;
