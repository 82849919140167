import { takeEvery } from "redux-saga/effects";
import {
  USER_LOGIN_INITIATE,
  UI_MENU_LIST,
  BI_DETAILS,
  BI_DETAILS_POST,
  BI_DETAILS_LIST,
  BI_SUB_SECTOR_GET,
  BI_BUSINESS_TYPE_GET,
  BI_BUSINESS_TYPE_POST,
  BI_PRODUCT_DETAILS_GET,
  BI_PRODUCT_DETAILS_POST,
  VC_LIST_GET,
  VC_CREATE,
  VC_CHANGE_TABLE_DATA,
  ADD_CUSTOMER_VC_SELECTION,
  GET_CUSTOMER_VC_SELECTION,
  GET_ORG_DETAILS,
  REMOVE_CUSTOMER_VC_SELECTION,
  GET_PRODUCTS_DETAILS,
  GET_MARKET_SIZE_DETAILS,
  GET_COMPETITOR_ANALYSIS_DETAILS,
  GET_FETAURE_TYPE_DETAILS,
  GET_COMPETITOR_DETAILS,
  POST_MARKET_SIZE_DETAILS,
  POST_COMPETITOR_DETAILS,
  POST_COMPETITOR_ANALYSIS_DETAILS,
  POST_FETAURE_DETAILS,
  GET_METRICS,
  POST_METRICS,
  POST_MS_DOCS,
  GET_MS_DOCS,
  GET_NOTES,
  POST_NOTES,
  GET_ANSOFF_MATRIX_DETAILS,
  GET_MEETING,
  POST_MEETING,
  GET_KNOWLEDGE_BASE,
  POST_COPY_KNOWLEDGE_BASE,
  GET_XLEXECUTION_PLAN_DETAILS,
  POST_COPY_MASTER_FUNCTIONS_XLEXECUTION,
  POST_EDIT_STRATEGY_OBJECTIVE,
  POST_STRATEGY_GOALS,
  POST_STRATEGY_FUNCTIONS,
  GET_DEPARTMENTS,
  GET_EMPLOYEE_DATA,
  GET_TASKS_PRIORITY_STATUS,
  GET_INITIATIVE_TRACKER_DETAILS,
  GET_EXISTING_GOALS,
  GET_EXISTING_FUNCTIONS,
  GET_SINGLE_TASK_DATA,
  GET_FUNCTIONAL_GOAL_DETAILS,
  POST_TASK_COMMENTS,
  POST_FUNGOAL_DEPENDECIES,
  POST_FUNGOAL_KEYDECISIONS,
  POST_FUNGOAL_RISK_ISSUES,
  POST_FUNGOAL_MEETING_REVIEW_DATA,
  GET_FUNGOAL_MEETING_REVIEW_DATA,
  POST_FUNGOALREVIEW_MEETING_NEXTSTEPS,
  POST_FUNGOALREVIEW_MEETING_PROGRESS,
  GET_DASHBOARD_STATE_DETAILS,
  POST_DASHBOARD_STATE_DETAILS,
  GET_EXECUTION_READINESS_FUNCTION,
  GET_STRATEGY_READINESS_FUNCTION,
  POST_STRATEGY_READINESS_FUNCTION,
  GET_CALENDER_MEETING_DETAILS,
  GET_DASHBOARD_TASK_DETAILS,
  GET_ECOSYSTEM_MAPPING_SECTION_DETAILS,
  POST_ECOSYSTEM_MAPPING_SECTION_DETAILS,
  GET_NOTIFICATION_DETAILS,
  GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA,
  POST_EXECUTION_READINESS_FUNCTIONALAREA,
  POST_NOTIFICATION_DETAILS,
  GET_ANSOFF_MATRIX_GRID_DETAILS,
  POST_ANSOFF_MATRIX_DETAILS,
  GET_FILE_MANAGER_DETAILS,
  POST_FILE_MANAGER_DETAILS,
  POST_EXECUTION_READINESS_DETAILS,
  GET_EXECUTION_READINESS_REVIEW_DETAILS,
  POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS,
  POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
  GET_GLOBAL_FILE_MANAGER_DETAILS,
  POST_GLOBAL_FILE_MANAGER_DETAILS,
  POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS,
  GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS,
  GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA,
  POST_STRATEGY_READINESS_FUNCTIONALAREA,
  POST_STRATEGY_READINESS_DETAILS,
  GET_STRATEGY_READINESS_REVIEW_DETAILS,
  POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS,
  POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
  POST_FOLDER_MANAGER_ADD_NEW,
  POST_FOLDER_MANAGER_EDIT,
  POST_FOLDER_MANAGER_DELETE,
  POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER,
  GET_DRIVE_FILES,
  GET_PROJECT_SUMMARY_DETAILS,
  GET_LOCAL_FOLDER_MANAGER,
  POST_LOCAL_FOLDER_MANAGER,
  POST_LOCAL_FOLDER_MANAGER_FILE_DELETE,
  GET_SCREEN_COMMENTS,
  POST_SCREEN_COMMENTS,
  UPDATE_USER_PASSWORD,
  FORGOT_USER_PASSWORD,
  POST_BI_FGOAL_STATUS,
  GET_BI_FGOAL_STATUS,
  GET_GLOBAL_SERACH_FILTER,
  GET_IS_MAINTENANCE_MODE,
  POST_XLSTRATEGYPLAN_DETAILS,
  GET_XLSTRATEGYPLAN_DETAILS,
} from "../actions/actionTypes";
import {
  userLoginSaga,
  uiMenuListSaga,
  biDetailsGetSaga,
  biDetailsPostSaga,
  biDetailsListSaga,
  biSubSectorGetSaga,
  biBusinessTypeGetSaga,
  biBusinessTypePostSaga,
  biProductDetailsGetSaga,
  biProductDetailsPostSaga,
  vcListGetSaga,
  vcCreateSaga,
  vcChangeTableDataSaga,
  addCustomerVCSelectionSaga,
  getCustomerVCSelectionSaga,
  getOrgDetailsSaga,
  removeCustomerVCSelectionSaga,
  getProductDetailsSaga,
  getMarketSizeDetailsSaga,
  getCompAnalDetailsSaga,
  getCompetitorDetailsSaga,
  getFeatureTypeDetailsSaga,
  postMarketSizeDetailsSaga,
  postCompetitorDetailsSaga,
  postCompAnalDetailsSaga,
  postFeatureDetailsSaga,
  getMetricsSaga,
  postMetricsSaga,
  postMSDocsSaga,
  getMSDocsSaga,
  getNotesSaga,
  postNotesSaga,
  getAnsoffMatrixDetailsSaga,
  getMeetingSaga,
  postMeetingSaga,
  getKnowledgeBaseSaga,
  postCopyKnowledgeBaseTemplateSaga,
  getXLExecutionDetailsSaga,
  // postCopyMasterFunctionsXLExecutionSaga,
  postEditStrategyObjectiveSaga,
  postStrategyGoalsSaga,
  postStrategyFunctionsSaga,
  // getDepartmentsSaga,
  getEmployeeDataSaga,
  getMasterTaskPriorityAndStatusDataSaga,
  getInitiativeTrackerDetailsSaga,
  getExistingGoalsSaga,
  getExistingFunctionsSaga,
  getTaskDetailsSaga,
  getFunctionalGoalsSaga,
  postTaskCommentsSaga,
  postFunGoalDependenciesSaga,
  postFunGoalKeyDecisionsSaga,
  postFunGoalKeyRiskIssuesSaga,
  getFunctionalGoalReviewMeetingDataSaga,
  postFunctionalGoalReviewMeetingDataSaga,
  postFunctionalGoalReviewMeetingProgressDataSaga,
  postFunctionalGoalReviewMeetingNextStepsDataSaga,
  getdashboardStateManagementSaga,
  postdashboardStateManagementSaga,
  getExecutionReadinessFunctionSaga,
  getStrategyReadinessFunctionSaga,
  postStrategyReadinessFunctionSaga,
  getCalendarMeetingDataSaga,
  getDashboardTaskDataSaga,
  getEcosystemMappingSectionDataSaga,
  postEcosystemMappingSectionDataSaga,
  getNoticationsDetailsSaga,
  getExecutionReadinessFunctionalAreaDetailsSaga,
  postExecutionReadinessFunctionalAreaDetailsSaga,
  postNoticationsDetailsSaga,
  getAnsoffMatrixGridDataSaga,
  postAnsoffMatrixDataSaga,
  getFileManagerDetailsSaga,
  postFilemanagerDetailsSaga,
  postExecutionReadinessDetailsSaga,
  getExecutionReadinessReviewDetailsSaga,
  postExecutionReadinessCustomerInputDetailsSaga,
  postExecutionReadinessCustomerAnswerChoiceDetailsSaga,
  getGlobalFileManagerDetailsSaga,
  postGlobalFilemanagerDetailsSaga,
  postNewStrategyReadinessFunctionsSaga,
  getStrategyReadinessExistedFunctionsSaga,
  getStrategyReadinessFunctionalAreaDetailsSaga,
  postStrategyReadinessFunctionalAreaDetailsSaga,
  postStrategyReadinessDetailsSaga,
  getStrategyReadinessReviewDetailsSaga,
  postStrategyReadinessCustomerInputDetailsSaga,
  postStrategyReadinessCustomerAnswerChoiceDetailsSaga,
  postFloderManagerAddNewSaga,
  postFloderManagerEditSaga,
  postFloderManagerDeleteSaga,
  postUploadFileInSpecificFolderSaga,
  getDriveFilesSaga,
  getProjectSummaryDetailsSaga,
  postLocalFolderManagerSaga,
  getLocalFolderManagerSaga,
  postLocalFolderManagerFileDeleteSaga,
  getScreenCommentsSaga,
  postScreenCommentsSaga,
  userUpdatePasswordSaga,
  userForgotPasswordSaga,
  getBiFGoalStatusSaga,
  postBiFGoalStatusSaga,
  getGetGlobalSearchFilterSaga,
  getIsMaintenanceModeSaga,
  getXLStrategyPlanDetailsSaga,
  postXLStrategyPlanDetailsSaga,
} from "./API";

export function* root() {
  yield takeEvery(USER_LOGIN_INITIATE, userLoginSaga);
  yield takeEvery(UPDATE_USER_PASSWORD, userUpdatePasswordSaga);
  yield takeEvery(FORGOT_USER_PASSWORD, userForgotPasswordSaga);
  yield takeEvery(UI_MENU_LIST, uiMenuListSaga);
  yield takeEvery(BI_DETAILS_LIST, biDetailsListSaga);
  yield takeEvery(BI_DETAILS, biDetailsGetSaga);
  yield takeEvery(BI_DETAILS_POST, biDetailsPostSaga);
  yield takeEvery(BI_SUB_SECTOR_GET, biSubSectorGetSaga);
  yield takeEvery(BI_BUSINESS_TYPE_GET, biBusinessTypeGetSaga);
  yield takeEvery(BI_BUSINESS_TYPE_POST, biBusinessTypePostSaga);
  yield takeEvery(BI_PRODUCT_DETAILS_GET, biProductDetailsGetSaga);
  yield takeEvery(BI_PRODUCT_DETAILS_POST, biProductDetailsPostSaga);
  yield takeEvery(VC_LIST_GET, vcListGetSaga);
  yield takeEvery(VC_CREATE, vcCreateSaga);
  yield takeEvery(VC_CHANGE_TABLE_DATA, vcChangeTableDataSaga);
  yield takeEvery(ADD_CUSTOMER_VC_SELECTION, addCustomerVCSelectionSaga);
  yield takeEvery(REMOVE_CUSTOMER_VC_SELECTION, removeCustomerVCSelectionSaga);
  yield takeEvery(GET_CUSTOMER_VC_SELECTION, getCustomerVCSelectionSaga);
  yield takeEvery(GET_ORG_DETAILS, getOrgDetailsSaga);
  yield takeEvery(GET_PRODUCTS_DETAILS, getProductDetailsSaga);
  yield takeEvery(GET_ANSOFF_MATRIX_DETAILS, getAnsoffMatrixDetailsSaga);
  yield takeEvery(GET_MARKET_SIZE_DETAILS, getMarketSizeDetailsSaga);
  yield takeEvery(GET_FETAURE_TYPE_DETAILS, getFeatureTypeDetailsSaga);
  yield takeEvery(GET_COMPETITOR_DETAILS, getCompetitorDetailsSaga);
  yield takeEvery(GET_COMPETITOR_ANALYSIS_DETAILS, getCompAnalDetailsSaga);
  yield takeEvery(POST_MARKET_SIZE_DETAILS, postMarketSizeDetailsSaga);
  yield takeEvery(POST_COMPETITOR_DETAILS, postCompetitorDetailsSaga);
  yield takeEvery(POST_FETAURE_DETAILS, postFeatureDetailsSaga);
  yield takeEvery(POST_COMPETITOR_ANALYSIS_DETAILS, postCompAnalDetailsSaga);
  yield takeEvery(GET_METRICS, getMetricsSaga);
  yield takeEvery(POST_METRICS, postMetricsSaga);
  yield takeEvery(GET_MS_DOCS, getMSDocsSaga);
  yield takeEvery(POST_MS_DOCS, postMSDocsSaga);
  yield takeEvery(GET_NOTES, getNotesSaga);
  yield takeEvery(POST_NOTES, postNotesSaga);
  yield takeEvery(GET_MEETING, getMeetingSaga);
  yield takeEvery(POST_MEETING, postMeetingSaga);
  yield takeEvery(GET_KNOWLEDGE_BASE, getKnowledgeBaseSaga);
  yield takeEvery(POST_COPY_KNOWLEDGE_BASE, postCopyKnowledgeBaseTemplateSaga);
  yield takeEvery(GET_XLEXECUTION_PLAN_DETAILS, getXLExecutionDetailsSaga);
  // yield takeEvery(
  //   POST_COPY_MASTER_FUNCTIONS_XLEXECUTION,
  //   postCopyMasterFunctionsXLExecutionSaga
  // );
  yield takeEvery(POST_EDIT_STRATEGY_OBJECTIVE, postEditStrategyObjectiveSaga);
  yield takeEvery(POST_STRATEGY_GOALS, postStrategyGoalsSaga);
  yield takeEvery(POST_STRATEGY_FUNCTIONS, postStrategyFunctionsSaga);
  // yield takeEvery(GET_DEPARTMENTS, getDepartmentsSaga);
  yield takeEvery(GET_EMPLOYEE_DATA, getEmployeeDataSaga);
  yield takeEvery(
    GET_TASKS_PRIORITY_STATUS,
    getMasterTaskPriorityAndStatusDataSaga
  );
  yield takeEvery(
    GET_INITIATIVE_TRACKER_DETAILS,
    getInitiativeTrackerDetailsSaga
  );
  yield takeEvery(GET_EXISTING_GOALS, getExistingGoalsSaga);
  yield takeEvery(GET_EXISTING_FUNCTIONS, getExistingFunctionsSaga);
  yield takeEvery(GET_SINGLE_TASK_DATA, getTaskDetailsSaga);
  yield takeEvery(GET_FUNCTIONAL_GOAL_DETAILS, getFunctionalGoalsSaga);
  yield takeEvery(POST_TASK_COMMENTS, postTaskCommentsSaga);
  yield takeEvery(POST_FUNGOAL_DEPENDECIES, postFunGoalDependenciesSaga);
  yield takeEvery(POST_FUNGOAL_KEYDECISIONS, postFunGoalKeyDecisionsSaga);
  yield takeEvery(POST_FUNGOAL_RISK_ISSUES, postFunGoalKeyRiskIssuesSaga);
  yield takeEvery(
    POST_FUNGOAL_MEETING_REVIEW_DATA,
    postFunctionalGoalReviewMeetingDataSaga
  );
  yield takeEvery(
    GET_FUNGOAL_MEETING_REVIEW_DATA,
    getFunctionalGoalReviewMeetingDataSaga
  );
  yield takeEvery(
    POST_FUNGOALREVIEW_MEETING_PROGRESS,
    postFunctionalGoalReviewMeetingProgressDataSaga
  );
  yield takeEvery(
    POST_FUNGOALREVIEW_MEETING_NEXTSTEPS,
    postFunctionalGoalReviewMeetingNextStepsDataSaga
  );
  yield takeEvery(GET_DASHBOARD_STATE_DETAILS, getdashboardStateManagementSaga);
  yield takeEvery(
    POST_DASHBOARD_STATE_DETAILS,
    postdashboardStateManagementSaga
  );
  yield takeEvery(
    GET_EXECUTION_READINESS_FUNCTION,
    getExecutionReadinessFunctionSaga
  );
  yield takeEvery(
    GET_STRATEGY_READINESS_FUNCTION,
    getStrategyReadinessFunctionSaga
  );
  yield takeEvery(GET_CALENDER_MEETING_DETAILS, getCalendarMeetingDataSaga);
  yield takeEvery(GET_DASHBOARD_TASK_DETAILS, getDashboardTaskDataSaga);
  yield takeEvery(
    GET_ECOSYSTEM_MAPPING_SECTION_DETAILS,
    getEcosystemMappingSectionDataSaga
  );
  yield takeEvery(
    POST_ECOSYSTEM_MAPPING_SECTION_DETAILS,
    postEcosystemMappingSectionDataSaga
  );
  yield takeEvery(GET_NOTIFICATION_DETAILS, getNoticationsDetailsSaga);
  yield takeEvery(POST_NOTIFICATION_DETAILS, postNoticationsDetailsSaga);
  yield takeEvery(GET_FILE_MANAGER_DETAILS, getFileManagerDetailsSaga);
  yield takeEvery(POST_FILE_MANAGER_DETAILS, postFilemanagerDetailsSaga);
  yield takeEvery(
    GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA,
    getExecutionReadinessFunctionalAreaDetailsSaga
  );

  yield takeEvery(GET_ANSOFF_MATRIX_GRID_DETAILS, getAnsoffMatrixGridDataSaga);
  yield takeEvery(POST_ANSOFF_MATRIX_DETAILS, postAnsoffMatrixDataSaga);
  yield takeEvery(
    POST_EXECUTION_READINESS_DETAILS,
    postExecutionReadinessDetailsSaga
  );
  yield takeEvery(
    GET_EXECUTION_READINESS_REVIEW_DETAILS,
    getExecutionReadinessReviewDetailsSaga
  );
  yield takeEvery(
    POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS,
    postExecutionReadinessCustomerInputDetailsSaga
  );
  yield takeEvery(
    POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
    postExecutionReadinessCustomerAnswerChoiceDetailsSaga
  );
  yield takeEvery(
    POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS,
    postNewStrategyReadinessFunctionsSaga
  );
  yield takeEvery(
    GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS,
    getStrategyReadinessExistedFunctionsSaga
  );

  // yield takeEvery(
  //   POST_STRATEGY_READINESS_FUNCTIONALAREA,
  //   postStrategyReadinessFunctionalAreaDetailsSaga
  // );

  yield takeEvery(
    GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA,
    getStrategyReadinessFunctionalAreaDetailsSaga
  );

  yield takeEvery(
    POST_STRATEGY_READINESS_DETAILS,
    postStrategyReadinessDetailsSaga
  );

  yield takeEvery(
    GET_STRATEGY_READINESS_REVIEW_DETAILS,
    getStrategyReadinessReviewDetailsSaga
  );

  yield takeEvery(
    POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS,
    postStrategyReadinessCustomerInputDetailsSaga
  );

  yield takeEvery(
    POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
    postStrategyReadinessCustomerAnswerChoiceDetailsSaga
  );
  yield takeEvery(POST_FOLDER_MANAGER_ADD_NEW, postFloderManagerAddNewSaga);
  yield takeEvery(POST_FOLDER_MANAGER_EDIT, postFloderManagerEditSaga);
  yield takeEvery(POST_FOLDER_MANAGER_DELETE, postFloderManagerDeleteSaga);
  yield takeEvery(
    POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER,
    postUploadFileInSpecificFolderSaga
  );
  yield takeEvery(GET_DRIVE_FILES, getDriveFilesSaga);
  yield takeEvery(GET_PROJECT_SUMMARY_DETAILS, getProjectSummaryDetailsSaga);
  yield takeEvery(GET_LOCAL_FOLDER_MANAGER, getLocalFolderManagerSaga);
  yield takeEvery(POST_LOCAL_FOLDER_MANAGER, postLocalFolderManagerSaga);
  yield takeEvery(
    POST_LOCAL_FOLDER_MANAGER_FILE_DELETE,
    postLocalFolderManagerFileDeleteSaga
  );
  yield takeEvery(GET_SCREEN_COMMENTS, getScreenCommentsSaga);
  yield takeEvery(POST_SCREEN_COMMENTS, postScreenCommentsSaga);
  yield takeEvery(GET_BI_FGOAL_STATUS, getBiFGoalStatusSaga);
  yield takeEvery(POST_BI_FGOAL_STATUS, postBiFGoalStatusSaga);
  yield takeEvery(GET_GLOBAL_SERACH_FILTER, getGetGlobalSearchFilterSaga);
  yield takeEvery(GET_IS_MAINTENANCE_MODE, getIsMaintenanceModeSaga);
  yield takeEvery(GET_XLSTRATEGYPLAN_DETAILS, getXLStrategyPlanDetailsSaga);
  yield takeEvery(POST_XLSTRATEGYPLAN_DETAILS, postXLStrategyPlanDetailsSaga);
}
