import { useState, useEffect } from "react";
// import { ProSidebarProvider, useProSidebar } from "react-pro-sidebar";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import MainComponent from "./MainComponent";
import { useNavigate } from "react-router-dom";
import Example from "./BICard.js";
import {
  biDetailsList,
  getDashboardTaskDetails,
} from "../store/actions/index.js";
import WidgetIcon from "../assets/images/widget.svg";
import TaskWidgetIcon from "../assets/images/taskwidget.svg";
import Add from "../assets/images/add.svg";
import { createStatus } from "./gtmExecution/WorkboardProgress";
import Calendar from "../assets/images/Calendar.svg";
import Login from "./Login.js";

function Dashboard() {
  const username = localStorage.getItem("full_name");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isBIFetched = useSelector((state) => state.isBIFetched);
  const biDetails = useSelector((state) => state.biDetails);
  const [biData, setBiData] = useState([]);
  const fetchDashboardTaskData = useSelector(
    (state) => state.fetchDashboardTaskData
  );
  const [taskList, setTaskList] = useState([]);

  const handleSelect = () => {
    localStorage.removeItem("bi_id");
    navigate("/bidetails");
    window.location.reload();
    localStorage.setItem("newbuttonClicked", JSON.stringify(true));
  };

  useEffect(() => {
    dispatch(biDetailsList());
    dispatch(getDashboardTaskDetails());
    return () => {};
  }, []);

  useEffect(() => {
    if (isBIFetched) {
      if (biDetails) {
        if (biDetails.ok) {
          if (biDetails.data) {
            setBiData(biDetails.data.bi_data);
          }
        } else if (biDetails.status === "403") {
          return <div>{alert("Session Expired")}</div>;
        }
      }
    }
    return () => {};
  }, [isBIFetched]);

  useEffect(() => {
    if (
      fetchDashboardTaskData &&
      fetchDashboardTaskData.task_data &&
      fetchDashboardTaskData.task_data.length > 0
    ) {
      setTaskList(fetchDashboardTaskData.task_data);
    }
  }, [fetchDashboardTaskData]);

  const content = (
    <div>
      <div
        style={{
          fontSize: "var(--main-heading)",
          fontWeight: "var(--heading-font-weight)",
        }}
      >
        Hi {username} <span>&#x21; &#128075;</span>
      </div>
      <div style={{ fontSize: "var(--sub-heading-font-size)" }}>
        Welcome to your Dashboard. Start by creating your first Business
        Initiative
      </div>
      <div
        className=""
        style={{
          border: "thin solid",
          borderStyle: "ridge",
          borderRadius: "10px",
          height: "65vh",
          textAlign: "center",
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            marginTop: "20%",
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          New Business Initiative
        </div>
        <p>Accelerate your growth by oboarding your Inititaive.</p>
        <Button
          onClick={handleSelect}
          style={{
            backgroundColor: "#0A5F59",
            text: "center",
            width: 150,
            height: 43,
            border: "1px solid var(--rs-border-primary)",
          }}
        >
          Create New BI
        </Button>
      </div>
    </div>
  );

  var bi_content = (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <div
        style={{
          fontSize: "var(--main-heading)",
          fontWeight: "var(--heading-font-weight)",
        }}
      >
        Hi {username} <span>&#x21; &#128075;</span>
      </div>
      <div style={{ fontSize: "var(--sub-heading-font-size)" }}>
        Here's what is happening in your organisation today.
      </div>
      <div style={{ display: "flex", width: "100%", height: "95%" }}>
        <div
          style={{
            width: "100%",
            height: "95%",
            paddingRight: 10,
            // borderRight: "1px solid#ccc",
          }}
        >
          <div
            className=""
            style={{
              width: "98%",
            }}
          >
            <div style={{ textAlign: "right", paddingBottom: 20 }}>
              <button
                style={{
                  width: "100px",
                  height: "36px",
                  background: "white",
                  border: "1px solid #EAEAEA",
                  borderRadius: "10px",
                }}
                onClick={handleSelect}
              >
                <img
                  alt="logo"
                  src={Add}
                  width={16}
                  height={16}
                  style={{ marginRight: "5px" }}
                ></img>
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  New BI
                </span>
              </button>
            </div>
            <Example cardContents={biData} />
            <hr style={{ borderBottom: "1px solid #000", borderTop: 0 }} />
          </div>
          {/* <div
            style={{
              width: "98%",
              height: "55%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img alt="" src={TaskWidgetIcon} />
            <b>No Tasks</b>
            <p>You don't have any tasks assigned to you</p>
          </div> */}
          <div>
            <div
              style={{
                fontSize: "var(--main-heading)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              My Tasks
            </div>
            {taskList && taskList.length > 0 ? (
              <div
                style={{
                  border: "1px solid lightgray",
                  height: "50vh",
                  overflowY: "auto",
                  borderRadius: "5px",
                }}
              >
                <table className="dashboard-tasks-table">
                  <thead style={{ backgroundColor: "#f6f8fa" }}>
                    <tr>
                      <th style={{ textAlign: "left", paddingLeft: "30px" }}>
                        Task Name
                      </th>
                      <th style={{ textAlign: "left" }}>BI Name</th>
                      <th>Status</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  {taskList.length > 0 &&
                    taskList.map((tasks, taskIndex) => (
                      <>
                        <tbody style={{ border: "1px solid lightgray" }}>
                          <tr key={taskIndex}>
                            <td style={{ paddingLeft: "20px !important" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "20px",
                                }}
                              >
                                {tasks.name}
                              </div>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {tasks.bi_name}
                            </td>
                            <td>{createStatus(tasks.status)}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  // width: "100px",
                                }}
                              >
                                <img
                                  alt=""
                                  src={Calendar}
                                  style={{ margin: "10px" }}
                                />
                                {tasks.exp_end_date ? (
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {tasks.exp_end_date}
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      color: "gray",
                                      marginBottom: "0px",
                                      width: "90px",
                                    }}
                                  >
                                    Select Date
                                  </p>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </>
                    ))}
                </table>
              </div>
            ) : (
              <>
                <h5
                  style={{
                    paddingTop: "50px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  There are no tasks to show
                </h5>
              </>
            )}
          </div>
        </div>
        {/* <div
          style={{
            height: "90%",
            width: "35%",
            paddingLeft: 10,
          }}
        >
          <div
            style={{
              height: "350px",
              width: "98%",
              borderBottom: "1px solid #ccc",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img alt="" src={WidgetIcon} />
            <b>Add Widget</b>
            <p>Select from various widgets to customise your experience.</p>
          </div>
          <div
            style={{
              height: "350px",
              width: "98%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <img alt="" src={WidgetIcon} />
            <b>Add Widget</b>
            <p>Select from various widgets to customise your experience.</p>
          </div>
        </div> */}
      </div>
    </div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
      <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={biData.length > 0 ? bi_content : content}
          showSideBar={false}
          breadcrumbs={"Dashboard"}
          showTemplate={true}
          showFullScreen={true}
        />
      </>
    );
  }
}

export default Dashboard;
