import React from "react";
import { connect } from "react-redux";
import Login from "./components/base/Login";
import "font-awesome/css/font-awesome.min.css";
import "./variables.css";
// import Navigation from "./components/navigation";
// import { ActionCreators } from "./components/store/actions/index.js";

function App() {
  return <Login />;
}

export default App;
