import React, { useState, useEffect } from "react";

import { BsSearch } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { getKnowledgeBase } from "../../store/actions";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import "./TagSearch.css";

const TagSearch = ({ tags }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tagsFound, setTagsFound] = useState(true);
  const [searching, setSearching] = useState(false);

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length < 3) {
      setFilteredTags([]);
      setTagsFound(true);
      setSearching(false);
    } else {
      setSearching(true);
      const filtered = tags.filter((tag) =>
        tag.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredTags(filtered);
      setTagsFound(filtered.length > 0);
      setSearching(filtered.length > 0);
    }

    // Show/hide dropdown based on search query length and filtered tags
    setShowDropdown(query.length >= 3 && tagsFound);
  };

  useEffect(() => {
    // setSearchQuery(localStorage.getItem("old_screen_name"));
    // const referrerUrl = new URL(document.referrer).pathname;
    // if (referrerUrl == "/ansoffmatrix") {
    //   setSearchQuery("Ansoff Matrix");
    // } else if (referrerUrl == "/xlstrgyplan") {
    //   setSearchQuery("XL Strategy Plan");
    // } else if (referrerUrl == "/workboard") {
    //   setSearchQuery("Workboard");
    // } else if (referrerUrl == "/kb/templates") {
    //   setSearchQuery("Templates");
    // } else {
    //   setSearchQuery("Dashboard");
    // }
  }, []);

  const handleItemClick = (tag) => {
    setSearchQuery(tag);
    setShowDropdown(false);
    setTagsFound(true);
    dispatch(getKnowledgeBase(false, tag));
  };

  const handleClearFilter = () => {
    localStorage.setItem("old_screenid", null);
    setSearchQuery("");
    dispatch(getKnowledgeBase());
  };

  return (
    <div className="search-container">
      <div className="search-input">
        <input
          type="search"
          className="form-control search_input"
          // type="text"
          placeholder="Search templates with tags..."
          value={searchQuery}
          onChange={handleInputChange}
          style={{ minWidth: "70%", marginBottom: 20 }}
          onInput={(e) => {
            if (!e.target.value) {
              handleClearFilter();
            }
          }}
        />
      </div>
      {showDropdown && tagsFound ? (
        <ul className="search-results" style={{ width: "100%" }}>
          {filteredTags.map((tag, index) => (
            <li
              key={index}
              className="search-result-item"
              onClick={() => handleItemClick(tag)}
            >
              {tag}
            </li>
          ))}
        </ul>
      ) : (
        !tagsFound &&
        searchQuery &&
        searchQuery.length >= 3 && <p>No templates available</p>
      )}
      {searchQuery && searchQuery.length < 3 && searchQuery.length > 0 && (
        <p>Type at least 3 characters to search</p>
      )}
      {/* {searching && searchQuery.length >= 3 && <p>Searching...</p>} */}
    </div>
  );
};

export default TagSearch;
