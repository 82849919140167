import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb({
  breadcrumbItems,
  SecondSideBarClicked,
  isSecSideBarOpen,
}) {
  const nonEmptyBreadcrumbItems = breadcrumbItems.filter(
    (item) => item.text !== "" && item.text !== undefined
  );
  return (
    <p>
      {nonEmptyBreadcrumbItems.map((item, index) => (
        <React.Fragment key={index}>
          <>
            <Link
              to={item.link}
              style={{
                color:
                  index !== nonEmptyBreadcrumbItems.length - 1 ? "" : "black",
                textDecoration:
                  index !== nonEmptyBreadcrumbItems.length - 1 ? "" : "none",
                fontSize: "var(--captions-font-size)",
              }}
            >
              {index === 0 ? (
                // <span onClick={() => SecondSideBarClicked(!isSecSideBarOpen)}>
                <span>
                  {item.text}
                </span>
              ) : (
                <span>{item.text}</span>
              )}
            </Link>
            {index < nonEmptyBreadcrumbItems.length - 1 && (
              <span
                style={{
                  padding: "0px 5px",
                  fontSize: "var(--captions-font-size)",
                }}
              >
                &gt;
              </span>
            )}
          </>
        </React.Fragment>
      ))}
    </p>
  );
}

export default Breadcrumb;
