import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainComponent from "../../MainComponent";
import Login from "../../Login";

function TargetMarketShare() {
  const [currentDocURL, setCurrentDocURL] = useState("");
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);

  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);

  const content = (
    <div style={{ height: "100%", width: "100%" }}>
      {currentDocURL && (
        <>
          <a
            style={{
              float: "right",
              marginBottom: 5,
              textDecoration: "none",
              border: 0,
              padding: 5,
              borderRadius: 10,
              background: "#0A5F59",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            target="_blank"
            href={currentDocURL}
          >
            Popout
          </a>
          <iframe height="100%" width="100%" src={currentDocURL} />
        </>
      )}
    </div>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={false}
        showProducts={true}
        breadcrumbParent={"Strategy Validation"}
        breadcrumbChild={"Target Market Share"}
      />
    );
  }
}

export default TargetMarketShare;
