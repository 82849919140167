import React, { useRef, useEffect } from "react";
import { Button } from "react-bootstrap";

const InputField = ({
  onChange,
  handleSubmit,
  Close,
  placeholder,
  name,
  handleKeyDown,
  disabled,
  value,
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    const handleInputFocus = () => {
      inputRef.current.focus();
      inputRef.current.select();
    };

    if (inputRef.current) {
      inputRef.current.addEventListener("focus", handleInputFocus);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("focus", handleInputFocus);
      }
    };
  }, []);

  return (
    <>
      <input
        style={{ fontSize: "var(--sub-heading-font-size)" }}
        className="form-control"
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        onKeyDown={handleKeyDown}
        value={value}
        autoFocus
      ></input>
      <Button
        style={{
          marginLeft: "10px",
          backgroundColor: "#0A5F59",
          borderColor: "#0A5F59",
          height: 35,
        }}
        onClick={handleSubmit}
        disabled={disabled}
      >
        Save
      </Button>
      <Button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "#0A5F59",
          height: 35,
        }}
        onClick={Close}
      >
        Cancel
      </Button>
    </>
  );
};

export default InputField;
