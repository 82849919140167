import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Objective from "../../assets/images/Objective.svg";
import Goals from "../../assets/images/Goals.svg";
import InputField from "./InputField";
import Arrow from "../../assets/images/arrow-circle-down.svg";
import ArrowSIMP from "../../assets/images/arrow-bottom.svg";
import Repeat from "../../assets/images/repeat.svg";
import "./XLExecutionPlan.css";
import ElipsisVerticalIcon from "../../assets/images/ellipsis-vertical-solid.svg";
import ProfileAdd from "../../assets/images/profile-add.svg";
import EditIcon from "../../assets/images/Edit.svg";
import DeleteIcon from "../../assets/images/trash.svg";
import AddFolder from "../../assets/images/add-folder.png";
import AddIcon from "../../assets/images/add.svg";
import { v4 as uuidv4 } from "uuid";
import ManageOptions from "./ManageOptions";
import AddExistingpopup from "./AddExistingpopup";
import ChevronRight from "../../assets/images/Chevron-right.svg";
import keys from "../../utils/helpInfoKeys.json";
import Select from "react-select";
import { Button } from "react-bootstrap";
import Assign from "../../assets/images/assign.svg";

import {
  getEmployeeData,
  getMasterTaskPriorityAndStatusData,
  getXLExecutionPlanDetails,
  postCopyMasterFunctionsXLStrategy,
  postEditStrategyObjective,
  postStrategyFunctions,
  postStrategyGoals,
} from "../../store/actions";
import XLExecutionPlanSidebar from "./XLExecutionPlanSidebar";
import { createImageFromInitials, getRandomColor } from "../Utils";
import DisabledGoalSelector from "./DisabledGoalSelector";
import CommentSection from "../../utils/CommentSection";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";

function XLExecutionPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [biName, setBIName] = useState(null);
  const [isobjectiveVisible, setIsObjectiveVisible] = useState(true);
  const [isGoalVisible, setIsGoalVisible] = useState(true);
  const [isFunctionVisible, setIsFunctionVisible] = useState(true);
  const [issimpVisible, setIsSimpVisible] = useState(true);
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [objectiveData, setObjectiveData] = useState([]);
  const [objectiveList, setObjectiveList] = useState([]);
  const [goalData, setGoalData] = useState([]);
  const [functionData, setFunctionData] = useState([]);
  const [goalList, setGoalList] = useState([]);
  const [functionList, setFunctionList] = useState([]);
  const [functionModel, setFunctionModel] = useState(false);
  const [isMilestoneVisible, setIsMilestoneVisible] = useState(true);
  const [selectedFunctionIndex, setSelectedFunctionIndex] = useState(-1);
  const [collapsedItems, setCollapsedItems] = useState({});
  const [strategicItems, setStrategicItems] = useState({});
  const [newStrategicItem, setNewStrategicItem] = useState("");
  const selectedBI = useSelector((state) => state.selectedBI);
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [showIcons, setShowIcons] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [GoalModalIsOpen, setGoalModalIsOpen] = useState(false);
  const [onElipsisshowIcons, setOnElipsisshowIcons] = useState(false);
  const [currentItemId, setCurrentItemId] = useState("");
  const [singleGoal, setSingleGoal] = useState(false);
  const [show, setShow] = useState(false);
  const [showGoalPopup, setShowGoalPopup] = useState(false);
  const [showFunctionPopup, setShowFunctionPopup] = useState(false);
  const [showSimpPopup, setShowSimpPopup] = useState(false);
  const [showMilestonePopup, setShowMilestonePopup] = useState(false);
  const [milestoneItems, setMilestoneItems] = useState({});
  const [newMilestoneItem, setNewMilestoneItem] = useState("");
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState("");
  const [currentImperativeIndex, setCurrentImperativeIndex] = useState("");
  const [currentMilestoneIndex, setCurrentMilestoneIndex] = useState("");
  const [editPopupshow, setEditPopupShow] = useState(false);
  const [currentFunctionId, setCurrentFunctionId] = useState("");
  const [currentImperativeId, setCurrentImperativeId] = useState("");
  const [currentMilestoneId, setCurrentMilestoneId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarType, setSidebarType] = useState();
  const [placeholder, setPlaceholder] = useState();
  const [AssingOwnerClick, setAssignOwnerClick] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const strategyPlanData = useSelector((state) => state.strategyPlanData);
  const isGoalDataFetched = useSelector((state) => state.isGoalDataFetched);
  const isFunctionDataFetched = useSelector(
    (state) => state.isFunctionDataFetched
  );
  const postGoalDetails = useSelector((state) => state.postGoalDetails);
  const postStrategyFunctionDetails = useSelector(
    (state) => state.postStrategyFunctionDetails
  );
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const [empData, setEmpData] = useState(null);
  const [dropdownGoalData, setDropdownGoalData] = useState([]);
  const [strategicItemValues, setStrategicItemValues] = useState(
    Array(functionList.length).fill("")
  );
  const [milestoneValues, setMilestoneValues] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [statusData, setStatusData] = useState([]);
  const [priorityData, setPriorityData] = useState([]);
  const getMasterTasksPriorityAndStatusDetails = useSelector(
    (state) => state.getMasterTasksPriorityAndStatusDetails
  );
  const updateStrategicItemValue = (index, value) => {
    const updatedValues = [...strategicItemValues];
    updatedValues[index] = value;
    setStrategicItemValues(updatedValues);
  };
  const handleAssignOwnerClick = (e, fun_id) => {
    e.preventDefault();
    setCurrentFunctionId(fun_id);
    setAssignOwnerClick(true);
  };
  const [formData, setFormData] = useState({
    name: "",
    assigned_to: null,
  });
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      assigned_to: e.label,
    });
    setSelectedOption(e);
  };
  useEffect(() => {
    setSelectedOption();
  }, []);

  const handleOwnerSubmit = (e) => {
    e.preventDefault();
    const assignedToList = [formData.assigned_to];
    const requiredAssignedData = empData.map((item) => ({
      key: item.value,
      is_active: assignedToList.some(
        (selectedItem) => selectedItem === item.label
      ),
      label: item.label,
    }));
    let data = [
      {
        bi_id: localStorage.getItem("bi_id"),
        product_id: localStorage.getItem("product_id"),
        function_id: currentFunctionId,
        assigned_to: requiredAssignedData,
        is_active: true,
      },
    ];
    dispatch(postStrategyFunctions(data, "function", true));
    setAssignOwnerClick(false);
    setCurrentFunctionId("");
  };

  const updateMilestoneValue = (functionIndex, imperativeIndex, value) => {
    const updatedValues = [...milestoneValues];
    updatedValues[functionIndex][imperativeIndex] = value;
    setMilestoneValues(updatedValues);
  };
  const [breadcrumbs, setBreadcrumbs] = useState("");

  useEffect(() => {
    if (getMasterTasksPriorityAndStatusDetails) {
      const transformedStatusOptions =
        getMasterTasksPriorityAndStatusDetails.status_dt.map((status) => ({
          value: status,
          label: status,
        }));
      setStatusData(transformedStatusOptions);
      const transformedPriorityOptions =
        getMasterTasksPriorityAndStatusDetails.priority_dt.map((priority) => ({
          value: priority,
          label: priority,
        }));
      setPriorityData(transformedPriorityOptions);
    }
  }, [getMasterTasksPriorityAndStatusDetails]);

  useEffect(() => {
    if (
      getEmployeeDetails &&
      getEmployeeDetails.emp_data &&
      getEmployeeDetails.emp_data.length > 0
    ) {
      setEmpData(
        getEmployeeDetails.emp_data.map((obj) => {
          return {
            value: obj.id,
            label: obj.prop_name,
            icon: createImageFromInitials(
              30,
              obj.prop_name,
              getRandomColor(obj.prop_name)
            ),
          };
        })
      );
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    dispatch(
      getXLExecutionPlanDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
    dispatch(getEmployeeData());
    dispatch(getMasterTaskPriorityAndStatusData());
  }, []);

  useEffect(() => {
    if (strategyPlanData) {
      setObjectiveList(strategyPlanData.objective);
      setGoalList(strategyPlanData.goals);
      const updatedFunctionList = strategyPlanData.functions.map(
        (functionItem) => ({
          ...functionItem,
          isCollapsed: true, // Set isCollapsed to false for all items
        })
      );

      setFunctionList(updatedFunctionList);
      setMilestoneValues(
        strategyPlanData.functions.map((functionItem) =>
          functionItem.strategic_imperative.map(() => "")
        )
      );
      setDropdownGoalData(
        strategyPlanData.goals.map((goal, i) => {
          return {
            value: goal.id,
            label: goal.short_name,
          };
        })
      );
    }
  }, [strategyPlanData]);

  useEffect(() => {
    if (selectedBI) {
      setBIName(selectedBI.label);
    }
  }, [selectedBI]);

  useEffect(() => {
    if (goalList.length === 1) {
      setSingleGoal(true);
    } else {
      setSingleGoal(false);
    }
  }, [goalList]);

  useEffect(
    (isGoalDataFetched) => {
      if (postGoalDetails && postGoalDetails.goal_res) {
        const newGoals = postGoalDetails.goal_res
          .filter((goal) => goal.action === "add")
          .map((goal) => ({
            name: goal.goal_name,
            id: goal.id,
            short_name: goal.short_name,
          }));
        setGoalList([...goalList, ...newGoals]);
      }
    },
    [isGoalDataFetched, postGoalDetails]
  );

  useEffect(() => {
    if (postStrategyFunctionDetails && postStrategyFunctionDetails.response) {
      if (postStrategyFunctionDetails.stage_type === "function") {
        postStrategyFunctionDetails.response.forEach((item) => {
          const index = functionList.findIndex((func) => func.id === item.key);
          if (index !== -1) {
            const updatedFunction = {
              ...functionList[index],
              assigned_to: item.assigned_to,
            };
            setFunctionList((prevList) => [
              ...prevList.slice(0, index),
              updatedFunction,
              ...prevList.slice(index + 1),
            ]);
          } else {
            const newFunction = {
              id: item.id,
              assigned_to: item.assigned_to,
            };
            setFunctionList((prevList) => [...prevList, newFunction]);
          }
        });
      }
    }
  }, [postStrategyFunctionDetails]);
  useEffect(
    (isFunctionDataFetched) => {
      if (
        postStrategyFunctionDetails &&
        postStrategyFunctionDetails.response &&
        postStrategyFunctionDetails.action === "add"
      ) {
        if (postStrategyFunctionDetails.stage_type === "function") {
          postStrategyFunctionDetails.response.map((item) => {
            const newFunction = {
              name: item.value,
              id: item.key,
              strategic_imperative: [],
            };
            setFunctionList([...functionList, newFunction]);
          });
        } else if (
          postStrategyFunctionDetails.stage_type === "functional_goal"
        ) {
          postStrategyFunctionDetails.response.map((item) => {
            const newFunctions = [...functionList];
            newFunctions[currentFunctionIndex] &&
              newFunctions[currentFunctionIndex].strategic_imperative.push({
                name: item.value,
                id: item.key,
                status: item.dt_status,
                due_date: item.exp_end_date,
                assigned_to: item.assigned_to,
                priority: item.priority,
                progress: item.progress,
                description: item.description,
                collaberators: item.collaberators,
                // goals_data: item.goals_data,
                goals_data: [],
                milestones: [],
              });
            setFunctionList(newFunctions);
          });
        } else if (postStrategyFunctionDetails.stage_type === "milestone") {
          postStrategyFunctionDetails.response.map((item) => {
            const newFunctions = [...functionList];
            newFunctions[currentFunctionIndex] &&
              newFunctions[currentFunctionIndex].strategic_imperative[
                currentImperativeIndex
              ].milestones.push({
                name: item.value,
                id: item.key,
                status: item.dt_status,
                start_date: item.exp_start_date,
                due_date: item.exp_end_date,
                assigned_to: item.assigned_to,
                priority: item.priority,
                progress: item.progress,
                description: item.description,
                collaberators: item.collaberators,
                tasks: [],
              });
            setFunctionList(newFunctions);
          });
        }
      }
    },
    [isFunctionDataFetched, postStrategyFunctionDetails]
  );

  const handleClick = () => {
    navigate("/workboard");
  };
  const handleBack = () => {
    if (
      selectedSubMenuPermission === "write" ||
      selectedSubMenuPermission === "admin"
    ) {
      navigate("/strategyplan");
    } else {
      navigate("/dashboard");
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const openGoalModal = () => {
    setGoalModalIsOpen(true);
  };

  const openFunctionModel = () => {
    setFunctionModel(true);
  };

  const setSimpModel = (functionIndex, value) => {
    setStrategicItems((prev) => ({ ...prev, [functionIndex]: value }));
  };

  const setMilestoneModel = (functionIndex, simpIndex, value) => {
    setMilestoneItems((prev) => ({
      ...prev,
      [functionIndex]: {
        ...prev[functionIndex],
        [simpIndex]: value,
      },
    }));
  };

  const handleObjectveSubmit = (e, id = false) => {
    e.preventDefault();
    if (objectiveData !== "") {
      if (id) {
        // Update the existing item in the list
        dispatch(
          postEditStrategyObjective(
            localStorage.getItem("bi_id"),
            objectiveData
          )
        );
        setObjectiveList((prevList) =>
          prevList.map((item) =>
            item.id === currentItemId ? { ...item, name: objectiveData } : item
          )
        );
      } else {
        // Add a new item to the list
        const newObjective = {
          name: objectiveData,
          id: uuidv4(),
        };
        setObjectiveList([...objectiveList, newObjective]);
      }
    }

    // Clear the currentItemId and exit edit mode
    setCurrentItemId("");
    setEditMode(false);
    setIsObjectiveVisible(false);
    setGoalModalIsOpen(true);
    setIsFunctionVisible(true);
  };

  const handleGoalSubmit = (e) => {
    e.preventDefault();
    if (goalData !== "") {
      if (currentItemId && currentItemId !== "") {
        // Update the existing item in the list
        setGoalList((prevList) =>
          prevList.map((item) =>
            item.id === currentItemId ? { ...item, name: goalData } : item
          )
        );
        dispatch(
          postStrategyGoals([
            {
              goal_dt: goalData,
              goal_id: currentItemId,
              is_active: true,
            },
          ])
        );
      } else {
        dispatch(
          postStrategyGoals([
            {
              bi_id: localStorage.getItem("bi_id"),
              product_id: localStorage.getItem("product_id"),
              goal_dt: goalData,
              is_active: true,
            },
          ])
        );
      }

      if (goalList.length === 1) {
        setSingleGoal(true);
      }

      setCurrentItemId("");
      setGoalModalIsOpen(false);
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    img: {
      height: 40,
    },
  };
  const handleFunctionSubmit = (e) => {
    e.preventDefault();
    if (functionData !== "") {
      if (currentItemId && currentItemId !== "") {
        // Update the existing function in the list
        setFunctionList((prevList) =>
          prevList.map((functionItem) =>
            functionItem.id === currentItemId
              ? { ...functionItem, name: functionData }
              : functionItem
          )
        );
        let data = [
          {
            function_id: currentItemId,
            function_name: functionData,
            is_active: true,
          },
        ];
        dispatch(postStrategyFunctions(data, "function", true));
        setEditMode(false);
      } else {
        // Add a new function to the list

        let data = [
          {
            bi_id: localStorage.getItem("bi_id"),
            product_id: localStorage.getItem("product_id"),
            function_name: functionData,
            function_id: false,
          },
        ];
        dispatch(postStrategyFunctions(data, "function", true));
      }

      setCurrentItemId("");
      setFunctionData("");
      setFunctionModel(false);
      setIsSimpVisible(true);
    }
  };

  const handleAddStrategicItem = (
    e,
    functionIndex,
    functionId,
    imperative_id = false,
    simpIndex = false
  ) => {
    e.preventDefault();
    setSelectedFunctionIndex(functionIndex);

    if (imperative_id) {
      const newFunctions = [...functionList];
      newFunctions[functionIndex].strategic_imperative[simpIndex].name =
        newStrategicItem;
      let data = [
        {
          imperative_name: newStrategicItem,
          imperative_id: imperative_id,
          is_active: true,
        },
      ];
      dispatch(postStrategyFunctions(data, "functional_goal", false, true));
      setEditMode(false);
      setFunctionList(newFunctions);
    } else {
      setCurrentFunctionIndex(functionIndex);
      let data = [
        {
          bi_id: localStorage.getItem("bi_id"),
          product_id: localStorage.getItem("product_id"),
          function_id: functionId,
          imperative_name: strategicItemValues[functionIndex],
          imperative_id: false,
          status: "Open",
          due_date: "",
          priority: "High",
          assigned_to: "",
          progress: 0,
          description: "",
          collaberators: "",
        },
      ];
      dispatch(postStrategyFunctions(data, "functional_goal", false, true));
    }

    // setNewStrategicItem("");
    updateStrategicItemValue(functionIndex, "");
    setStrategicItems((prev) => ({ ...prev, [functionIndex]: false }));
    setIsSimpVisible(true);
    setIsMilestoneVisible(true);
    setSimpModel(functionIndex, true);
  };

  const handleAddMilestoneItem = (
    e,
    functionIndex,
    simpIndex,
    milestoneIndex = false,
    simpId,
    milestoneId
  ) => {
    e.preventDefault();
    setSelectedFunctionIndex(functionIndex);

    if (currentItemId && currentItemId !== "") {
      const newFunctions = [...functionList];
      newFunctions[functionIndex].strategic_imperative[simpIndex].milestones[
        milestoneIndex
      ].name = newMilestoneItem; // Update the milestone name
      setEditMode(false);
      let data = [
        {
          milestone: newMilestoneItem,
          milestone_id: milestoneId,
          is_active: true,
        },
      ];
      dispatch(postStrategyFunctions(data, "milestone", false, false, true));
      setFunctionList(newFunctions);
    } else {
      setCurrentFunctionIndex(functionIndex);
      setCurrentImperativeIndex(simpIndex);
      let data = [
        {
          imperative_id: simpId,
          milestone: milestoneValues[functionIndex][simpIndex],
          milestone_id: false,
          status: "Open",
          start_date: "",
          due_date: "",
          priority: "High",
          assigned_to: "",
          progress: 0,
          description: "",
          collaberators: "",
        },
      ];
      dispatch(postStrategyFunctions(data, "milestone", false, false, true));
    }
    setCurrentItemId("");

    setMilestoneItems((prev) => ({
      ...prev,
      [functionIndex]: { ...prev[functionIndex], [simpIndex]: false },
    }));
    setMilestoneModel(functionIndex, simpIndex, true);
    setIsMilestoneVisible(true);

    setNewMilestoneItem("");
    updateMilestoneValue(functionIndex, simpIndex, "");
  };

  const handleKeyDownObj = (e) => {
    if (e.key === "Enter") {
      handleObjectveSubmit(e);
    } else if (e.key === "Escape") {
      setModalIsOpen(false);
    }
  };
  const handleKeyDownGoal = (e) => {
    if (e.key === "Enter") {
      handleGoalSubmit(e);
    } else if (e.key === "Escape") {
      setModalIsOpen(false);
    }
  };
  const handleKeyDownFunction = (e) => {
    if (e.key === "Enter") {
      handleFunctionSubmit(e);
    } else if (e.key === "Escape") {
      setFunctionModel(false);
    }
  };

  const handleCollapseToggle = (functionIndex) => {
    setFunctionList((prevFunctionList) => {
      const newList = [...prevFunctionList];
      newList[functionIndex].isCollapsed = !newList[functionIndex].isCollapsed; // Toggle the collapse state for the specific function
      return newList;
    });
  };

  const handleCollapseToggleSimp = (simpIndex) => {
    setCollapsedItems((prevCollapsedItems) => ({
      ...prevCollapsedItems,
      [simpIndex]: !prevCollapsedItems[simpIndex],
    }));
  };

  // popup close and open
  const handlePopupClose = () => {
    setShowGoalPopup(false);
  };

  const handlePopupShow = () => {
    setShowGoalPopup(true);
  };

  const handleEditClick = (action, name, id) => {
    if (action == "objective") {
      setObjectiveData(name);
    } else if (action == "goal") {
      setGoalData(name);
    } else if (action == "function") {
      setFunctionData(name);
    } else if (action == "strategicItem") {
      setNewStrategicItem(name);
    } else if (action == "milestone") {
      setNewMilestoneItem(name);
    }
    setCurrentItemId(id);
    setEditMode(true);
    setShowIcons(false);
  };

  const handleGoalDelete = (itemId) => {
    const updatedList = goalList.filter((item) => item.id !== itemId);
    setGoalList(updatedList);
    dispatch(
      postStrategyGoals([
        {
          bi_id: localStorage.getItem("bi_id"),
          product_id: localStorage.getItem("product_id"),
          goal_id: itemId,
          is_active: false,
        },
      ])
    );
  };

  const handleEditPopup = () => {
    setEditPopupShow(true);
  };
  const handleEditPopupClose = () => {
    setEditPopupShow(false);
  };

  const handleDeleteFunction = (itemId) => {
    const updatedList = functionList.filter((item) => item.id !== itemId);
    setFunctionList(updatedList);
    let data = [
      {
        function_id: itemId,
        is_active: false,
      },
    ];
    dispatch(postStrategyFunctions(data, "function", true));
  };

  const handleStrategicImpDelete = (functionIndex, simpIndex, simpId) => {
    const newFunctions = [...functionList];
    newFunctions[functionIndex].strategic_imperative.splice(simpIndex, 1);
    setFunctionList(newFunctions);
    let data = [
      {
        imperative_id: simpId,
        is_active: false,
      },
    ];
    dispatch(postStrategyFunctions(data, "functional_goal", false, true));
  };

  const handleStrategicImpEdit = (
    functionIndex,
    simpIndex,
    data,
    updatedAssignedData,
    updatedGoalsData,
    updatedCollaboratorsData
  ) => {
    const newFunctions = [...functionList];
    const existingStrategicImp =
      newFunctions[functionIndex].strategic_imperative[simpIndex];

    newFunctions[functionIndex].strategic_imperative[simpIndex] = {
      ...existingStrategicImp,
      name: data[0].imperative_name,
      status: data[0].status,
      due_date: data[0].due_date,
      priority: data[0].priority,
      assigned_to: updatedAssignedData,
      progress: data[0].progress,
      description: data[0].description,
      collaberators: updatedCollaboratorsData,
      goals_dt: updatedGoalsData,
    };

    dispatch(postStrategyFunctions(data, "functional_goal", false, true));
    setFunctionList(newFunctions);
  };

  const handleMilestoneDelete = (
    functionIndex,
    simpIndex,
    milestoneIndex,
    milestoneId
  ) => {
    const newFunctions = [...functionList];
    newFunctions[functionIndex].strategic_imperative[
      simpIndex
    ].milestones.splice(milestoneIndex, 1);
    setFunctionList(newFunctions);
    let data = [
      {
        milestone_id: milestoneId,
        is_active: false,
      },
    ];
    dispatch(postStrategyFunctions(data, "milestone", false, false, true));
  };

  const handleMilestoneEdit = (
    functionIndex,
    simpIndex,
    milestoneIndex,
    data,
    updatedAssignedData
  ) => {
    const newFunctions = [...functionList];
    const existingMilestone =
      newFunctions[functionIndex].strategic_imperative[simpIndex].milestones[
        milestoneIndex
      ];

    newFunctions[functionIndex].strategic_imperative[simpIndex].milestones[
      milestoneIndex
    ] = {
      ...existingMilestone,
      name: data[0].milestone,
      status: data[0].status,
      start_date: data[0].start_date,
      due_date: data[0].due_date,
      priority: data[0].priority,
      assigned_to: updatedAssignedData,
      progress: data[0].progress,
      description: data[0].description,
      collaberators: data[0].collaberators,
    };

    dispatch(postStrategyFunctions(data, "milestone", false, false, true));
    setFunctionList(newFunctions);
  };

  const handleVerticalIconClick = () => {
    setOnElipsisshowIcons(true);
  };
  const handleVerticalIconMouseLeave = () => {
    setOnElipsisshowIcons(false);
  };

  const handleSidebar = (
    is_edit,
    data,
    placeholder,
    type,
    selectedItem,
    functionIndex,
    simpIndex,
    breadcrumbs,
    milestoneIndex
  ) => {
    setIsSidebarOpen(true);
    setIsEdit(is_edit);
    setSelectedData(data);
    setSidebarType(type);
    setPlaceholder(placeholder);
    setSelectedItemId(selectedItem);
    setCurrentFunctionIndex(functionIndex);
    if (simpIndex !== false) {
      setCurrentImperativeIndex(simpIndex);
    }
    if (is_edit && milestoneIndex !== false) {
      setCurrentMilestoneIndex(milestoneIndex);
    }
    setBreadcrumbs(breadcrumbs);
  };
  const handleOwnerCancliClick = () => {
    setAssignOwnerClick(false);
    setCurrentFunctionId("");
  };
  const content = (
    <>
      <div style={{ paddingRight: "10px" }}>
        <div className="d-flex align-items-center biname">
          <img src={Goals} width={"24px"} style={{ marginLeft: "10px" }} />
          <span style={{ paddingLeft: "5px" }}>
            <div
              style={{
                fontSize: "var(--main-heading)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              {biName}
            </div>
          </span>
        </div>
      </div>
      <div>
        <form>
          {objectiveList && objectiveList.length === 0 ? (
            <div
              style={{
                display: isobjectiveVisible ? "flex" : "none",
                alignItems: "center",
                width: "100%",
              }}
            >
              {(selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin") && (
                <div
                  style={{
                    display: !ModalIsOpen ? "flex" : "none",
                    width: "100%",
                  }}
                >
                  <button
                    className="buttonStyle"
                    type="button"
                    onClick={openModal}
                  >
                    <img
                      src={AddIcon}
                      width={16}
                      height={16}
                      style={{ marginRight: "5px" }}
                    />
                    Add Objective
                  </button>
                </div>
              )}
              <div
                className="ObjectInput"
                style={{ display: ModalIsOpen ? "flex" : "none" }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <InputField
                    onChange={(e) => setObjectiveData(e.target.value)}
                    Close={(e) => setModalIsOpen(false)}
                    handleSubmit={(e) => handleObjectveSubmit(e, false)}
                    placeholder="Name your objective"
                    name="objective"
                    handleKeyDown={handleKeyDownObj}
                  />
                </div>
                <div>
                  <AddExistingpopup
                    Title="Add Existing Objective"
                    ModalTitle="My objective library"
                    handleClose={() => handlePopupClose()}
                    show={show}
                    AddFolder={AddFolder}
                    handleShow={() => handlePopupShow()}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              {objectiveList.map((item, index) => (
                <>
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        position: "relative",
                        paddingRight: "10px",
                      }}
                      onMouseEnter={() => setShowIcons(item.id)}
                      onMouseLeave={() => setShowIcons(null)}
                    >
                      <div
                        className="Objective"
                        style={{ wordWrap: "break-word" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={Objective}
                            width={"24px"}
                            style={{ marginLeft: "10px" }}
                          />
                          <span
                            style={{
                              paddingLeft: "5px",
                              fontSize: "var(--sub-heading-font-size)",
                            }}
                          >
                            Objective
                          </span>
                        </div>
                        {/* Display the objective name */}
                        {/* edit option here for objective */}
                        {editMode && item.id === currentItemId ? (
                          <div style={{ width: "80%" }}>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                marginLeft: "40px",
                                marginBottom: "6px",
                              }}
                            >
                              <InputField
                                value={objectiveData}
                                onChange={(e) =>
                                  setObjectiveData(e.target.value)
                                }
                                handleSubmit={(e) =>
                                  handleObjectveSubmit(e, item.id)
                                }
                                Close={() => setEditMode(false)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              className="ObjectiveData"
                              style={{
                                width: "100%",
                                paddingBottom: "10px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                wordWrap: "break-word",
                                textOverflow: "ellipsis",
                                transition: "text-overflow 0.3s",
                                fontSize: "var(--text-font-size)",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.width = "100%";
                                e.target.style.whiteSpace = "normal";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.width = "100%";
                                e.target.style.whiteSpace = "nowrap";
                              }}
                            >
                              {item.name}

                              {/* edit remove component */}
                            </div>
                            {(selectedSubMenuPermission === "write" ||
                              selectedSubMenuPermission === "admin") && (
                              <ManageOptions
                                handleEditClick={() =>
                                  handleEditClick(
                                    "objective",
                                    item.name,
                                    item.id
                                  )
                                }
                                handleDeleteClick={() => {}}
                                showIcons={showIcons === item.id}
                                showDelete={false}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* add goal */}
                  {goalList && goalList.length > 0 && (
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {goalList.map((item, index) => (
                        <div
                          key={item.id}
                          className="goal"
                          style={{ width: singleGoal ? "100%" : "49%" }}
                        >
                          <div
                            style={{
                              position: "relative",
                              alignItems: "center",
                            }}
                            onMouseEnter={() => setShowIcons(item.id)}
                            onMouseLeave={() => setShowIcons(null)}
                          >
                            <div style={{ wordWrap: "break-word" }}>
                              <div
                                className="d-flex align-items-center"
                                style={{ paddingTop: "10px" }}
                              >
                                <img
                                  src={Goals}
                                  width={"24px"}
                                  style={{ marginLeft: "10px" }}
                                />
                                <span
                                  style={{
                                    paddingLeft: "5px",
                                    fontSize: "var(--sub-heading-font-size)",
                                  }}
                                >
                                  Goal ({item.short_name})
                                </span>
                              </div>
                            </div>
                            {/* editing the goal item and submit */}
                            {editMode && item.id === currentItemId ? (
                              <div style={{ width: "90%" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    marginLeft: "40px",
                                  }}
                                >
                                  <InputField
                                    value={goalData}
                                    onChange={(e) =>
                                      setGoalData(e.target.value)
                                    }
                                    handleSubmit={handleGoalSubmit}
                                    Close={() => setEditMode(false)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="ObjectiveData"
                                  style={{
                                    width: "70%",
                                    paddingBottom: "10px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    wordWrap: "break-word",
                                    textOverflow: "ellipsis",
                                    transition: "text-overflow 0.3s",
                                    fontSize: "var(--text-font-size)",
                                  }}
                                  onMouseEnter={(e) => {
                                    e.target.style.width = "100%";
                                    e.target.style.whiteSpace = "normal";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.width = "70%";
                                    e.target.style.whiteSpace = "nowrap";
                                  }}
                                >
                                  {item.name}
                                </div>
                                {/* edit remove component */}
                                {(selectedSubMenuPermission === "write" ||
                                  selectedSubMenuPermission === "admin") && (
                                  <ManageOptions
                                    handleEditClick={() =>
                                      handleEditClick(
                                        "goal",
                                        item.name,
                                        item.id
                                      )
                                    }
                                    handleDeleteClick={() =>
                                      handleGoalDelete(item.id)
                                    }
                                    showIcons={showIcons === item.id}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div
                    style={{
                      display: isGoalVisible ? "flex" : "none",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {(selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin") && (
                      <div
                        style={{
                          display: !GoalModalIsOpen ? "flex" : "none",
                          width: "100%",
                        }}
                      >
                        <button
                          className="buttonStyle"
                          type="button"
                          onClick={openGoalModal}
                        >
                          <img src={AddIcon} width={16} height={16} />
                          <span style={{ paddingLeft: "5px" }}>Add Goal</span>
                        </button>
                      </div>
                    )}
                    <div
                      className="ObjectInput"
                      style={{ display: GoalModalIsOpen ? "flex" : "none" }}
                    >
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <InputField
                          onChange={(e) => setGoalData(e.target.value)}
                          Close={(e) => setGoalModalIsOpen(false)}
                          handleSubmit={handleGoalSubmit}
                          placeholder="Enter your goal"
                          name="goal"
                          handleKeyDown={handleKeyDownGoal}
                        />
                      </div>
                      {/* add existing popup here */}
                      <div>
                        <AddExistingpopup
                          Title="Add Existing Goal"
                          ModalTitle="My Goal Library"
                          handleClose={() => handlePopupClose()}
                          show={showGoalPopup}
                          AddFolder={AddFolder}
                          handleShow={() => handlePopupShow()}
                          stage={"goals"}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
          <>
            <div style={{ marginTop: "10px" }}>
              {functionList.map((functionItem, functionIndex) => (
                <div key={functionIndex} style={{ marginBottom: "10px" }}>
                  <div>
                    {editMode && functionItem.id === currentItemId ? (
                      <div style={{ width: "80%" }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginLeft: "40px",
                            marginBottom: "6px",
                          }}
                        >
                          <InputField
                            value={functionData}
                            onChange={(e) => setFunctionData(e.target.value)}
                            handleSubmit={(e) =>
                              handleFunctionSubmit(e, functionItem.id)
                            }
                            Close={() => setEditMode(false)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="d-flex align-items-center"
                        style={{
                          padding: "5px",
                          backgroundColor:
                            currentFunctionId === functionItem.id
                              ? "#D9F6E4"
                              : "transparent",
                        }}
                      >
                        <img
                          src={Arrow}
                          width={"24px"}
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            transition: "transform 0.3s ease",
                            transform: functionItem.isCollapsed
                              ? "rotate(180deg)"
                              : "rotate(0)",
                          }}
                          onClick={() => handleCollapseToggle(functionIndex)}
                        />
                        <button
                          style={{
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            padding: "2px 8px 4px 8px",
                            marginLeft: "8px",
                            fontSize: "var(--sub-heading-font-size)",
                          }}
                          className={`color${(functionIndex % 3) + 1}`}
                          type="button"
                          onClick={() => handleCollapseToggle(functionIndex)}
                        >
                          {functionItem.name}
                        </button>

                        <div style={{ marginLeft: "10px" }}>
                          {/* <button
                            type="button"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <img src={ProfileAdd} width={20} />
                          </button> */}
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              handleAssignOwnerClick(e, functionItem.id)
                            }
                            disabled={
                              selectedSubMenuPermission === "write" ||
                              selectedSubMenuPermission === "admin"
                                ? false
                                : true
                            }
                          >
                            {functionItem.assigned_to &&
                            functionItem.assigned_to.length > 0 &&
                            functionItem.assigned_to.find(
                              (j) => j.is_active
                            ) ? (
                              <img
                                alt="owner"
                                src={createImageFromInitials(
                                  30,
                                  functionItem.assigned_to.find(
                                    (j) => j.is_active
                                  ).label,
                                  getRandomColor(
                                    functionItem.assigned_to.find(
                                      (j) => j.is_active
                                    ).label
                                  )
                                )}
                              />
                            ) : (
                              <img alt="" src={ProfileAdd} />
                            )}
                          </button>
                        </div>
                        {AssingOwnerClick && (
                          <div
                            style={{
                              width: "50%",
                            }}
                            className={"sidenav"}
                          >
                            <div
                              className="gtm-sidebar-header"
                              style={{
                                borderBottom: "1px solid lightgrey",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "var(--sub-heading-font-size)",
                                  fontWeight: "var(--heading-font-weight)",
                                }}
                              >
                                Assign Owner for Function
                              </div>
                              <a
                                href="#"
                                className="closebtn"
                                onClick={() => {
                                  handleOwnerCancliClick();
                                }}
                              >
                                &times;
                              </a>
                            </div>
                            <div className="gtm-sidebar-body">
                              <div className="row">
                                <div
                                  className="col-6"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    zIndex: 2000,
                                  }}
                                >
                                  <h6 style={{ marginRight: "10px" }}>Owner</h6>
                                  <Select
                                    name="emp_id"
                                    styles={customStyles}
                                    className="form-control selectr"
                                    placeholder={
                                      <span
                                        style={{
                                          fontSize: "var(--text-font-size)",
                                        }}
                                      >
                                        Assign owner for function
                                      </span>
                                    }
                                    value={selectedOption}
                                    options={empData}
                                    onChange={handleChange}
                                    isSearchable={true}
                                    filterOption={customFilter}
                                    getOptionLabel={(e) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img alt="" src={e.icon} />
                                        <span
                                          style={{
                                            marginLeft: 5,
                                            fontSize: "var(--text-font-size)",
                                          }}
                                        >
                                          {e.label}
                                        </span>
                                      </div>
                                    )}
                                    components={{
                                      DropdownIndicator: () => null,
                                      IndicatorSeparator: () => null,
                                      // Option: EmpIconOption,
                                    }}
                                  />
                                </div>
                                <div>
                                  <Button
                                    className="save-button-style-rhpo"
                                    onClick={(e) => handleOwnerSubmit(e)}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {(selectedSubMenuPermission === "write" ||
                          selectedSubMenuPermission === "admin") && (
                          <div
                            className="ElipsisVerticalIcon"
                            onClick={() => {
                              handleVerticalIconClick();
                            }}
                            onMouseLeave={handleVerticalIconMouseLeave}
                          >
                            <img
                              src={ElipsisVerticalIcon}
                              width={"4px"}
                              alt="Ellipsis Vertical Icon"
                            />
                            {onElipsisshowIcons && (
                              <div className="IconsContainer">
                                <div className="ElipsisEditIcon">
                                  <button
                                    type="button"
                                    className="Addobjstyle"
                                    style={{
                                      display: "flex",
                                    }}
                                    onClick={() =>
                                      handleEditClick(
                                        "function",
                                        functionItem.name,
                                        functionItem.id
                                      )
                                    }
                                  >
                                    <img
                                      src={EditIcon}
                                      width={"18px"}
                                      alt="Edit Icon"
                                    />
                                    <span style={{ paddingLeft: "10px" }}>
                                      Edit
                                    </span>
                                  </button>
                                </div>
                                {/* {selectedSubMenuPermission === "admin" && (
                                  <div
                                    className="ElipsisDeleteIcon"
                                    onClick={() =>
                                      handleDeleteFunction(functionItem.id)
                                    }
                                  >
                                    <img
                                      style={{ opacity: ".7" }}
                                      src={DeleteIcon}
                                      width={"24px"}
                                      alt="Delete Icon"
                                    />
                                    <span style={{ paddingLeft: "10px" }}>
                                      Delete
                                    </span>
                                  </div>
                                )} */}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      className={`collapse ${
                        functionItem.isCollapsed ? "show" : ""
                      }`}
                    >
                      {/* Your content for the collapse drawer */}
                      {/* Replace this with your desired content for each input */}
                      {/* strategic imparative data here input*/}
                      <div style={{ marginTop: "10px" }}>
                        {functionItem.strategic_imperative &&
                          functionItem.strategic_imperative.length > 0 &&
                          functionItem.strategic_imperative.map(
                            (simpItem, simpIndex) => (
                              <>
                                <div
                                  key={simpIndex}
                                  style={{ marginBottom: "10px" }}
                                >
                                  {editMode && simpItem.id === currentItemId ? (
                                    <div style={{ width: "80%" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "space-evenly",
                                          alignItems: "center",
                                          marginLeft: "40px",
                                          marginBottom: "6px",
                                        }}
                                      >
                                        <InputField
                                          value={newStrategicItem}
                                          onChange={(e) =>
                                            setNewStrategicItem(e.target.value)
                                          }
                                          handleSubmit={(e) =>
                                            handleAddStrategicItem(
                                              e,
                                              functionIndex,
                                              functionItem.id,
                                              simpItem.id,
                                              simpIndex
                                            )
                                          }
                                          Close={() => setEditMode(false)}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="strategic">
                                      <div
                                        className="simpContainer"
                                        key={simpIndex}
                                        style={{
                                          backgroundColor:
                                            currentImperativeId === simpItem.id
                                              ? "#D9F6E4"
                                              : "transparent",
                                        }}
                                      >
                                        <div>
                                          <img
                                            src={ArrowSIMP}
                                            width={"24px"}
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              transition: "transform 0.3s ease",
                                              transform: collapsedItems[
                                                simpItem.id
                                              ]
                                                ? "rotate(0)"
                                                : "rotate(180deg)",
                                            }}
                                            onClick={() =>
                                              handleCollapseToggleSimp(
                                                simpItem.id
                                              )
                                            }
                                          />
                                          <span style={{ paddingLeft: "5px" }}>
                                            <button
                                              style={{
                                                border: "none",
                                                backgroundColor: "transparent",
                                                fontSize:
                                                  "var(--sub-heading-font-size)",
                                              }}
                                              type="button"
                                              onClick={() =>
                                                handleCollapseToggleSimp(
                                                  simpItem.id
                                                )
                                              }
                                            >
                                              {simpItem.name}
                                            </button>
                                          </span>
                                        </div>
                                        {/* add more options here for strategic imperative */}

                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div style={{ margin: "0px 10px" }}>
                                            {simpItem.assigned_to.length > 0 &&
                                            simpItem.assigned_to.find(
                                              (item) => item.is_active
                                            ) ? (
                                              <img
                                                style={{ margin: "10px" }}
                                                alt=""
                                                src={createImageFromInitials(
                                                  30,
                                                  simpItem.assigned_to.find(
                                                    (item) => item.is_active
                                                  ).label,
                                                  getRandomColor(
                                                    simpItem.assigned_to.find(
                                                      (item) => item.is_active
                                                    ).label
                                                  )
                                                )}
                                              />
                                            ) : (
                                              <img
                                                alt=""
                                                src={Assign}
                                                style={{ margin: "10px" }}
                                              />
                                            )}
                                          </div>
                                          <DisabledGoalSelector
                                            options={simpItem.goals_dt}
                                            selectedGoals={simpItem.goals_dt?.filter(
                                              (goal) => goal.is_active
                                            )}
                                            // simpItem_id={simpItem.id}
                                          />
                                          <img
                                            alt="chevronright"
                                            src={ChevronRight}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              handleSidebar(
                                                true,
                                                simpItem,
                                                "Write a functional goal name",
                                                "functional_goal",
                                                simpItem.id,
                                                functionIndex,
                                                simpIndex,
                                                "XL Strategy Plan" +
                                                  " > " +
                                                  functionItem.name,
                                                // " > " +
                                                // simpItem.name,
                                                false
                                              );
                                              setCurrentImperativeId(
                                                simpItem.id
                                              );
                                              setCurrentMilestoneId("");
                                              setCurrentFunctionId("");
                                              // setHighlightedRowIndex(sim.id);
                                            }}
                                          />
                                          {/* 
                                          <div
                                            className="ElipsisVerticalIcon"
                                            onClick={() => {
                                              handleVerticalIconClick();
                                            }}
                                            onMouseLeave={
                                              handleVerticalIconMouseLeave
                                            }
                                          >
                                            <img
                                              src={ElipsisVerticalIcon}
                                              width={"4px"}
                                              alt="Ellipsis Vertical Icon"
                                            />
                                            {onElipsisshowIcons && (
                                              <div className="IconsContainers">
                                                <div className="ElipsisEditIcon">
                                                  <button
                                                    type="button"
                                                    className="Addobjstyle"
                                                    onClick={() =>
                                                      handleEditClick(
                                                        "strategicItem",
                                                        simpItem.name,
                                                        simpItem.id
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={EditIcon}
                                                      width={"18px"}
                                                      alt="Edit Icon"
                                                    />
                                                    <span
                                                      style={{
                                                        paddingLeft: "10px",
                                                      }}
                                                    >
                                                      Edit
                                                    </span>
                                                  </button>
                                                </div>

                                                <div
                                                  className="ElipsisDeleteIcon"
                                                  onClick={() =>
                                                    handleStrategicImpDelete(
                                                      functionIndex,
                                                      simpIndex,
                                                      simpItem.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    style={{
                                                      opacity: ".7",
                                                    }}
                                                    src={DeleteIcon}
                                                    width={"24px"}
                                                    alt="Delete Icon"
                                                  />
                                                  <span
                                                    style={{
                                                      paddingLeft: "10px",
                                                    }}
                                                  >
                                                    Delete
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {!collapsedItems[simpItem.id] && (
                                    <div>
                                      <div
                                        style={{
                                          marginTop: "10px",
                                          marginLeft: "40px",
                                        }}
                                      >
                                        {simpItem.milestones &&
                                          simpItem.milestones.length > 0 &&
                                          simpItem.milestones.map(
                                            (item, index) => (
                                              <div
                                                key={item.id}
                                                style={{ marginBottom: "2px" }}
                                              >
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    alignItems: "center",
                                                  }}
                                                  onMouseEnter={() =>
                                                    setShowIcons(item.id)
                                                  }
                                                  onMouseLeave={() =>
                                                    setShowIcons(null)
                                                  }
                                                >
                                                  <div
                                                    className="Milestones"
                                                    style={{
                                                      background:
                                                        currentMilestoneId ===
                                                        item.id
                                                          ? "#D9F6E4"
                                                          : "linear-gradient(0deg, #eeeeee, #eeeeee),linear-gradient(0deg, #f8f8f8, #f8f8f8)",
                                                    }}
                                                  >
                                                    {/* edit option here for milestones */}
                                                    {/* {editMode &&
                                                    item.id ===
                                                      currentItemId ? (
                                                      <div
                                                        style={{
                                                          width: "65%",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            justifyContent:
                                                              "space-evenly",
                                                            alignItems:
                                                              "center",
                                                            marginLeft: "40px",
                                                            // marginBottom: "6px",
                                                          }}
                                                        >
                                                          <InputField
                                                            value={
                                                              newMilestoneItem
                                                            }
                                                            onChange={(e) =>
                                                              setNewMilestoneItem(
                                                                e.target.value
                                                              )
                                                            }
                                                            handleSubmit={(e) =>
                                                              handleAddMilestoneItem(
                                                                e,
                                                                functionIndex,
                                                                simpIndex,
                                                                index,
                                                                simpItem.id,
                                                                item.id
                                                              )
                                                            }
                                                            Close={() =>
                                                              setEditMode(false)
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : ( */}
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "space-between",
                                                        // overflow: "hidden",
                                                        width: "100%",
                                                        // paddingTop: "10px",
                                                        // background:
                                                        //   currentMilestoneId ===
                                                        //   item.id
                                                        //     ? "#D9F6E4"
                                                        //     : "linear-gradient(0deg, #eeeeee, #eeeeee),linear-gradient(0deg, #f8f8f8, #f8f8f8)",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          width: "65%",
                                                          alignItems: "center",
                                                          display: "flex",
                                                        }}
                                                        onClick={() => {
                                                          handleSidebar(
                                                            true,
                                                            item,
                                                            "Write a milestone name",
                                                            "milestone",
                                                            simpItem.id,
                                                            functionIndex,
                                                            simpIndex,

                                                            functionItem.name +
                                                              " > " +
                                                              simpItem.name,
                                                            index
                                                          );
                                                          setCurrentMilestoneId(
                                                            item.id
                                                          );
                                                          setCurrentImperativeId(
                                                            ""
                                                          );
                                                          setCurrentFunctionId(
                                                            ""
                                                          );
                                                          // setHighlightedRowIndex(sim.id);
                                                        }}
                                                      >
                                                        <img
                                                          src={Repeat}
                                                          width={"24px"}
                                                        />
                                                        <span
                                                          style={{
                                                            overflow: "hidden",
                                                            width: "100%",
                                                            textOverflow:
                                                              "ellipsis",
                                                            whiteSpace:
                                                              "nowrap",
                                                            border: "none",
                                                            backgroundColor:
                                                              "transparent",
                                                            fontSize:
                                                              "var(--sub-heading-font-size)",
                                                          }}
                                                          type="button"
                                                        >
                                                          {item.name}
                                                        </span>
                                                      </div>

                                                      {/* edit remove component */}
                                                      {/* <ManageOptions
                                                          handleEditClick={() =>
                                                            handleEditClick(
                                                              "milestone",
                                                              item.name,
                                                              item.id
                                                            )
                                                          }
                                                          handleDeleteClick={() =>
                                                            handleMilestoneDelete(
                                                              functionIndex,
                                                              simpIndex,
                                                              index,
                                                              item.id
                                                            )
                                                          }
                                                          showIcons={
                                                            showIcons ===
                                                            item.id
                                                          }
                                                        /> */}

                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <>
                                                          <div
                                                            style={{
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            {item.exp_end_date ? (
                                                              <div
                                                                style={{
                                                                  marginBottom:
                                                                    "0px",
                                                                }}
                                                              >
                                                                {
                                                                  item.exp_end_date
                                                                }
                                                              </div>
                                                            ) : (
                                                              <div
                                                                style={{
                                                                  color: "gray",
                                                                  marginBottom:
                                                                    "0px",
                                                                  marginRight:
                                                                    "20px",
                                                                }}
                                                              >
                                                                Due Date
                                                              </div>
                                                            )}
                                                          </div>
                                                          <div
                                                            style={{
                                                              margin:
                                                                "0px 20px",
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            {item.assigned_to
                                                              .length > 0 &&
                                                            item.assigned_to.find(
                                                              (item) =>
                                                                item.is_active
                                                            ) ? (
                                                              <img
                                                                alt=""
                                                                src={createImageFromInitials(
                                                                  30,
                                                                  item.assigned_to.find(
                                                                    (item) =>
                                                                      item.is_active
                                                                  ).label,
                                                                  getRandomColor(
                                                                    item.assigned_to.find(
                                                                      (item) =>
                                                                        item.is_active
                                                                    ).label
                                                                  )
                                                                )}
                                                              />
                                                            ) : (
                                                              <img
                                                                alt=""
                                                                src={Assign}
                                                              />
                                                            )}
                                                          </div>
                                                          <div
                                                            style={{
                                                              width: "20px",
                                                              marginLeft:
                                                                "48px",
                                                            }}
                                                          >
                                                            {showIcons ===
                                                              item.id && (
                                                              <img
                                                                alt="chevronright"
                                                                src={
                                                                  ChevronRight
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  handleSidebar(
                                                                    true,
                                                                    item,
                                                                    "Write a milestone name",
                                                                    "milestone",
                                                                    simpItem.id,
                                                                    functionIndex,
                                                                    simpIndex,
                                                                    functionItem.name,
                                                                    index
                                                                  );
                                                                  setCurrentMilestoneId(
                                                                    item.id
                                                                  );
                                                                  setCurrentImperativeId(
                                                                    ""
                                                                  );
                                                                  setCurrentFunctionId(
                                                                    ""
                                                                  );
                                                                  // setHighlightedRowIndex(sim.id);
                                                                }}
                                                              />
                                                            )}
                                                          </div>
                                                        </>
                                                      </div>
                                                    </div>
                                                    {/* )} */}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                      <div
                                        style={{
                                          display: isMilestoneVisible
                                            ? "block"
                                            : "none",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        {(selectedSubMenuPermission ===
                                          "write" ||
                                          selectedSubMenuPermission ===
                                            "admin") && (
                                          <div
                                            style={{
                                              display: milestoneItems[
                                                functionIndex
                                              ]?.[simpIndex]
                                                ? "none"
                                                : "flex",
                                              width: "100%",
                                            }}
                                          >
                                            <button
                                              style={{
                                                marginLeft: "60px",
                                              }}
                                              className="buttonStyle"
                                              type="button"
                                              // onClick={() =>
                                              //   setMilestoneModel(
                                              //     functionIndex,
                                              //     simpIndex,
                                              //     false
                                              //   )
                                              // }
                                              onClick={() => {
                                                handleSidebar(
                                                  false,
                                                  false,
                                                  "Write a milestone name",
                                                  "milestone",
                                                  simpItem.id,
                                                  functionIndex,
                                                  simpIndex,
                                                  functionItem.name +
                                                    " > " +
                                                    simpItem.name +
                                                    " > New Milestone",
                                                  false
                                                );
                                                setCurrentImperativeId(
                                                  simpItem.id
                                                );
                                                setCurrentMilestoneId("");
                                                setCurrentFunctionId("");
                                              }}
                                            >
                                              <img
                                                src={AddIcon}
                                                width={16}
                                                height={16}
                                                style={{ marginRight: "5px" }}
                                              />
                                              Add Milestones
                                            </button>
                                          </div>
                                        )}
                                        {/* <div
                                          className="ObjectInput Milestones"
                                          style={{
                                            display: milestoneItems[
                                              functionIndex
                                            ]?.[simpIndex]
                                              ? "none"
                                              : "flex",
                                            marginLeft: "40px",
                                            width: "96.5%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "50%",
                                              display: "flex",
                                              justifyContent: "space-evenly",
                                              alignItems: "center",
                                              marginLeft: "15px",
                                            }}
                                          >
                                            <InputField
                                              onChange={(e) =>
                                                updateMilestoneValue(
                                                  functionIndex,
                                                  simpIndex,
                                                  e.target.value
                                                )
                                              }
                                              Close={() =>
                                                setMilestoneModel(
                                                  functionIndex,
                                                  simpIndex,
                                                  true
                                                )
                                              }
                                              value={
                                                milestoneValues[functionIndex][
                                                  simpIndex
                                                ]
                                              }
                                              handleSubmit={(e) =>
                                                handleAddMilestoneItem(
                                                  e,
                                                  functionIndex,
                                                  simpIndex,
                                                  false,
                                                  simpItem.id,
                                                  false
                                                )
                                              }
                                              placeholder="Add Milestone here"
                                              name="milestone"
                                            />
                                          </div>
                                          <div style={{ display: "none" }}>
                                            <AddExistingpopup
                                              Title="Add Existing Milestone"
                                              ModalTitle="My Milestones Library"
                                              handleClose={() =>
                                                handlePopupClose()
                                              }
                                              show={showMilestonePopup}
                                              AddFolder={AddFolder}
                                              handleShow={() =>
                                                handlePopupShow()
                                              }
                                            />
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )
                          )}
                      </div>
                      <div
                        style={{
                          display: issimpVisible ? "block" : "none",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {(selectedSubMenuPermission === "write" ||
                          selectedSubMenuPermission === "admin") && (
                          <div
                            style={{
                              display: strategicItems[functionIndex]
                                ? "none"
                                : "flex",
                              width: "100%",
                            }}
                          >
                            <button
                              className="buttonStyle"
                              style={{ marginLeft: "30px" }}
                              type="button"
                              // onClick={() => setSimpModel(functionIndex, false)}
                              onClick={() => {
                                handleSidebar(
                                  false,
                                  false,
                                  "Write a functional goal name",
                                  "functional_goal",
                                  functionItem.id,
                                  functionIndex,
                                  false,
                                  functionItem.name + " > New Functional Goal",
                                  false
                                );
                                setCurrentImperativeId("");
                                setCurrentMilestoneId("");
                                setCurrentFunctionId(functionItem.id);
                              }}
                            >
                              <img
                                src={AddIcon}
                                width={16}
                                height={16}
                                style={{ marginRight: "5px" }}
                              />
                              Add Functional Goal
                            </button>
                          </div>
                        )}
                        {/* <div
                          className="ObjectInput"
                          style={{
                            display: strategicItems[functionIndex]
                              ? "none"
                              : "none",
                            marginLeft: "40px",
                            width: "96%",
                            marginRight: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "40px",
                              width: "50%",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <InputField
                              onChange={(e) =>
                                updateStrategicItemValue(
                                  functionIndex,
                                  e.target.value
                                )
                              }
                              Close={() => setSimpModel(functionIndex, true)}
                              // value={newStrategicItem}
                              value={strategicItemValues[functionIndex]}
                              handleSubmit={(e) =>
                                handleAddStrategicItem(
                                  e,
                                  functionIndex,
                                  functionItem.id
                                )
                              }
                              placeholder="Add Functional Goal here"
                              name="strategic"
                            />
                          </div>
                          <div style={{ display: "none" }}>
                            <AddExistingpopup
                              Title="Add Existing Functional Goal"
                              ModalTitle="My Functional Goal Library"
                              handleClose={() => handlePopupClose()}
                              show={showSimpPopup}
                              AddFolder={AddFolder}
                              handleShow={() => handlePopupShow()}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                // display: isFunctionVisible ? "block" : "none",
                display: "none",
                alignItems: "center",
                width: "99%",
              }}
            >
              <div
                style={{
                  display: !functionModel ? "flex" : "none",
                  width: "99%",
                }}
              >
                <button
                  className="buttonStyle"
                  type="button"
                  onClick={openFunctionModel}
                >
                  <span className="add-icon">+</span>Add Function
                </button>
              </div>
              <div
                className="ObjectInput"
                style={{ display: functionModel ? "flex" : "none" }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <InputField
                    onChange={(e) => setFunctionData(e.target.value)}
                    Close={(e) => setFunctionModel(false)}
                    handleSubmit={handleFunctionSubmit}
                    placeholder="Add Function here"
                    name="function"
                    handleKeyDown={handleKeyDownFunction}
                  />
                </div>
                {/* add existing popup here */}
                <div>
                  <AddExistingpopup
                    Title="Add Existing function"
                    ModalTitle="My Function Library"
                    handleClose={() => handlePopupClose()}
                    show={showFunctionPopup}
                    AddFolder={AddFolder}
                    handleShow={() => handlePopupShow()}
                    stage={"functions"}
                  />
                </div>
              </div>
            </div>
            {isSidebarOpen && (
              <XLExecutionPlanSidebar
                isEdit={isEdit}
                data={selectedData}
                type={sidebarType}
                placeholder={placeholder}
                goalData={dropdownGoalData}
                empData={empData}
                parentId={selectedItemId}
                statusData={statusData}
                priorityData={priorityData}
                breadcrumbs={breadcrumbs}
                functionIndex={currentFunctionIndex}
                impIndex={currentImperativeIndex}
                impid={currentImperativeId}
                milestoneIndex={currentMilestoneIndex}
                milestonedataId={currentMilestoneId}
                handleMilestoneEdit={handleMilestoneEdit}
                handleStrategicImpEdit={handleStrategicImpEdit}
                handleStrategicImpDelete={handleStrategicImpDelete}
                handleMilestoneDelete={handleMilestoneDelete}
                onClose={() => {
                  setIsSidebarOpen(false);
                  setCurrentFunctionId("");
                  setCurrentImperativeId("");
                  setCurrentMilestoneId("");
                }}
                setIsSidebarOpen={(value) => {
                  setIsSidebarOpen(value);
                }}
              />
            )}
          </>
        </form>
        <div>
          <CommentSection />
          {selectedHelpInfoSidebar && <SideNavHelpInfo />}
        </div>
      </div>
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          // showTemplate={true}
          showSelector={true}
          showButtons={true}
          handleNext={handleClick}
          handleBackButton={handleBack}
          showProducts={true}
          breadcrumbParent={"GTM Execution"}
          breadcrumbChild={"XL Execution Plan"}
          documentName={"XL Execution Plan"}
          helpKey={keys["XL Execution Plan"].Name}
          image={"question"}
        />
      </>
    );
  }
}

export default XLExecutionPlan;
