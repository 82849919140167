import "./onboarding/vcmapping/Mapping.css";

const VCTabs = (props) => {
  return (
    <>
      <div
        className="topnav"
        style={{
          display: "flex",
          marginBottom: 10,
          width: "100%",
          overflow: "auto",
        }}
      >
        {props.menus &&
          props.menus
            .filter((menu) => menu.isactive === true)
            .map((menu) => (
              <button
                style={{ fontSize: "var(--sub-heading-font-size)" }}
                key={menu.key}
                onClick={() => props.onMenuChanged(menu, props.vcKey)}
                className={
                  props.selectedMenu.key === menu.key ? "menus active" : "menus"
                }
              >
                {menu.value}
              </button>
            ))}
      </div>
    </>
  );
};

export default VCTabs;
