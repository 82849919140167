import React, { useEffect, useState } from "react";
import OptionsTypes from "../Options";
import Sections from "../Sections";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../store/actions";
import AddIcon from "../../../assets/images/add.svg";

export default function Questions({
  categoryId,
  sectionId,
  questions,
  questionIndex = "",
}) {
  const dispatch = useDispatch();
  const selectedPermission = useSelector((state) => state.selectedPermission);

  const allQuestionsList = useSelector((state) => state.allQuestionsList);
  const [activeIndex, setActiveIndex] = useState(0);
  // console.log(questions)
  const _handleAddNewOptions = (activeIndex = 0) => {
    setActiveIndex(activeIndex);
    let tempData = allQuestionsList.map((el) => {
      if (el.key === categoryId) {
        // _checkSections(el.sections);
        if (el.hasOwnProperty("sections") && el.sections?.length > 0) {
          _checkSections(el.sections);
        }
        if (el.hasOwnProperty("questions") && el.questions?.length > 0) {
          _checkQuestions(el.questions);
        }
      }
      return el;
    });
    dispatch(Actions.setAllQuestions(tempData));
  };

  const _checkSections = (sections) => {
    sections.map((sec) => {
      _checkQuestions(sec.questions);
    });
  };

  const _checkQuestions = (questionsOpt) => {
    questionsOpt.map((question) => {
      // if (question.hasOwnProperty("sections")) {
      //   try {
      //     _checkSections(question.sections);
      //   } catch (e) {
      //     //
      //   }
      // } else {
      //   _checkOptionsActiveStatus(question);
      // }
      // Add options not working properly so commented the above code.
      _checkOptionsActiveStatus(question);
      return question;
    });
  };

  const generateRandomKey = () => {
    return Math.random().toString(36).substring(2, 15);
  };

  const _generateDynamicKeys = (defaultValues, keyValue) => {
    function _getUpdatedValuesOptions(data, key) {
      data.map((el, index) => {
        el.key = `opt_${key}_${index}`;
        if (el.hasOwnProperty("children") && el.children?.length > 0) {
          _updateChildrenKeys(el.children, `${key}_${index}`);
        }
        if (el.hasOwnProperty("options") && el.options?.length > 0) {
          _updateChildrenOptionsKeys(el.options, `${key}_${index}`);
        }
        return el;
      });
      return data;
    }

    function _updateChildrenKeys(data, key) {
      data.map((el, index) => {
        el.key = `opt_child_${key}_${index}`;
        _getUpdatedValuesOptions(el.options, `${key}_${index}`);
        return el;
      });
    }

    function _updateChildrenOptionsKeys(data, key) {
      data.map((el, index) => {
        el.key = `opt_child_opt_${key}_${index}`;
        if (el.hasOwnProperty("values") && el.values?.length > 0)
          _updateChildrenOptionsValuesKeys(el.values, `${key}_${index}`);
        if (el.hasOwnProperty("children") && el.children?.length > 0)
          _updateChildrenKeys(el.children, `${key}_${index}`);

        return el;
      });
    }

    function _updateChildrenOptionsValuesKeys(data, key) {
      data.map((el, index) => {
        el.key = `opt_child_opt_values_${key}_${index}`;
        _getUpdatedValuesOptions(el.options, `${key}_${index}`);
        return el;
      });
    }
    // Generate dynamic keys for the JSON structure
    const newData = _getUpdatedValuesOptions(defaultValues, keyValue);

    return newData;
  };

  const _checkOptionsActiveStatus = (question) => {
    try {
      let findSelectedQuestion = true;
      let activeQuestion = questions[activeIndex];

      question.options.map((opt) => {
        if (question.key === activeQuestion.key) {
          findSelectedQuestion = false;
        } else if (findSelectedQuestion) {
          _checkQuestions(opt.children);
        }
        return opt;
      });

      if (!findSelectedQuestion) {
        let keyValue = `${generateRandomKey()}_${question.options.length + 1}`;
        let defaultValues = JSON.stringify(question.options[0].values);
        if (defaultValues) {
          question.options.push({
            key: `options_${keyValue}`,
            title: "",
            selected: false,
            type: "list",
            values: _generateDynamicKeys(
              JSON.parse(defaultValues),
              `options_${keyValue}`
            ),
            children: [],
          });
        } else {
          question.options.push({
            key: `options_${keyValue}`,
            title: "",
            selected: false,
            type: "list",
            values: [],
            children: [],
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {questions.map((node, index) => (
        <Children
          index={index}
          data={node}
          key={node.key}
          categoryId={categoryId}
          sectionId={sectionId}
          questionId={node.key}
          verticalId={node.vertical_id}
          isMaster={node.is_master}
          questionIndex={questionIndex}
          _handleAddNewOptions={_handleAddNewOptions}
          selectedPermission={selectedPermission}
        />
      ))}
    </div>
  );
}

const Children = ({
  index,
  data,
  categoryId,
  sectionId,
  questionId,
  verticalId,
  isMaster,
  questionIndex,
  _handleAddNewOptions,
  selectedPermission,
}) => {
  const { options = [], label = "", sections = [] } = data;
  const [defaultIndex, setDefaultIndex] = useState(questionIndex);
  const [display, setDisplay] = useState("flex");

  useEffect(() => {
    let indexValue =
      questionIndex !== "" ? `${defaultIndex}.${index + 1}` : index + 1;
    setDisplay(
      options.filter((el) => el.type === "list").length > 0 ? "" : "flex"
    );
    setDefaultIndex(indexValue);
  }, []);
  return (
    <div
      style={{ marginBottom: "10px", fontSize: "var(--sub-heading-font-size)" }}
    >
      {label !== "" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="fun-question">
            <span>{` ${label}`}</span>
          </div>
          <div>
            {options.filter((el) => el.type === "list").length > 0 &&
              (selectedPermission === "write" ||
                selectedPermission === "admin") && (
                <div className="user-input" style={{ width: "98.4%" }}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        cursor: "pointer",
                        marginRight: 5,
                        height: 20,
                        width: 20,
                      }}
                      src={AddIcon}
                      alt={"add"}
                      onClick={() => {
                        _handleAddNewOptions(index);
                      }}
                    />
                    Add Options
                  </span>
                </div>
              )}
          </div>
        </div>
      )}

      {/* <div
        className="fun-question"
        style={{ width: "98.4%" }}
      >{`${defaultIndex}. ${label}`}</div> */}

      {options.length > 0 && (
        <OptionsTypes
          attachments={data?.attachments}
          optionData={options}
          categoryId={categoryId}
          sectionId={sectionId}
          questionId={questionId}
          verticalId={verticalId}
          isMaster={isMaster}
          questionIndex={defaultIndex}
          display={display}
          selectedPermission={selectedPermission}
        />
      )}
      {sections.length > 0 && (
        <Sections categoryId={categoryId} sections={sections} />
      )}
      {/* {options.length === 0 && sections.length === 0 && (
        <p style={{ color: "#b5b5b5" }}>
          {"This is the last question for this section..."}
        </p>
      )} */}
    </div>
  );
};
