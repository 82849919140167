import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../MainComponent";
import "./ExecutionReadiness.css";
import { url } from "../../store/sagas/API.js";
import Image from "../../assets/images/Modalicon.svg";
import { getRandomColor, createImageFromInitials } from "../Utils";
import { Modal, Button, ModalFooter } from "react-bootstrap";
import UserProfile from "../../assets/images/user-profile.svg";
import DefaultFunction from "../../assets/images/f1.svg";
import Select from "react-select";
import Alert from "../Alert.js";
import {
  getEmployeeData,
  getStrategyReadinessDetails,
  getStrategyReadinessExistedFunctionsDetails,
  postNewStrategyReadinessFunctions,
  postStrategyReadinessDetails,
} from "../../store/actions";
import { resetExceptionError } from "../../store/actions/index.js";
import { useNavigate } from "react-router-dom";
import ProgressBar from "react-customizable-progressbar";
import HelpInfo from "../../utils/helpInfoProviderComponent.js";
import keys from "../../utils/helpInfoKeys.json";
import CommentSection from "../../utils/CommentSection.js";
import Cancel from "../../assets/images/Cancel.svg";
import Login from "../Login.js";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo.js";

function StrategyReadiness() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [currentDocURL, setCurrentDocURL] = useState("");
  const [itemId, setItemIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddFunctionsOpen, setIsAddFunctionsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [emp_data, setEmpData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [addExisting, setAddExisting] = useState(false);
  const [functionData, setFunctionData] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showRemoveIcon, setShowRemoveIcon] = useState(null);
  const [ClickOnEdit, setClickOnEdit] = useState(false);
  const [deleteFunction, setDeleteFunction] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [progress, setProgress] = useState("");
  const selectedPermission = useSelector((state) => state.selectedPermission);
  useState(false);

  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);

  const fetchPostNewStrategyReadinessFunctionData = useSelector(
    (state) => state.fetchPostNewStrategyReadinessFunctionData
  );
  const ISError = useSelector((state) => state.ISError);

  const fetchStrategyReadinessExistedFunctionData = useSelector(
    (state) => state.fetchStrategyReadinessExistedFunctionData
  );
  const fetchStrategyReadinessFunctionData = useSelector(
    (state) => state.fetchStrategyReadinessFunctionData
  );
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const [functionList, setFunctionList] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    assigned_to: null,
  });

  useEffect(() => {
    if (fetchStrategyReadinessFunctionData) {
      setFunctionList(fetchStrategyReadinessFunctionData.readiness_functions);
      setProgress(fetchStrategyReadinessFunctionData.score);
    }
  }, [fetchStrategyReadinessFunctionData]);
  useEffect(() => {
    dispatch(
      getStrategyReadinessDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);

  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);

  useEffect(() => {
    if (
      fetchStrategyReadinessExistedFunctionData &&
      fetchStrategyReadinessExistedFunctionData.old_fun
    ) {
      setFunctionData(fetchStrategyReadinessExistedFunctionData.old_fun);
    }
  }, [fetchStrategyReadinessExistedFunctionData]);

  useEffect(() => {
    if (
      getEmployeeDetails &&
      getEmployeeDetails.emp_data &&
      getEmployeeDetails.emp_data.length > 0
    ) {
      setEmpData(
        getEmployeeDetails.emp_data.map((obj) => {
          return {
            value: obj.id,
            label: obj.prop_name,
            icon: createImageFromInitials(
              30,
              obj.prop_name,
              getRandomColor(obj.prop_name)
            ),
          };
        })
      );
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    if (
      fetchPostNewStrategyReadinessFunctionData &&
      fetchPostNewStrategyReadinessFunctionData.response &&
      fetchPostNewStrategyReadinessFunctionData.action === "add"
    ) {
      if (fetchPostNewStrategyReadinessFunctionData.stage_type === "function") {
        fetchPostNewStrategyReadinessFunctionData.response.map((item) => {
          const newFunction = {
            name: item.value,
            id: item.key,
            assigned_to: item.assigned_to,
            icon: "/files/f4.svg",
            children: [],
            is_active: true,
          };
          setFunctionList([...functionList, newFunction]);
        });
      }
    }
  }, [fetchPostNewStrategyReadinessFunctionData]);

  const openModal = () => {
    setIsOpen(true);
    dispatch(getEmployeeData());
    dispatch(
      getStrategyReadinessExistedFunctionsDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        false
      )
    );
  };
  const openAlert = () => setIsShow(true);
  const closeModal = () => {
    setIsOpen(false);
    setAddExisting(false);
  };
  const closeAddModal = () => {
    setIsAddFunctionsOpen(false);
    setAddExisting(false);
  };

  const handleInputChange = (field, value, e) => {
    e.stopPropagation();
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleBack = () => {
    navigate("/dashboard", { replace: true });
  };
  const handleClick = () => {
    setNextButtonClicked(true);
    navigate("/executionreadiness", { replace: true });
  };
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    img: {
      height: 40,
    },
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      assigned_to: e.value,
    });
    setSelectedOption(e);
  };

  const handleSubmit = (event) => {
    if (addExisting) {
      const selectedFunctionsData = selectedLabels.map((label) => ({
        bi_id: localStorage.getItem("bi_id"),
        product_id: localStorage.getItem("product_id"),
        name: label.name,
        id: label.id,
        is_active: true,
      }));
      const selectedFunctionData = selectedLabels.map((label) => ({
        name: label.name,
        id: label.id,
        is_active: true,
        is_master: label.is_master,
      }));
      setFunctionList((prevFunctionList) => [
        ...prevFunctionList,
        ...selectedFunctionData,
      ]);
      dispatch(
        postNewStrategyReadinessFunctions(selectedFunctionsData, "function")
      );
      closeModal();
    } else {
      if (formData.name !== "") {
        const assignedToList = [formData.assigned_to];
        const requiredAssignedData = emp_data.map((item) => ({
          key: item.value,
          is_active: assignedToList.includes(item.value),
        }));
        let data = [
          {
            bi_id: localStorage.getItem("bi_id"),
            product_id: localStorage.getItem("product_id"),
            name: formData.name,
            assigned_to: requiredAssignedData,
          },
        ];
        dispatch(postNewStrategyReadinessFunctions(data, "function"));

        closeModal();
      } else {
        openAlert();
      }
    }
    setAddExisting(false);
  };

  const handleCheckboxChange = (optionValue) => {
    const newCheckboxStates = {
      ...checkboxStates,
      [optionValue]: !checkboxStates[optionValue],
    };
    setCheckboxStates(newCheckboxStates);
    const selectedLabel =
      fetchStrategyReadinessExistedFunctionData.old_fun.find(
        (label) => label.id === optionValue
      );
    if (newCheckboxStates[optionValue]) {
      setSelectedLabels([...selectedLabels, selectedLabel]);
    } else {
      setSelectedLabels(
        selectedLabels.filter((label) => label.id !== optionValue)
      );
    }

    const hasCheckedCheckbox = Object.values(newCheckboxStates).some(
      (isChecked) => isChecked
    );
    setIsButtonDisabled(!hasCheckedCheckbox);
  };

  const getColorClass = (progress) => {
    if (progress <= 33) {
      return "red";
    } else if (progress <= 66) {
      return "orange";
    } else {
      return "green";
    }
  };
  const colorClass = getColorClass(progress);
  const handleDeleteFunction = (e, itemId) => {
    e.stopPropagation();
    setDeleteFunction(true);
    const latestList =
      functionList &&
      functionList.map((item) =>
        item.id === itemId ? { ...item, is_active: false } : item
      );
    setFunctionList(latestList);
  };
  const handleEditClick = () => {
    setClickOnEdit(true);
  };
  const handleSaveClick = () => {
    setClickOnEdit(false);
    const data = {
      data: [...functionList],
      bi_id: localStorage.getItem("bi_id"),
      product_id: localStorage.getItem("product_id"),
    };
    dispatch(postStrategyReadinessDetails(data));
  };
  const handleFunctionCardClick = (function_id) => {
    localStorage.setItem("function_id", function_id);
    navigate("/strategyreadinessfunction");
  };

  const content = (
    <>
      <div className="Container">
        <div
          style={{
            display: "flex",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              {ClickOnEdit ? (
                <div
                  style={{
                    fontSize: "var(--main-heading)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Strategy Readiness Selection
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "var(--main-heading)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Strategy Readiness
                </div>
              )}
              {/* <p
                style={{
                  fontSize: "var(--sub-heading-font-size)",
                  color: "#646464",
                }}
              >
                Progress: 00%
              </p> */}
              <br />
            </div>
          </div>
          <div style={{ position: "absolute", right: '5%' }}>
            <ProgressBar
              radius={70}
              children={120}
              progress={progress}
              steps={100}
              strokeColor={colorClass}
              pointerStrokeColor={colorClass}
              cut={180}
              rotate={180}
              strokeWidth={8}
              trackStrokeWidth={8}
              pointerRadius={8}
              pointerStrokeWidth={5}
            >
              <div className="indicator">
                <div className="indicator-number">{progress}</div>
                <div className="indicator-text">XL ERM Score:</div>
              </div>
            </ProgressBar>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className="exe-readiness-card-list">
            {functionList &&
              functionList.length > 0 &&
              functionList.map((functionItem) => (
                <>
                  {functionItem.is_active && (
                    <div
                      className="card exe-readiness-card"
                      onMouseEnter={() => setShowRemoveIcon(functionItem.id)}
                      onMouseLeave={() => setShowRemoveIcon(null)}
                      onClick={() =>
                        !ClickOnEdit && handleFunctionCardClick(functionItem.id)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          <img
                            alt="icon"
                            src={url + functionItem.icon}
                            style={{ paddingBottom: "5px", width: "30px" }}
                          />
                        </div>
                        <div style={{ display: "flex" }}>
                          {functionItem.fun_score && (
                            <div
                              style={{
                                height: "30px",
                                width: "30px",
                                marginLeft: "5px",
                                textAlign: "center",
                                color: "white",
                                paddingTop: "2px",
                                backgroundColor: getColorClass(
                                  functionItem.fun_score[0]
                                ),
                                borderRadius: "30px",
                              }}
                            >
                              {functionItem.fun_score &&
                                functionItem.fun_score[0]}
                            </div>
                          )}
                          {/* <div>
                            {functionItem.assigned_to &&
                            functionItem.assigned_to.length > 0 &&
                            functionItem.assigned_to.find(
                              (j) => j.is_active
                            ) ? (
                              <img
                                alt="owner"
                                src={createImageFromInitials(
                                  30,
                                  functionItem.assigned_to.find(
                                    (j) => j.is_active
                                  ).label,
                                  getRandomColor(
                                    functionItem.assigned_to.find(
                                      (j) => j.is_active
                                    ).label
                                  )
                                )}
                              />
                            ) : (
                              <img alt="" src={UserProfile} />
                            )}
                          </div> */}
                        </div>
                      </div>
                      <div
                        key={functionItem.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h6 className="exe-readiness-card-title">
                          <div>{functionItem.name}</div>
                        </h6>
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          key={functionItem.id}
                        >
                          {showRemoveIcon === functionItem.id &&
                            ClickOnEdit && (
                              <button
                                style={{
                                  marginLeft: 5,
                                  background: "white",
                                  height: 17,
                                  width: 17,
                                  borderRadius: 17,
                                  border: "thin ridge",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                type="button"
                                onClick={(e) =>
                                  handleDeleteFunction(e, functionItem.id)
                                }
                              >
                                {/* <span
                                  style={{ fontSize: 12, paddingBottom: 2 }}
                                >
                                  x
                                </span> */}
                                <img src={Cancel} width={20} height={20} />
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="exe-readiness-progress-card">
                        <div
                          style={{
                            width:
                              functionItem.fun_score &&
                              functionItem.fun_score.length > 0
                                ? functionItem.fun_score[0] + "%"
                                : "0%",
                            maxWidth: "100%",
                            height: "3px",
                            backgroundColor: "#0da14b",
                          }}
                        ></div>
                      </div>
                      <p
                        style={{
                          fontSize: "var(--captions-font-size)",
                          padding: "10px 0px 0px 0px",
                        }}
                      >
                        Required
                      </p>

                      <div className="exe-readiness-children">
                        {functionItem.children &&
                          functionItem.children.length > 0 &&
                          functionItem.children
                            .slice(0, 3)
                            .map((child, index) => (
                              <div
                                key={index}
                                className="exe-readiness-children-child"
                              >
                                {child}
                              </div>
                            ))}
                        {functionItem.children &&
                          functionItem.children.length > 3 && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "15px",
                                backgroundColor: "#E2E2E2",
                                padding: "2px 12px",
                                fontSize: "12px",
                              }}
                            >
                              +{functionItem.children.length - 3}
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </>
              ))}
            {ClickOnEdit && (
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                onClick={(e) => openModal()}
              >
                <div className="exe-readiness-cardmodal">
                  <i class="bi bi-plus-circle"></i>
                  <p
                    style={{
                      fontSize: "var(--text-font-size)",
                    }}
                  >
                    Add New Function
                  </p>
                </div>
              </button>
            )}
            {isOpen && (
              <div
                style={{
                  width: "50%",
                }}
                className={"sidenav"}
              >
                <div
                  className="gtm-sidebar-header"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    {!addExisting
                      ? "Add New Function"
                      : "Add Existing Functions"}
                  </div>
                  <a href="#" className="closebtn" onClick={closeModal}>
                    &times;
                  </a>
                </div>
                <div className="gtm-sidebar-body">
                  {!addExisting ? (
                    <>
                      <div style={{ height: 550 }}>
                        <div className="row">
                          <div
                            className="col-6"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                              zIndex: 2000,
                            }}
                          >
                            <div
                              style={{
                                fontSize: "var(--text-font-size)",
                                fontWeight: "var(--heading-font-weight)",
                              }}
                            >
                              Name
                            </div>
                            <input
                              style={{
                                marginLeft: "20px",
                                fontSize: "var(--text-font-size)",
                              }}
                              className="form-control"
                              value={formData.name}
                              placeholder="Add Function name"
                              onChange={(e) =>
                                handleInputChange("name", e.target.value, e)
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-6"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                              zIndex: 2000,
                            }}
                          >
                            <h6
                              style={{
                                marginRight: "10px",
                                fontSize: "var(--text-font-size)",
                                fontWeight: "var(--heading-font-weight)",
                              }}
                            >
                              Owner
                            </h6>
                            <Select
                              required
                              name="emp_id"
                              styles={customStyles}
                              className="form-control selectr"
                              placeholder={
                                <span
                                  style={{ fontSize: "var(--text-font-size)" }}
                                >
                                  Assign owner to Business Initiative
                                </span>
                              }
                              value={selectedOption}
                              options={emp_data}
                              onChange={handleChange}
                              isSearchable={true}
                              filterOption={customFilter}
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img alt="" src={e.icon} />
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      fontSize: "var(--text-font-size)",
                                    }}
                                  >
                                    {e.label}
                                  </span>
                                </div>
                              )}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                // Option: EmpIconOption,
                              }}
                            />
                          </div>
                        </div>
                        <Button
                          style={{
                            color: "white",
                            marginTop: "10px",
                            paddingTop: "10px",
                            width: "50%",
                            backgroundColor: "#0A5F59",
                            borderColor: "#0A5F59",
                          }}
                          onClick={(e) => setAddExisting(true)}
                        >
                          <div
                            style={{
                              fontSize: "var(--sub-heading-font-size)",
                            }}
                          >
                            Add Existing Functions
                          </div>
                        </Button>
                      </div>
                      {!addExisting ? (
                        <>
                          <div
                            style={{
                              textAlign: "right",
                              paddingLeft: "30px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              marginRight: "20px",
                            }}
                          >
                            <Button
                              type="button"
                              onClick={(e) => handleSubmit()}
                              className="save-button-style-rhpo"
                            >
                              Save
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <Button
                              type="button"
                              className="save-button-style-rhpo"
                              onClick={(e) => handleSubmit()}
                            >
                              Save
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div style={{ height: 550, overflowY: "auto" }}>
                        {functionData && functionData.length > 0 && (
                          <div>
                            {functionData.map((funItem, funIndex) => (
                              <div
                                key={funItem.id}
                                style={{
                                  boxShadow: "0px 2px 4px 0px #32302c1a",
                                  padding: "5px",
                                }}
                              >
                                <input
                                  style={{ fontSize: "var(--text-font-size)" }}
                                  type="checkbox"
                                  className="form-check-input"
                                  id={funItem.id}
                                  checked={checkboxStates[funItem.id] || false}
                                  onChange={() =>
                                    handleCheckboxChange(funItem.id)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  style={{
                                    paddingLeft: "10px",
                                    fontSize: "var(--text-font-size)",
                                  }}
                                  htmlFor={`checkbox_${funIndex}`}
                                >
                                  <div>{funItem.name}</div>
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          paddingLeft: "30px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          marginRight: "20px",
                        }}
                      >
                        <Button
                          type="button"
                          className="save-button-style-rhpo"
                          onClick={(e) => handleSubmit()}
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <CommentSection />
          {selectedHelpInfoSidebar && <SideNavHelpInfo />}
        </div>
      </div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showProducts={true}
        breadcrumbParent={"Strategy Readiness"}
        breadcrumbChild={"Strategy Readiness"}
        handleNext={handleClick}
        handleBackButton={handleBack}
        showEditButton={true}
        documentName={"Strategy Readiness"}
        helpKey={keys["Strategy Readiness"].Name}
        image={"question"}
        // ClickOnEdit={ClickOnEdit}
        handleExecutionEditClick={() => handleEditClick()}
        handleExecutionSaveClick={() => handleSaveClick()}
        // showExecutionEditButton={true}
        selectedPermission={selectedPermission}
      />
    );
  }
}

export default StrategyReadiness;
