import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuillEditor.css";
const QuillEditor = ({ value, onChange, height }) => {
  const [editorHtml, setEditorHtml] = useState(value);
  const quillRef = useRef(null);

  useEffect(() => {
    if (value) {
      setEditorHtml(value);
    }
  }, [value]);

  const handleQuillChange = (html) => {
    setEditorHtml(html);
    onChange(html);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false], font: [] }],
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "align",
        "code-block",
      ],
      [{ color: [] }, { background: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      ["link", "image", "formula"],
    ],
  };

  return (
    <ReactQuill
      ref={quillRef}
      value={editorHtml}
      onChange={handleQuillChange}
      modules={modules}
      theme="snow"
      style={{ height: height, zIndex: 999 }}
    />
  );
};

export default QuillEditor;
