/*jshint esversion: 6 */
import React from "react";
import "./Card.css";
import Image from "../assets/images/Checkmark.png";
import OtherImage from "../assets/images/Othericon.svg";
import { url } from "../store/sagas/API";

class Card extends React.Component {
  render() {
    return <div className="card">{this.props.children}</div>;
  }
}

class SelectableCard extends React.Component {
  render() {
    var isSelected = this.props.selected ? "selected" : "";
    var className = "selectable " + isSelected;
    return (
      <Card className="column">
        <div
          className={className}
          data-id={this.props.dataid}
          onClick={this.props.onClick}
        >
          {this.props.children}
          <div className="check">
            <img alt="" src={Image} className="checkmark" />
          </div>
        </div>
      </Card>
    );
  }
}

class SelectableTitleCard extends React.Component {
  render() {
    var { icon, title, description, selected, dataid } = this.props;

    return (
      <SelectableCard
        onClick={this.props.onClick}
        selected={selected}
        dataid={dataid}
      >
        <div className="content">
          <img
            alt=""
            style={{ width: 50, height: 50 }}
            className="icon"
            src={icon}
          />
          <div style={{ alignItems: "center" }}>
            <h4 className="title">{title}</h4>
            <h6 className="description">{description}</h6>
          </div>
        </div>
      </SelectableCard>
    );
  }
}

class SelectableCardList extends React.Component {
  constructor(props) {
    super(props);
    var selected = -1;
    var initialState = {
      selected: selected,
      selectionchange: false,
    };
    this.state = initialState;
  }

  onItemSelected(index) {
    this.setState((prevState, props) => {
      props.onChange(index);
      return {
        selected: index,
        selectionchange: true,
      };
    });
  }

  render() {
    var { contents } = this.props;
    var content = contents.map((cardContent, i) => {
      var { icon, title, prop_name, selected, id, isselected } = cardContent;
      selected = this.state.selected === i;
      if (isselected === "true") {
        isselected = true;
      }
      if (isselected === "false") {
        isselected = false;
      }
      if (isselected && !this.state.selectionchange) {
        selected = this.state.selected = i;
        if (selected === 0) {
          selected = true;
        }
      }

      return (
        <SelectableTitleCard
          key={i}
          icon={icon ? url + icon : OtherImage}
          title={title}
          description={prop_name}
          selected={selected}
          dataid={id}
          onClick={(e) => this.onItemSelected(i)}
        />
      );
    });
    return (
      <div style={{ overflow: "auto" }} className="cardlist">
        {content}
      </div>
    );
  }
}

class Example extends React.Component {
  onListChanged(selected) {
    this.setState({
      selected: selected,
      selectionchange: true,
    });
  }
  submit() {
    window.alert("Selected: " + this.state.selected);
  }
  render() {
    return (
      <div className="" style={{ height: "90%" }}>
        <h1 className="title" style={{ paddingBottom: 10 }}>
          {this.props.title}
        </h1>
        <SelectableCardList
          multiple={this.props.multiple}
          maxSelectable={this.props.maxSelectable}
          contents={this.props.cardContents}
          onChange={this.onListChanged.bind(this)}
        />
      </div>
    );
  }
}

export default Example;
