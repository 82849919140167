import React from "react";
import "./Test.css"; // Import the CSS file for styling

const Table = () => {
  const data = [
    { name: "Row 1", col1: "Value 1", col2: "Value 2", col3: "Value 3" },
    { name: "Row 2", col1: "Value 4", col2: "Value 5", col3: "Value 6" },
    { name: "Row 3", col1: "Value 7", col2: "Value 8", col3: "Value 9" },
  ];

  return (
    <div className="table-container">
      <div className="vertical-column">
        {data.map((row) => (
          <div key={row.name} className="vertical-cell">
            {row.name}
          </div>
        ))}
      </div>
      <div className="grid-container">
        <div className="header-cell">Column 1</div>
        <div className="header-cell">Column 2</div>
        <div className="header-cell">Column 3</div>
        {data.map((row) => (
          <React.Fragment key={row.name}>
            <div className="grid-cell">{row.col1}</div>
            <div className="grid-cell">{row.col2}</div>
            <div className="grid-cell">{row.col3}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Table;
