import React from "react";
import Select from "react-select";

const DisabledGoalSelector = ({ options, selectedGoals }) => {
  return (
    <Select
      isDisabled={true}
      className={"disabled-goal-selector"}
      classNamePrefix="custom-goal-select"
      options={options}
      value={selectedGoals}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions
      getOptionLabel={(e) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{ marginLeft: 5, fontSize: "var(--sub-heading-font-size)" }}
          >
            {e.label}
          </span>
        </div>
      )}
      placeholder=""
      //   onChange={handleGoalChange}
      allowSelectAll
      styles={{
        // Remove padding and background for selected options
        multiValue: (base) => ({
          ...base,
          background: "white",
          padding: "0 2px",
          margin: 1,
          textAlign: "center",
        }),
        multiValueLabel: (base) => ({
          ...base,
          // padding: 0,
        }),
        multiValueRemove: (base) => ({
          ...base,
          display: "none", // Hide the close icon
        }),
        multiValueGeneric: (base) => ({
          ...base,
          paddingLeft: 0, // Set padding-left to 0px to remove it
        }),
      }}
      components={{
        // DropdownIndicator: () => null,
        ClearIndicator: () => null,
        IndicatorSeparator: () => null,
        // MultiValueRemove: () => CustomMultiValueLabel,
        // Option: CustomOption,
      }}
    />
  );
};
export default DisabledGoalSelector;
