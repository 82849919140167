import React from "react";
import Select from "react-select";
const ProductSelector = ({
  showSelector,
  selectedProduct,
  productData,
  handleSelectProduct,
}) => {
  const customStyles = (borderStyle) => ({
    control: (provided, state) => ({
      ...provided,
      ...borderStyle,
      borderRadius: "10px",
      marginRight: "20px",
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: state.isSelected
        ? "#ecf4f3"
        : state.isFocused
        ? "#ecf4f3"
        : "white",
      color: "black",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
    }),
  });

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const redBorderStyle = {
    border: "2px solid red",
  };

  return (
    <div style={{ minWidth: "250px" }}>
      <Select
        required
        name="product_id"
        className={showSelector ? "selectpr" : "selectpr hide"}
        placeholder="Select Product"
        value={selectedProduct || ""}
        options={productData}
        onChange={handleSelectProduct}
        isSearchable={true}
        filterOption={customFilter}
        styles={customStyles(!selectedProduct ? redBorderStyle : {})}
        // components={{
        //  DropdownIndicator: () => null,
          // IndicatorSeparator: () => null,
          // Option: IconOption,
        // }}
        getOptionLabel={(e) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                marginLeft: 5,
                fontSize: "var(--sub-heading-font-size)",
              }}
            >
              {e.label}
            </span>
          </div>
        )}
      />
    </div>
  );
};

export default ProductSelector;
