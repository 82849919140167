import React, { useState, useEffect } from "react";
import { Form, FormCheck } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { default as ReactSelect } from "react-select";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getMeeting, postMeeting } from "../store/actions/index.js";
import BISelector from "./BISelector";
import DatePicker from "react-datepicker";
import { SlCalender } from "react-icons/sl";
import moment from "moment";
import { getRandomColor, createImageFromInitials } from "./Utils.js";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import "./Meeting.css";
import QuillEditor from "../utils/QuillEditor.js";

const Meeting = (props) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [meetingId, setMeetingId] = useState("");
  const [empData, setEmpData] = useState([]);
  const [meetingName, setMeetingName] = useState("New Meeting");
  const [owner, setOwner] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [agenda, setAgenda] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isShared, setIsShared] = useState(true);
  const [ownerIcon, setOwnerIcon] = useState("");
  //   const [selectedBI, setSelectedBI] = useState(null);
  const selectedBI = useSelector((state) => state.selectedBI);

  const [selectedRow, setSelectedRow] = useState(null);
  const isMeetingPostFetched = useSelector(
    (state) => state.isMeetingPostFetched
  );
  const [navigateNotes, setNavigateNotes] = useState(false);

  const postedMeetingData = useSelector((state) => state.postedMeetingData);
  const isBIFetched = useSelector((state) => state.isBIFetched);
  const biDetails = useSelector((state) => state.biDetails);

  const [meetings, setMeetings] = useState([]);
  const isMeetingDataFetched = useSelector(
    (state) => state.isMeetingDataFetched
  );
  const getMeetingData = useSelector((state) => state.getMeetingData);

  const [biData, setBiData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    console.log("date", date);
    setSelectedDate(date);
  };

  useEffect(() => {
    if (isBIFetched) {
      if (biDetails) {
        if (biDetails.ok) {
          if (biDetails.data) {
            setBiData(
              biDetails.data.bi_data.map((obj, i) => {
                if (obj.name === localStorage.getItem("bi_id")) {
                  dispatch({
                    type: "SET_SELECTED_BI",
                    payload: {
                      value: obj.name,
                      label: obj.bi_name,
                    },
                  });
                }
                return {
                  value: obj.name,
                  label: obj.bi_name,
                };
              })
            );
          }
        }
      }
    }
    return () => {};
  }, [isBIFetched]);
  useEffect(() => {
    if (isMeetingDataFetched) {
      if (getMeetingData) {
        setMeetings(getMeetingData.meeting_data);
        // setEmpData(getMeetingData.emp_data);
        if (getMeetingData.emp_data.length > 0) {
          setEmpData(
            getMeetingData.emp_data.map((obj) => {
              return {
                value: obj.value,
                label: obj.label,
                icon: createImageFromInitials(
                  30,
                  obj.label,
                  getRandomColor(obj.label)
                ),
              };
            })
          );
        }
      }
    }
  }, [isMeetingDataFetched, getMeetingData]);

  const handleAgendaOnChange = (content) => {
    setAgenda(content);
  };

  const handleCloseMeetingPopup = () => {
    onClose();
  };

  const handleBISelect = (e) => {
    localStorage.setItem("bi_id", e.value);
    dispatch(getMeeting(e.value));
    // setSelectedBI(e);
    dispatch({
      type: "SET_SELECTED_BI",
      payload: e,
    });
  };

  useEffect(() => {
    if (props.selectedMeeting !== "") {
      setSelectedRow(props.selectedMeeting);
      const meetingIdToFind = props.selectedMeeting;
      const meetingData = getMeetingData.meeting_data.find(
        (meeting) => meeting.meeting_id === meetingIdToFind
      );
      if (meetingData) {
        setMeetingId(meetingData.meeting_id);
        setOwner(meetingData.owner);
        setOwnerIcon(createImageFromInitials(30, owner, getRandomColor(owner)));
        setSelectedRow(meetingData.meeting_id);
        setMeetingName(meetingData.meeting);
        setAgenda(meetingData.agenda);
        setIsPrivate(meetingData.is_private);
        setIsShared(meetingData.is_shared);
        setMeetingDate(meetingData.date);
        setMeetingTime(meetingData.time);
        const parsedDate = moment(
          meetingData.date + " " + meetingData.time,
          "YYYY-MM-DD HH:mm:ss"
        );
        setSelectedDate(parsedDate.toDate());
        const selectedEmps =
          empData.length > 0 &&
          meetingData.emp.map((empId) => {
            const emp = empData.find((employee) => employee.value === empId);
            return emp;
          });
        if (selectedEmps.length > 0) {
          const selectedOptions = selectedEmps.map((employee) => ({
            value: employee.value,
            label: employee.label,
            icon: createImageFromInitials(
              30,
              employee.label,
              getRandomColor(employee.label)
            ),
          }));
          setSelectedEmployees(selectedEmps);
        }
      } else {
        console.log(`Meeting with ID ${meetingIdToFind} not found.`);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (isMeetingPostFetched) {
  //     console.log("true")
  //     if (postedMeetingData) {
  //       localStorage.setItem("note_id", postedMeetingData.note_id);
  //     }
  //   }
  // }, [isMeetingPostFetched]);

  useEffect(() => {
    const fullname = localStorage.getItem("full_name");
    if (fullname) {
      setOwner(fullname);
      setOwnerIcon(
        createImageFromInitials(30, fullname, getRandomColor(fullname))
      );
    }
  }, []);

  const handleEmployeeChange = (selectedOptions) => {
    const updatedOptions = selectedOptions.filter((option) =>
      empData.some((emp) => emp.value === option.value)
    );
    setSelectedEmployees(updatedOptions);
  };

  const handleRowClick = (row) => {
    setMeetingId(row.meeting_id);
    setOwner(row.owner);
    setOwnerIcon(createImageFromInitials(30, owner, getRandomColor(owner)));
    setSelectedRow(row.meeting_id);
    setMeetingName(row.meeting);
    setAgenda(row.agenda);
    setIsPrivate(row.is_private);
    setIsShared(row.is_shared);
    setMeetingDate(row.date);
    setMeetingTime(row.time);
    const parsedDate = moment(row.date + " " + row.time, "YYYY-MM-DD HH:mm:ss");
    setSelectedDate(parsedDate.toDate());
    const selectedEmps =
      empData.length > 0 &&
      row.emp.map((empId) => {
        const emp = empData.find((employee) => employee.value === empId);
        return emp;
      });
    if (selectedEmps.length > 0) {
      const selectedOptions = selectedEmps.map((employee) => ({
        value: employee.value,
        label: employee.label,
        icon: createImageFromInitials(
          30,
          employee.label,
          getRandomColor(employee.label)
        ),
      }));
      setSelectedEmployees(selectedEmps);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (navigateNotes) {
      navigate("/notes", { replace: true });
      window.location.reload();
      onClose();
    } else {
      console.log("false");
    }
  }, [navigateNotes]);

  const handleNotesClick = (navigateFlag, event) => {
    event.preventDefault();
    setNavigateNotes(navigateFlag);
    const updatedOptions = meetings.filter(
      (meeting) => meeting.meeting_id === selectedRow
    );
    updatedOptions.map((note) => {
      localStorage.setItem("note_id", note.notes_id);
    });
    let emp_list = [];
    if (!isShared && !isPrivate) {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: selectedEmployees.some(
          (selectedEmp) => selectedEmp.value === emp.value
        ),
      }));
    } else if (!isPrivate) {
      emp_list = empData.map((emp) => ({
        key: emp.value,
        is_active: true,
      }));
    }
    const date = formatDate(selectedDate);
    const time = formatTime(selectedDate);
    let post_meeting_data = [
      {
        action: "agenda",
        meeting: meetingName,
        agenda: agenda,
        is_private: isPrivate,
        is_shared: isShared,
        meeting_id: meetingId,
        emp: emp_list,
        date: date,
        time: time,
      },
    ];
    dispatch(postMeeting(localStorage.getItem("bi_id"), post_meeting_data));
  };
  useEffect(() => {
    if (isMeetingPostFetched) {
      dispatch(getMeeting(localStorage.getItem("bi_id")));
    }
  }, [isMeetingPostFetched]);

  const handleIsprivateClick = () => {
    setIsPrivate(!isPrivate);
    if (!isPrivate) {
      setIsShared(false);
    }
  };

  const handleIssharedClick = () => {
    setIsShared(!isShared);
    setIsPrivate(false);
  };

  const CustomInput = ({ value, onClick }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: 8,
        border: "1px solid #ced4da",
        padding: "0px 8px",
        cursor: "pointer",
        width: "220px",
      }}
      onClick={onClick}
    >
      <input
        type="text"
        value={value}
        readOnly
        placeholder="yyyy-MM-dd HH:mm:ss"
        style={{
          flex: 1,
          border: "none",
          outline: "none",
          background: "transparent",
          margin: "5px 0",
        }}
      />
      <SlCalender />
    </div>
  );

  return (
    <div className="modal-overlay">
      <div
        className="modal-content meeting-popover"
        style={{
          padding: "20px",
          height: "90%",
          overflowY: "auto",
          fontSize: "var(--sub-heading-font-size)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5 style={{ fontWeight: "var(--heading-font-weight)" }}>
            Meeting Planner
          </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              zIndex: "100",
            }}
          >
            <BISelector
              showSelector={true}
              selectedBI={selectedBI}
              biData={biData}
              handleSelect={handleBISelect}
              style={{ marginRight: "10px" }}
            />
            <AiOutlineClose
              size={24}
              onClick={handleCloseMeetingPopup}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <hr style={{ borderTop: "1px solid" }} />
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "50%",
              border: "1px solid lightgray",
              background: "#ecf4f3",
            }}
          >
            <table
              className="meeting-table"
              style={{ width: "100%", textAlign: "center" }}
            >
              <thead
                style={{
                  backgroundColor: "#0A5F59",
                  color: "white",
                }}
              >
                <tr>
                  <th style={{ textAlign: "left", paddingLeft: "10px" }}>
                    Meeting
                  </th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "10px" }}></tr>
                {meetings?.length > 0 ? (
                  meetings.map((val, key) => (
                    <tr
                      key={key}
                      onClick={() => handleRowClick(val)}
                      style={{
                        border:
                          selectedRow === val.meeting_id
                            ? "2px solid #0A5F59"
                            : "none",
                        backgroundColor:
                          selectedRow === val.meeting_id
                            ? "#D9F6E4"
                            : "transparent",
                        color:
                          selectedRow === val.meeting_id
                            ? "#0A5F59"
                            : "inherit",
                      }}
                    >
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        {val.meeting}
                      </td>
                      <td>{val.date}</td>
                      <td>{val.time}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        height: "250px",
                        verticalAlign: "middle",
                        backgroundColor: "rgb(236, 244, 243)",
                      }}
                    >
                      No meetings available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="modal-body-right"
            style={{ width: "50%", paddingLeft: "20px" }}
          >
            <div className="row">
              <div className="col-12">
                <Form.Group
                  controlId="formMeetingName"
                  style={{
                    marginTop: 0,
                  }}
                >
                  <Form.Control
                    type="text"
                    value={meetingName}
                    onChange={(e) => setMeetingName(e.target.value)}
                    placeholder="Add Meeting Title"
                    required
                  />
                </Form.Group>
              </div>
            </div>
            {/* <h6>{meetingName}</h6> */}
            <div className="row">
              <div className="col-12 col-lg-7 col-sm-12">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <b
                    style={{
                      paddingTop: "5px",
                      marginRight: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      textWrap: "nowrap",
                    }}
                  >
                    Schedule Date:
                  </b>
                  <div style={{ zIndex: 11 }}>
                    <DatePicker
                      placeholderText="yyyy-MM-dd HH:mm:ss"
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      showYearDropdown
                      scrollableYearDropdown
                      customInput={<CustomInput />}
                      showMonthDropdown
                      className="custom-datepicker"
                      yearDropdownItemNumber={1000}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-sm-12">
                <div
                  // className="custom-datepicker"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                      paddingRight: "5px",
                    }}
                  >
                    Owner:
                  </div>

                  <img alt="" src={ownerIcon} />
                  {owner}
                </div>
              </div>
            </div>
            <Form.Group controlId="formAdditionalField">
              <strong>
                <Form.Label
                  style={{
                    marginBottom: "0px",
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  Agenda:
                </Form.Label>
              </strong>
              <div style={{ height: "380px" }}>
                <QuillEditor
                  value={agenda}
                  onChange={handleAgendaOnChange}
                  height={300}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formCheckbox" style={{ marginTop: "10px" }}>
              <Form.Check
                type="checkbox"
                label="Share with everyone"
                checked={isShared}
                onChange={() => handleIssharedClick()}
              />
            </Form.Group>
            {!isShared && !isPrivate && (
              <Form.Group>
                <span
                  data-toggle="popover"
                  data-trigger="focus"
                  data-content="Please selecet employee(s)"
                >
                  <Select
                    options={empData}
                    value={selectedEmployees}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img alt="" src={e.icon} />
                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                      </div>
                    )}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={handleEmployeeChange}
                    allowSelectAll
                  />
                </span>
              </Form.Group>
            )}
            <Form.Group
              controlId="formCheckbox"
              style={{ paddingTop: "10px", paddingBottom: "30px" }}
            >
              <Form.Check
                type="checkbox"
                label="Private"
                checked={isPrivate}
                onChange={() => handleIsprivateClick()}
              />
            </Form.Group>
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            marginTop: 20,
            flexWrap: "wrap",
            justifyContent: "space-between",
            bottom: 20,
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <button
            style={{
              width: "40%",
              backgroundColor: "#0A5F59",
              color: "white",
              borderRadius: 10,
              height: 48,
              border: "thin ridge",
              marginRight: 20,
            }}
            onClick={(e) => handleNotesClick(false, e)}
          >
            Schedule Meeting
          </button>
          <button
            style={{
              width: "40%",
              backgroundColor: "#0A5F59",
              borderColor: "#0A5F59",
              color: "white",
              borderRadius: 10,
              height: 48,
              border: 0,
            }}
            onClick={(e) => handleNotesClick(true, e)}
          >
            Go to meeting
            <FaArrowRight style={{ marginLeft: "5px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Meeting;
