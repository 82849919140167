import React, { useEffect } from "react";
import { Badge } from "react-bootstrap";
import { PiBellLight } from "react-icons/pi";
import { getRandomColor, createImageFromInitials } from "./Utils.js";
import { AiFillCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  getNoticationsDetails,
  postNoticationsDetails,
} from "../store/actions/index.js";

const NotificationMenu = ({
  notifData,
  // handleRemoveNotification,
  notificationRef,
  fecthPostNotifData,
}) => {
  console.log(notifData);
  const dispatch = useDispatch();
  const handleRemoveNotification = (id) => {
    dispatch(postNoticationsDetails(id));
  };
  useEffect(() => {
    if (fecthPostNotifData) {
      dispatch(getNoticationsDetails(false));
    }
  }, [fecthPostNotifData]);
  function stripHtmlTags(str) {
    const div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText || "";
  }
  return (
    <div
      className="notification-menu"
      style={{
        width: "400px",
        overflowX: "hidden",
        textAlign: "left",
        right: "60px",
        backgroundColor: "#ecf4f3",
        position: "absolute",
        zIndex: 9999,
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        top: "62px",
      }}
      ref={notificationRef}
    >
      {notifData &&
        notifData.length > 0 &&
        notifData.map((notification, index) => (
          <div
            key={notification.id}
            style={{
              borderBottom:
                index === notifData.length - 1 ? "none" : "1px solid #ccc",
              padding: "10px 10px 0px 10px",
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight:
                      notification.is_read === 1 ||
                      notification.is_read === true
                        ? "0"
                        : "500",
                  }}
                >
                  {stripHtmlTags(notification.desc)}
                </div>
                <AiFillCloseCircle
                  style={{ color: "rgb(10, 95, 89)" }}
                  size={20}
                  onClick={() => handleRemoveNotification(notification.id)}
                />
              </div>
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {stripHtmlTags(notification.title)}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight:
                      notification.is_read === 1 ||
                      notification.is_read === true
                        ? "0"
                        : "500",
                  }}
                >
                  Date
                </div>
                {notification.created_dt}
              </div>
              <p
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight:
                      notification.is_read === 1 ||
                      notification.is_read === true
                        ? "0"
                        : "500",
                  }}
                >
                  Owner
                </div>
                <div style={{ fontSize: "14px" }}>
                  <img
                    style={{ marginRight: "5px" }}
                    alt=""
                    src={createImageFromInitials(
                      30,
                      notification.owner,
                      getRandomColor(notification.owner)
                    )}
                  />
                  {notification.owner}
                </div>
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default NotificationMenu;
