import { createChatBotMessage } from "react-chatbot-kit";
import * as Colors from "../../../../style/base";
const botName = "Excelrate Bot";

const config = {
  initialMessages: [createChatBotMessage(`Hi! I'm ${botName}`)],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: Colors.PRIMARY_COLOR,
    },
    chatButton: {
      backgroundColor: Colors.SECONDARY_COLOR,
    },
  },
};

export default config;
