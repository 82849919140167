import React, { useEffect, useState } from "react";
import "./vcmapping/Mapping.css";
import MainComponent from "../MainComponent";
import { getOrgDetails } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Login from "../Login";

function OrgDetails() {
  const orgDetailsData = useSelector((state) => state.orgDetailsData);
  const isOrgDetailsFetched = useSelector((state) => state.isOrgDetailsFetched);
  const [regDetails, setRegDetails] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [sector, setSector] = useState(null);
  const [subSector, setSubSector] = useState(null);
  const [website, setWebsite] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOrgDetails());
    return () => {};
  }, []);
  useEffect(() => {
    if (isOrgDetailsFetched) {
      if (orgDetailsData) {
        if (orgDetailsData.ok) {
          if (orgDetailsData.data && orgDetailsData.data.data) {
            setOrgName(orgDetailsData.data.data[0].company_name);
            setRegDetails(orgDetailsData.data.data[0].registration_details);
            setSector(orgDetailsData.data.data[0].sector);
            setSubSector(orgDetailsData.data.data[0].sub_sector);
            setWebsite(orgDetailsData.data.data[0].website);
          }
        } else {
          return <div>{alert(orgDetailsData.data)}</div>;
        }
      } else {
        return <div>{alert("Not able to fetch the data")}</div>;
      }
    }
  }, [isOrgDetailsFetched]);
  const handleClick = () => {
    navigate("/bidetails", { replace: true });
  };
  const content = (
    <>
      <div>
        <h4>Organisation Details</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "3vh 0px 10px 5px",
          }}
        >
          <label>Name:</label>
          <input
            id="name"
            type="text"
            className="form-control"
            style={{ width: 620, backgroundColor: "transparent" }}
            value={orgName || ""}
            disabled
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "5px",
          }}
        >
          <label>Registration Details:</label>
          <input
            id="registrationDetails"
            type="text"
            className="form-control"
            style={{ width: 620, backgroundColor: "transparent" }}
            value={regDetails || ""}
            disabled
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
              marginRight: "20px",
            }}
          >
            <label>Sector:</label>
            <input
              id="sector"
              type="text"
              className="form-control"
              style={{ width: 300, backgroundColor: "transparent" }}
              value={sector || ""}
              disabled
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "5px",
            }}
          >
            <label>Sub Sector:</label>
            <input
              id="subsector"
              type="text"
              className="form-control"
              style={{ width: 300, backgroundColor: "transparent" }}
              value={subSector || ""}
              disabled
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: "5px",
            marginRight: "20px",
          }}
        >
          <span>Website:</span>
          <a style={{ paddingLeft: "10px" }} href={website} target="_blank">
            {website}
          </a>
        </div>
      </div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          showTemplate={true}
          showButtons={true}
          handleNext={handleClick}
          hideBackButton={true}
          breadcrumbParent={"Onboarding"}
          breadcrumbChild={"Organisation Details"}
        />
      </>
    );
  }
}

export default OrgDetails;
