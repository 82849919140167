import React, { useEffect, useRef, useState } from "react";
import Questions from "../Questions";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../store/actions";
import Image from "../../../assets/images/icon.svg";
import { Button } from "react-bootstrap";
import UploadFiles from "../../UploadFiles";

export default function Options({
  attachments,
  optionData,
  categoryId,
  sectionId,
  questionId,
  verticalId,
  isMaster,
  questionIndex = 0,
  display = "flex",
  selectedPermission,
  funQuestionAnsOptId,
}) {
  const dispatch = useDispatch();
  const allQuestionsList = useSelector((state) => state.allQuestionsList);
  const [children, setChildren] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const handleClick = (
    optionDetails,
    showMessage = true,
    isDelete = false,
    inputValue = "",
    isEdit = false
  ) => {
    if (showMessage != "edit") {
      if (optionDetails.type == "list" || optionDetails.type == "text") {
        if (isDelete) {
          dispatch(
            Actions.postExecutionReadinessCustomerInputDetails(
              localStorage.getItem("bi_id"),
              localStorage.getItem("product_id"),
              questionId,
              verticalId,
              "delete",
              false,
              optionDetails.key
            )
          );
        } else if (isEdit) {
          dispatch(
            Actions.postExecutionReadinessCustomerInputDetails(
              localStorage.getItem("bi_id"),
              localStorage.getItem("product_id"),
              questionId,
              verticalId,
              "edit",
              inputValue,
              optionDetails.key,
              optionDetails.type
            )
          );
        } else {
          dispatch(
            Actions.postExecutionReadinessCustomerInputDetails(
              localStorage.getItem("bi_id"),
              localStorage.getItem("product_id"),
              questionId,
              verticalId,
              "add",
              inputValue,
              "",
              optionDetails.type
            )
          );
        }
      } else {
        dispatch(
          Actions.postExecutionReadinessCustomerAnswerChoiceDetails(
            localStorage.getItem("function_id"),
            questionId,
            optionDetails.key,
            isMaster,
            sectionId,
            localStorage.getItem("bi_id"),
            localStorage.getItem("product_id"),
            optionDetails.title
          )
        );
      }
    }

    if (showMessage && optionDetails.type !== "list")
      setShowMessage(optionDetails.children.length === 0);
    let tempData = allQuestionsList.map((el) => {
      if (el.key === categoryId) {
        // _checkSections(el.sections, optionDetails, isDelete);
        if (el.hasOwnProperty("sections") && el.sections?.length > 0) {
          _checkSections(el.sections, optionDetails, isDelete);
        }
        if (el.hasOwnProperty("questions") && el.questions?.length > 0) {
          _checkQuestions(el.questions, optionDetails, isDelete);
        }
      }
      return el;
    });
    dispatch(Actions.setAllQuestions(tempData));
  };
  const _checkSections = (sections, optionDetails, isDelete) => {
    sections.map((sec) => {
      _checkQuestions(sec.questions, optionDetails, isDelete);
      if (sec.sections?.length > 0) {
        _checkSections(sec.sections, optionDetails, isDelete);
      }
    });
  };

  const _checkQuestions = (questions, optionDetails, isDelete) => {
    questions.map((question) => {
      if (question.section?.length > 0) {
        try {
          _checkSections(question.sections, optionDetails, isDelete);
        } catch (e) {
          //
        }
      } else {
        if (isDelete) {
          try {
            let findSelectedQuestion = true;
            let matchIndexValue = 0;

            question.options.map((opt, index) => {
              if (opt.key === optionDetails.key) {
                findSelectedQuestion = false;
                matchIndexValue = index;
              } else if (findSelectedQuestion) {
                _checkQuestions(opt.children, optionDetails, isDelete);
              }
              return opt;
            });
            if (!findSelectedQuestion) {
              question.options.splice(matchIndexValue, 1);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          _checkOptionsActiveStatus(question, optionDetails);
        }
      }
      return question;
    });
  };

  const _checkOptionsActiveStatus = (question, optionDetails) => {
    let findSelectedQuestion = true;
    let isClearChildrenData = true;
    question.options.map((opt) => {
      if (question.key === questionId) {
        findSelectedQuestion = false;
        if (opt.selected) {
          opt.selected = false;
          //setShowMessage(false);
          _clearSelectedQuestions(opt.children, isClearChildrenData);
        } else {
          if (opt.key === optionDetails.key) {
            isClearChildrenData = false;
            opt.selected = true;
            if (optionDetails.type === "list") {
              if (opt.children && opt.children.length === 0 && opt.values) {
                opt.children = opt.values;
              }
              setChildren([]);
              setShowMessage(optionDetails.values?.length === 0);
            } else {
              setChildren(optionDetails.children);
              setShowMessage(optionDetails.children.length === 0);
            }
          } else {
            opt.selected = false;
            if (isClearChildrenData) setShowMessage(false);
          }
        }
      } else if (findSelectedQuestion) {
        _checkQuestions(opt.children, optionDetails);
      }
      return opt;
    });
  };

  const _clearSelectedQuestions = (questions, isClearChildrenData) => {
    questions.map((question) => {
      question.options.map((opt) => {
        opt.selected = false;
        _clearSelectedQuestions(opt.children, isClearChildrenData);
        return opt;
      });
      if (isClearChildrenData) {
        setShowMessage(false);
        setChildren([]);
      }
      return question;
    });
  };

  const initialAction = (optionDetails) => {
    setChildren(optionDetails.children);
    if (optionDetails.type !== "file")
      setShowMessage(optionDetails.children.length === 0);
  };
  return (
    <>
      {/* <div style={{ display: child.type === "list" ? "block" : "flex" }}> */}
      <div style={{ display: display }}>
        {optionData.map((child, index) => (
          <OptionsValue
            attachments={attachments}
            questionId={questionId}
            optionData={optionData}
            child={child}
            key={child.key}
            handleClick={handleClick}
            initialAction={initialAction}
            categoryId={categoryId}
            sectionId={sectionId}
            questionIndex={questionIndex}
            selectedPermission={selectedPermission}
            children={child.children}
          />
        ))}
      </div>

      <div style={{ marginTop: "10px" }}>
        {children.length > 0 && (
          <Questions
            categoryId={categoryId}
            sectionId={sectionId}
            questions={children}
            questionIndex={questionIndex}
          />
        )}
        {/* {showMessage && (
          <p style={{ color: "#b5b5b5", marginLeft: "50px" }}>
            {"This is the last question for this section..."}
          </p>
        )} */}
      </div>
    </>
  );
}

const OptionsValue = ({
  attachments,
  questionId,
  optionData,
  child,
  handleClick,
  initialAction,
  categoryId,
  sectionId,
  questionIndex,
  selectedPermission,
}) => {
  const { title = "", selected = false, type = "radio" } = child;
  useEffect(() => {
    if (selected) {
      initialAction(child);
    }
  }, [selected]);
  return (
    <>
      {type === "radio" && (
        <RadioButton
          selected={selected}
          title={title}
          handleClick={handleClick}
          child={child}
          selectedPermission={selectedPermission}
        />
      )}
      {type === "file" && (
        <UploadButton
          attachments={attachments}
          optionData={optionData}
          questionId={questionId}
          selectedPermission={selectedPermission}
        />
      )}
      {type === "list" && (
        <ListType
          optionData={optionData}
          selected={selected}
          title={title}
          handleClick={handleClick}
          child={child}
          categoryId={categoryId}
          sectionId={sectionId}
          questionIndex={questionIndex}
        />
      )}
      {type === "text" && (
        <TextInput
          title={title}
          handleClick={handleClick}
          child={child}
          categoryId={categoryId}
          sectionId={sectionId}
          questionIndex={questionIndex}
        />
      )}
    </>
  );
};

const RadioButton = ({
  selected,
  title,
  handleClick,
  child,
  selectedPermission,
}) => {
  const handleRadioChange = () => {
    if (selectedPermission === "write" || selectedPermission === "admin") {
      handleClick(child);
      console.log("child", child);
    }
  };
  return (
    <div
      onClick={handleRadioChange}
      style={{
        margin: "10px 0px 0px 50px",
        cursor: "pointer",
        display: "flex",
      }}
    >
      <div>
        <input
          type="radio"
          checked={selected}
          value={title}
          onChange={() => {
            //handleClick(child)
          }}
          disabled={
            selectedPermission === "write" || selectedPermission === "admin"
              ? false
              : true
          }
        />
      </div>
      <span style={{ paddingLeft: 5 }}>{title}</span>
    </div>
  );
};

const UploadButton = ({ selectedPermission, optionData, attachments }) => {
  const function_id = localStorage.getItem("function_id");
  const bi_id = localStorage.getItem("bi_id");
  const product_id = localStorage.getItem("product_id");
  const questionId = optionData[0].fun_que_ans_opt_id;

  return (
    <div
      style={{
        margin: "10px 0px 0px 40px",
        cursor: "pointer",
        width: "50%",
      }}
    >
      {questionId && (
        <UploadFiles
          selectedSubMenuPermission={selectedPermission}
          function_id={function_id}
          bi_id={bi_id}
          questionId={questionId}
          product_id={product_id}
          attachments={attachments}
        />
      )}
    </div>
  );
};

const ListType = ({
  optionData,
  selected,
  title,
  handleClick,
  child,
  categoryId,
  sectionId,
  questionIndex,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(title);
  const [isEdit, setIsEdit] = useState(title && title !== "");
  const [edit, setEdit] = useState(false);
  const selectedPermission = useSelector((state) => state.selectedPermission);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth" });
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {(selectedPermission === "write" || selectedPermission === "admin") && (
        <div
          style={{
            display: "flex",
            margin: "10px 40px 0",
            alignItems: "center",
          }}
        >
          <input
            ref={inputRef}
            style={{ fontSize: "var(--sub-heading-font-size)" }}
            className="form-control option-input"
            placeholder="Enter your vertical"
            type="text"
            name="type here..."
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
            value={inputValue}
            disabled={isEdit}
          />
          {!isEdit && (
            // <img
            //   style={{ padding: 5, cursor: "pointer", height: 30, width: 30 }}
            //   onClick={() => {
            //     if (inputValue.trim() !== "") {
            //       handleClick(child);
            //       setIsEdit(inputValue.trim() !== "");
            //     }
            //   }}
            //   src={require("../../../assets/images/check.png")}
            //   alt={"done"}
            // />
            <Button
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                backgroundColor: "#0A5F59",
                borderColor: "#0A5F59",
                height: 35,
                fontSize: "var(--sub-heading-font-size)",
              }}
              onClick={() => {
                if (inputValue.trim() !== "") {
                  handleClick(child, true, false, inputValue, edit);
                  setIsEdit(inputValue.trim() !== "");
                  setEdit(false);
                }
              }}
            >
              Save
            </Button>
          )}

          {isEdit && (
            <img
              style={{ padding: 5, cursor: "pointer", height: 30, width: 30 }}
              onClick={() => {
                setIsEdit(false);
                setEdit(true);
                handleClick(child, "edit");
              }}
              src={require("../../../assets/images/edit.png")}
              alt={"edit"}
            />
          )}

          {optionData.length > 0 && (
            <Button
              style={{
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                color: "#0A5F59",
                height: 35,
                fontSize: "var(--sub-heading-font-size)",
              }}
              onClick={() => {
                handleClick(child, false, true);
              }}
            >
              Cancel
            </Button>
          )}

          {/* {isEdit && !child.selected && (
          <img
            style={{ padding: 5, cursor: "pointer", height: 30, width: 30 }}
            onClick={() => {
              handleClick(child);
            }}
            src={require("../../../assets/images/view.png")}
            alt={"edit"}
          />
        )} */}
        </div>
      )}
      <div style={{ marginTop: "10px" }}>
        {child.children.length > 0 && (
          <Questions
            categoryId={categoryId}
            sectionId={sectionId}
            questions={child.children}
            questionIndex={questionIndex}
          />
        )}
      </div>
    </>
  );
};

const TextInput = ({
  title,
  handleClick,
  child,
  categoryId,
  sectionId,
  questionIndex,
}) => {
  const [inputValue, setInputValue] = useState(title);
  const [isEdit, setIsEdit] = useState(title && title !== "");
  const [edit, setEdit] = useState(false);
  return (
    <div
      style={{ display: "flex", margin: "10px 40px 0", alignItems: "center" }}
    >
      <input
        style={{ fontSize: "var(--sub-heading-font-size)" }}
        className="form-control option-input"
        type="text"
        placeholder="Enter your data"
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value);
        }}
        disabled={isEdit}
      />
      {!isEdit && (
        <Button
          style={{
            cursor: "pointer",
            marginLeft: "10px",
            backgroundColor: "#0A5F59",
            borderColor: "#0A5F59",
            height: 35,
            fontSize: "var(--sub-heading-font-size)",
          }}
          onClick={() => {
            if (inputValue.trim() !== "") {
              handleClick(child, true, false, inputValue, edit);
              setIsEdit(inputValue.trim() !== "");
              setEdit(false);
            }
          }}
        >
          Save
        </Button>
      )}

      {isEdit && (
        <img
          style={{ padding: 5, cursor: "pointer", height: 30, width: 30 }}
          onClick={() => {
            setIsEdit(false);
            setEdit(true);
            handleClick(child, "edit");
          }}
          src={require("../../../assets/images/edit.png")}
          alt={"edit"}
        />
      )}

      <Button
        style={{
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none",
          color: "#0A5F59",
          height: 35,
          fontSize: "var(--sub-heading-font-size)",
        }}
        onClick={() => {
          handleClick(child, false, true);
        }}
      >
        Cancel
      </Button>
    </div>
  );
};

// const UploadButton = ({selected, title, handleClick, child}) => {
//     return(
//         <div onClick={()=> {
//             handleClick(child);
//         }} style={{ marginLeft: "10px", cursor: 'pointer'}}>
//             <input type="file" name="filename"/>
//         </div>
//     )
// };
