export const createProgressBar = (status) => {
  return (
    <div className="progress-card" style={{ width: "40%" }}>
      {status === "AT RISK" ? (
        <div className="progress-card-danger">
          <div className="danger-progress"></div>
        </div>
      ) : status === "ON TRACK" ? (
        <div className="progress-card-success">
          <div className="success-progress"></div>
        </div>
      ) : status === "OFF TRACK" ? (
        <div className="progress-card-info">
          <div className="info-progress"></div>
        </div>
      ) : (
        <div className="progress-card-default">
          <div className="default-progress"></div>
        </div>
      )}
    </div>
  );
};

export const createStatus = (status) => {
  return (
    <div
      style={{
        marginLeft: "15px",
      }}
    >
      {status === "Open" ? (
        <span className="Open">{status}</span>
      ) : status === "Working" ? (
        <span className="Working">{status}</span>
      ) : status === "Pending Review" ? (
        <span className="Pending-Review">{status}</span>
      ) : status === "Overdue" ? (
        <span className="Overdue">{status}</span>
      ) : status === "Template" ? (
        <span className="Template">{status}</span>
      ) : status === "Completed" ? (
        <span className="Completed">{status}</span>
      ) : status === "Cancelled" ? (
        <span className="Cancelled">{status}</span>
      ) : (
        status && <span className="default-status">{status}</span>
      )}
    </div>
  );
};

export const createPriority = (status) => {
  return (
    <div>
      {status === "Low" ? (
        <span className="Low">{status}</span>
      ) : status === "Medium" ? (
        <span className="Medium">{status}</span>
      ) : status === "High" ? (
        <span className="High">{status}</span>
      ) : status === "Urgent" ? (
        <span className="Urgent">{status}</span>
      ) : (
        status && <span className="default-status">{status}</span>
      )}
    </div>
  );
};
