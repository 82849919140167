import React, { useEffect, useState, useRef } from "react";
import { getRandomColor, createImageFromInitials } from "../Utils.js";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { postStrategyFunctions } from "../../store/actions/index.js";
import { StepProgressBar } from "../Utils.js";
import GoalSelector from "./GoalSelector.js";
import { resetExceptionError } from "../../store/actions/index.js";
import Alert from "../Alert.js";
import UploadFiles from "../UploadFiles.js";
import QuillEditor from "../../utils/QuillEditor.js";
function StrategyPlanSidebar({
  isEdit,
  data,
  type,
  placeholder,
  goalData,
  empData,
  parentId,
  statusData,
  priorityData,
  breadcrumbs,
  functionIndex,
  impIndex,
  impid,
  milestoneIndex,
  handleMilestoneEdit,
  handleStrategicImpEdit,
  onClose,
  setIsSidebarOpen,
  handleStrategicImpDelete,
  handleMilestoneDelete,
  milestonedataId,
}) {
  console.log("data", data);
  const dispatch = useDispatch();
  const ISError = useSelector((state) => state.ISError);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [title, setTitle] = useState(isEdit ? data.name : "");
  const [progressHide, setProgressHide] = useState(false);
  const [assignedTo, setAssignedTo] = useState(
    isEdit ? data.assigned_to.filter((employee) => employee.is_active) : []
  );
  const [selectedGoals, setSelectedGoals] = useState(
    isEdit && type !== "milestone"
      ? data.goals_dt.filter((goal) => goal.is_active)
      : []
  );
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [progress, setProgress] = useState(isEdit ? data.progress : 0);
  const [desc, setDesc] = useState(isEdit ? data.description : "");
  const [status, setStatus] = useState(
    isEdit ? { label: data.status, value: data.status } : ""
  );
  const [priority, setPriority] = useState(
    isEdit ? { label: data.priority, value: data.priority } : ""
  );
  const [startDate, setstartDate] = useState(isEdit ? data.exp_start_date : "");
  const [duedate, setDuedate] = useState(isEdit ? data.exp_end_date : "");
  const initialCollaborators =
    isEdit && data.collaberators
      ? data.collaberators.filter((employee) => employee.is_active)
      : [];
  const [collaberators, setCollaberators] = useState(initialCollaborators);

  const [milestoneId, setMilestoneId] = useState(
    isEdit && type === "milestone" ? data.id : ""
  );
  const [functionalGoalId, setFunctionalGoalId] = useState(
    isEdit && type !== "milestone" ? data.id : ""
  );
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const getProgress = (step) => {
    if (step === 0) {
      return 0;
    } else if (step === 20) {
      return 1;
    } else if (step === 40) {
      return 2;
    } else if (step === 60) {
      return 3;
    } else if (step === 80) {
      return 4;
    } else {
      return 5;
    }
  };
  const [currentStep, setCurrentStep] = useState(
    isEdit ? getProgress(data.progress) : 0
  );
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];

  const customStyles = (borderStyle) => ({
    control: (provided, state) => ({
      ...provided,
      ...borderStyle,
      borderRadius: "10px",
      marginRight: "20px",
      marginBottom: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: state.isSelected ? "#ecf4f3" : "white",
      color: "black",
    }),
  });

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("strategyplan")) {
      setProgressHide(true);
    } else {
      setProgressHide(false);
    }
  }, []);
  const handleOwnerChange = (e) => {
    setAssignedTo(e);
  };
  const handleCollaboratorChange = (e) => {
    setCollaberators(e);
  };

  const handleFullScreen = () => {
    setFullScreenMode(true);
  };

  const handleSideNav = () => {
    setFullScreenMode(false);
  };

  const handleStatusSelect = (selectedOptions) => {
    setStatus(selectedOptions);
  };

  const handlePrioritySelect = (selectedOptions) => {
    setPriority(selectedOptions);
  };

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleDescOnChange = (content) => {
    setDesc(content);
  };

  const handleSave = () => {
    if (
      selectedSubMenuPermission === "write" ||
      selectedSubMenuPermission === "admin"
    ) {
      if (type === "milestone") {
        if (title !== "") {
          if (isEdit) {
            setIsSidebarOpen(false);
            let assignedToList = [];
            if (Array.isArray(assignedTo)) {
              assignedToList = assignedTo;
            } else {
              assignedToList = [assignedTo];
            }
            const requiredAssignedData = data.assigned_to.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const updatedAssignedData = data.assigned_to.map((item) => ({
              ...item,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));

            let milestone_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                milestone: title,
                milestone_id: milestoneId,
                status: status.value,
                start_date: startDate || "",
                due_date: duedate || "",
                priority: priority.value,
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];

            handleMilestoneEdit(
              functionIndex,
              impIndex,
              milestoneIndex,
              milestone_data,
              updatedAssignedData
            );
          } else {
            const assignedToList = [assignedTo];
            const requiredAssignedData = empData.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));

            let milestone_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                prod_id: localStorage.getItem("product_id"),
                imperative_id: parentId,
                milestone: title,
                milestone_id: isEdit ? milestoneId : false,
                status: status.value || "",
                start_date: startDate,
                due_date: duedate,
                priority: priority.value || "",
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];
            dispatch(
              postStrategyFunctions(
                milestone_data,
                "milestone",
                false,
                false,
                true
              )
            );
          }
        } else {
          Alert.warning({
            title: "Warning",
            text: "Milestone name should not be empty",
            html: ISError,
            icon: "warning",
            preConfirm: (isConfirm) => {
              if (isConfirm) {
                dispatch(resetExceptionError());
              } else {
              }
            },
          });
        }
      } else {
        if (title !== "") {
          if (isEdit) {
            setIsSidebarOpen(false);
            let assignedToList = [];
            if (Array.isArray(assignedTo)) {
              assignedToList = assignedTo;
            } else {
              assignedToList = [assignedTo];
            }
            const requiredAssignedData = empData.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const updatedAssignedData = empData.map((item) => ({
              ...item,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const selectedGoalsWithActive = data.goals_dt.map((goal) => ({
              key: goal.value,
              is_active: selectedGoals.some(
                (selectedGoal) => selectedGoal.value === goal.value
              ),
            }));
            const updatedGoalsData = data.goals_dt.map((goal) => ({
              ...goal,
              is_active: selectedGoals.some(
                (selectedGoal) => selectedGoal.value === goal.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const updatedCollaboratorsData = empData.map((item) => ({
              ...item,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            let functional_goal_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                product_id: localStorage.getItem("product_id"),
                function_id: parentId,
                imperative_name: title,
                imperative_id: isEdit ? functionalGoalId : false,
                selected_goal: selectedGoalsWithActive,
                status: "Open",
                due_date: duedate,
                priority: "High",
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];
            handleStrategicImpEdit(
              functionIndex,
              impIndex,
              functional_goal_data,
              updatedAssignedData,
              updatedGoalsData,
              updatedCollaboratorsData
            );
          } else {
            const assignedToList = [assignedTo];
            const requiredAssignedData = empData.map((item) => ({
              key: item.value,
              is_active: assignedToList.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            const selectedGoalsWithActive = goalData.map((goal) => ({
              key: goal.value,
              is_active: selectedGoals.some(
                (selectedGoal) => selectedGoal.value === goal.value
              ),
            }));
            const requiredCollaboratorsData = empData.map((item) => ({
              key: item.value,
              is_active: collaberators.some(
                (selectedItem) => selectedItem.value === item.value
              ),
            }));
            let functional_goal_data = [
              {
                bi_id: localStorage.getItem("bi_id"),
                product_id: localStorage.getItem("product_id"),
                function_id: parentId,
                imperative_name: title,
                imperative_id: isEdit ? functionalGoalId : false,
                selected_goal: selectedGoalsWithActive,
                status: "Open",
                due_date: duedate,
                priority: "High",
                assigned_to: requiredAssignedData,
                progress: progress,
                description: desc,
                collaberators: requiredCollaboratorsData,
                is_active: true,
              },
            ];
            dispatch(
              postStrategyFunctions(
                functional_goal_data,
                "functional_goal",
                false,
                true
              )
            );
          }
        } else {
          Alert.warning({
            title: "Warning",
            text: "Functional Goal name should not be empty",
            html: ISError,
            icon: "warning",
            preConfirm: (isConfirm) => {
              if (isConfirm) {
                dispatch(resetExceptionError());
              } else {
              }
            },
          });
        }
      }
      setIsSidebarOpen(false);
    }
  };

  const logo = createImageFromInitials(
    30,
    localStorage.getItem("full_name"),
    getRandomColor(localStorage.getItem("full_name"))
  );

  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
    setProgress(stepIndex * 20);
  };

  const handleGoalChange = (selectedOptions) => {
    setSelectedGoals(selectedOptions);
  };

  return (
    <div
      style={{
        width: fullScreenMode && "calc(100% - 50px)",
        zIndex: "100",
      }}
      className={"sidenav"}
    >
      <div
        className="gtm-sidebar-header"
        style={{
          borderBottom: "1px solid lightgrey",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: "1",
            minWidth: "70%",
            maxWidth: "80%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0",
            paddingTop: "4px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
            paddingLeft: "10px",
          }}
          title={breadcrumbs}
        >
          {breadcrumbs}
        </div>
        {!fullScreenMode ? (
          <>
            {type === "milestone" ? (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}
                {isMenuOpen && (
                  <div className="menu">
                    <div className="Edit">
                      <div
                        className="DeleteOption"
                        onClick={() => {
                          handleMilestoneDelete(
                            functionIndex,
                            impIndex,
                            milestoneIndex,
                            milestonedataId
                          );
                          onClose();
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          width={"18px"}
                          style={{ marginRight: "10px" }}
                          alt="Edit Icon"
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {(selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin") && (
                  <a href="#" className="sideMenu" onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </a>
                )}
                {isMenuOpen && (
                  <div className="menu">
                    <div className="Edit">
                      <div
                        className="DeleteOption"
                        onClick={() => {
                          handleStrategicImpDelete(
                            functionIndex,
                            impIndex,
                            impid
                          );
                          onClose();
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          width={"18px"}
                          style={{ marginRight: "10px" }}
                          alt="Edit Icon"
                        />
                        Delete
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <a href="#" className="fullscreen" onClick={handleFullScreen}>
              <AiOutlineCaretRight />
            </a>
          </>
        ) : (
          <>
            {(selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <a href="#" className="sideMenu" onClick={handleMenuClick}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </a>
            )}
            <a href="#" className="fullscreen" onClick={handleSideNav}>
              <AiOutlineCaretLeft />
            </a>
          </>
        )}
        <a href="#" className="closebtn" onClick={onClose}>
          &times;
        </a>
      </div>
      <div className="gtm-sidebar-body">
        <div>
          <input
            type="text"
            placeholder={placeholder}
            value={title}
            style={{
              marginLeft: "10px",
              width: "95%",
              border: "none",
              marginBottom: "10px",
              fontSize: "var(--task-title-font-size)",
            }}
            onChange={(e) => setTitle(e.target.value)}
            disabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          />
        </div>

        <div className="row">
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "20px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Owner
            </div>
            <Select
              required
              name="emp_id"
              styles={customStyles}
              className="select-owner"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Owner
                </span>
              }
              value={assignedTo}
              options={empData}
              onChange={handleOwnerChange}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img alt="" src={e.icon} />
                  <span
                    style={{ marginLeft: 5, fontSize: "var(--text-font-size)" }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>

          <div
            className="col-6"
            style={{
              display: type === "functional_goal" ? "flex" : "none",
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 999,
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                marginRight: "40px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Goals
            </div>
            <GoalSelector
              selectedGoals={selectedGoals}
              options={goalData}
              value={selectedGoals}
              handleGoalChange={handleGoalChange}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
          <div
            className="col-6"
            style={{
              display: type === "milestone" ? "flex" : "none",
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 999,
            }}
          >
            <div
              style={{
                marginRight: "20px",
                marginLeft: "10px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Status
            </div>
            <Select
              required
              styles={customStyles}
              className="status-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Status
                </span>
              }
              value={status}
              options={statusData}
              onChange={handleStatusSelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-6"
            style={{
              display: type === "milestone" ? "flex" : "none",
              alignItems: "center",
            }}
          >
            <span
              style={{
                marginLeft: "10px",
                marginRight: "25px",
                marginBottom: "10px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
                whiteSpace: "nowrap",
              }}
            >
              Start Date
            </span>
            <input
              style={{
                width: "250px",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
          <div
            className="col-6"
            style={{
              display: type === "milestone" ? "flex" : "none",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "25px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
                whiteSpace: "nowrap",
                marginBottom: "10px",
              }}
            >
              Due Date
            </div>
            <input
              style={{
                width: "250px",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control"
              value={duedate}
              onChange={(e) => setDuedate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>

        <div
          className="row"
          style={{ display: type === "milestone" ? "flex" : "none" }}
        >
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 999,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "40px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Priority
            </div>
            <Select
              required
              styles={customStyles}
              className="priority-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select priority
                </span>
              }
              value={priority}
              options={priorityData}
              onChange={handlePrioritySelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>

        {!progressHide && (
          <div className="row">
            <div
              className="col-12"
              style={{
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  marginLeft: "10px",
                  marginRight: "20px",
                  marginBottom: 0,
                }}
              >
                Progress
              </h6>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "15px",
                  marginRight: "10px",
                }}
              >
                <StepProgressBar
                  steps={steps}
                  currentStep={currentStep}
                  onStepClick={handleStepClick}
                  selectedSubMenuPermission={selectedSubMenuPermission}
                />
                <div className="step-percentage">
                  {(currentStep / (steps.length - 1)) * 100}%
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            marginBottom: "0px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
            marginLeft: "10px",
          }}
        >
          Description
        </div>
        <div style={{ margin: "10px 10px 30px 10px", height: "300px" }}>
          <QuillEditor
            value={desc}
            onChange={handleDescOnChange}
            height={"300px"}
          />
        </div>
        <br />
        {/* <div style={{ display: "flex", width: "100%" }}>
          <h6
            style={{
              marginLeft: "20px",
              marginBottom: "0px",
              display: "flex",
              alignItems: "center",
              paddingRight: "10px",
            }}
          >
            Collaborators
          </h6>

          <Select
            required
            name="emp_id"
            isMulti
            className="select-collaborator"
            hideSelectedOptions
            allowSelectAll
            styles={customStyles}
            filterOption={customFilter}
            placeholder="Select"
            value={collaberators}
            options={empData}
            onChange={handleCollaboratorChange}
            isSearchable={true}
            getOptionLabel={(e) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img alt="" src={e.icon} />
                <span style={{ marginLeft: 5 }}>{e.label}</span>
              </div>
            )}
            menuPlacement="top"
            isDisabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          />
        </div> */}
        {impid && (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={impid}
              attachments={isEdit ? data.attachment : ""}
            />
          </>
        )}
        {milestonedataId && (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={milestonedataId}
              attachments={isEdit ? data.attachment : ""}
            />
          </>
        )}

      </div>
      <div>
        <div>
          <Button
            type="button"
            style={{
              position: "absolute",
              top: "8px",
              right: "150px",
              padding: "4px 10px",
              backgroundColor: "var(--button-primary-color)",
              fontSize: "var(--sub-heading-font-size)",
              border: "1px solid var(--button-primary-color)",
            }}
            onClick={() => handleSave()}
            disabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default StrategyPlanSidebar;
