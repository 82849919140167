import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "../MainComponent";
// import AnsoffMatrixContainer from "./AnsoffMatrixContainer";
// import { Link } from "react-router-dom";
import RightElement from "./AnsoffMatrix/rightElement";
import LeftElement from "./AnsoffMatrix/leftElement";
import // productAxis,
//marketAxis,
// gridData,
// leftGridData,
"./AnsoffMatrix/ansoffMatrix";
import { useNavigate } from "react-router-dom";
import "./AnsoffMatrix.css";
import {
  getAnsoffMatrixDetails,
  getAnsoffMatrixGridDetails,
  postAnsoffMatrixDetails,
} from "../../store/actions";
import HelpInfo from "../../utils/helpInfoProviderComponent";
import keys from "../../utils/helpInfoKeys.json";
import CommentSection from "../../utils/CommentSection";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";
function AnsoffMatrix() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const [currentDocURL, setCurrentDocURL] = useState("");

  const [selectedProduct, setSelectedProduct] = useState(null);
  // const [defaultLeftGridData, setDefaultLeftGridData] = useState(
  //   JSON.parse(JSON.stringify(leftGridData))
  // );
  const [defaultLeftGridData, setDefaultLeftGridData] = useState([]);
  const [leftPanelQuestions, setLeftPanelQuestions] =
    useState(defaultLeftGridData);
  const [yAxisData, setYAxisData] = useState(null);
  const [xAxisData, setXAxisData] = useState(null);
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [graphGridData, setGraphGridData] = useState([]);
  const [productAxis, setProductAxis] = useState([]);
  const [marketAxis, setMarketAxis] = useState([]);
  const [productsChildElements, setProductsChildElements] = useState([]);
  const [marketChildElements, setMarketChildElements] = useState([]);
  const [leftPanelData, setLeftPanelData] = useState([]);

  const [leftGridData, setLeftGridData] = useState([]);
  const [gridData, setGridData] = useState([]);

  const screen_id = useSelector((state) => state.screen_id);
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);

  const getAnsoffMatrixData = useSelector((state) => state.getAnsoffMatrixData);
  const isAnsoffMatrixDetailsFetched = useSelector(
    (state) => state.isAnsoffMatrixDetailsFetched
  );
  const fetchAnsoffMatrixGridDetails = useSelector(
    (state) => state.fetchAnsoffMatrixGridDetails
  );

  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);

  useEffect(() => {
    dispatch(
      getAnsoffMatrixGridDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
  }, []);

  useEffect(() => {
    if (fetchAnsoffMatrixGridDetails) {
      setMarketAxis(fetchAnsoffMatrixGridDetails.marketAxis);
      setProductAxis(fetchAnsoffMatrixGridDetails.productAxis);
      setGridData(fetchAnsoffMatrixGridDetails.gridData);
      setLeftGridData(fetchAnsoffMatrixGridDetails.leftGridData);
      setProductsChildElements(
        fetchAnsoffMatrixGridDetails.productsChildElements
      );
      setMarketChildElements(fetchAnsoffMatrixGridDetails.marketChildElements);
      setDefaultLeftGridData(
        JSON.parse(JSON.stringify(fetchAnsoffMatrixGridDetails.leftGridData))
      );
      setLeftPanelQuestions(
        JSON.parse(JSON.stringify(fetchAnsoffMatrixGridDetails.leftGridData))
      );
      //Get y-axis data
      generateFinalArray(fetchAnsoffMatrixGridDetails.marketAxis, "y");
      //Get x-axis data
      generateFinalArray(fetchAnsoffMatrixGridDetails.productAxis);

      let graphChildData = sortData(
        fetchAnsoffMatrixGridDetails.gridData,
        fetchAnsoffMatrixGridDetails.marketChildElements,
        fetchAnsoffMatrixGridDetails.productsChildElements
      );

      //Local temp data
      let localData = [];

      //Previous total colSpan value
      let previousTotalColValue = 0;

      for (
        let i = 0;
        i < fetchAnsoffMatrixGridDetails.marketChildElements.length;
        i++
      ) {
        let marketId = fetchAnsoffMatrixGridDetails.marketChildElements[i].key;
        //Reset previous total colSpan value
        previousTotalColValue = 0;

        let tempChildArr = graphChildData.filter((list) => {
          if (list.market_axis_id === marketId) {
            let colIndex =
              fetchAnsoffMatrixGridDetails.productsChildElements.findIndex(
                (el) => el.key === list.product_axis_id
              ) + 1;

            list.colSpan =
              colIndex < 2 ? "" : (colIndex - previousTotalColValue).toString();
            list.rowSpan = "";
            previousTotalColValue += colIndex;

            return list;
          }
        });

        if (tempChildArr.length > 0) {
          //Update local temp data
          localData.push({
            key: fetchAnsoffMatrixGridDetails.marketChildElements[i].key,
            name: fetchAnsoffMatrixGridDetails.marketChildElements[i].value,
            child: tempChildArr,
          });
        }
      }

      /**
       * Local data reverse because
       * */
      let finalGraphData = localData.map((items, idx) => {
        //Child items data
        let currentRecords = items.child;

        //Calculate total sum value of colSpan
        let currentTotalColValue = currentRecords
          .map((el) => {
            return el.colSpan === "" ? 1 : parseInt(el.colSpan);
          })
          .reduce((a, b) => a + b);

        if (
          previousTotalColValue < currentTotalColValue &&
          previousTotalColValue <
            fetchAnsoffMatrixGridDetails.productsChildElements.length
        ) {
          currentRecords.forEach((list, index) => {
            if (
              index + 1 > previousTotalColValue ||
              index + 1 === currentRecords.length
            ) {
              let rowIndex = idx + 1;
              list.rowSpan = rowIndex < 2 ? "" : rowIndex.toString();
            } else {
              list.rowSpan = "";
            }
          });
        }

        //Update previous total colSpan value with current colSpan value
        previousTotalColValue = currentTotalColValue;

        return items;
      });

      /**
       * Final data for Ansoff matrix graph
       * reverse() - data should be in top to bottom drawable manner, because we're using table properties
       * */
      if (finalGraphData.length > 0) {
        setGraphGridData([...finalGraphData].reverse());
      }
    }
  }, [fetchAnsoffMatrixGridDetails]);

  // generic comparison function
  const compareElement = (x, y) => {
    return x > y ? 1 : x < y ? -1 : 0;
  };
  const handleBack = () => {
    navigate("/esmapping", { replace: true });
  };

  const handleUpdatedQuestions = (updatedLeftElementData) => {
    setLeftPanelQuestions(updatedLeftElementData);
  };

  const handleClick = () => {
    const childList = graphGridData.reduce((result, item) => {
      item.child.forEach((child) => {
        result.push({
          key: child.key,
          value: child.value,
          isselected: child.isselected,
        });
      });
      return result;
    }, []);
    if (selectedPermission === "write" || selectedPermission === "admin") {
      dispatch(
        postAnsoffMatrixDetails(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id"),
          leftPanelQuestions,
          childList
        )
      );
    }

    const extractedXAxisData = xAxisData.reduce((result, group) => {
      group.data.forEach((item) => {
        result.push({
          key: item.key,
          value: item.value,
          isselected: item.isselected,
        });
      });
      return result;
    }, []);
    const extractedYAxisData = yAxisData.reduce((result, group) => {
      group.data.forEach((item) => {
        result.push({
          key: item.key,
          value: item.value,
          isselected: item.isselected,
        });
      });
      return result;
    }, []);

    // console.log("extractedXAxisData", extractedXAxisData);
    // console.log("extractedYAxisData", extractedYAxisData);
    navigate("/vcselection", { replace: true });
  };

  // generic comparison function
  const sortData = (dataArray, marketData, productData) => {
    return dataArray.sort((a, b) => {
      const marketIndexA = marketData.findIndex(
        (item) => item.key === a.market_axis_id
      );
      const marketIndexB = marketData.findIndex(
        (item) => item.key === b.market_axis_id
      );

      const productIndexA = productData.findIndex(
        (item) => item.key === a.product_axis_id
      );
      const productIndexB = productData.findIndex(
        (item) => item.key === b.product_axis_id
      );

      const combinedIndexA = Math.min(marketIndexA, productIndexA);
      const combinedIndexB = Math.min(marketIndexB, productIndexB);

      return combinedIndexA - combinedIndexB;
    });
  };

  /**
   * Generate X & Y axis data including rowSpan & colSpan based on the children elements
   * */
  const generateFinalArray = (data, axisType = "x") => {
    const finalArray = [];

    const traverse = (nodes, depth) => {
      const rowSpans = {};
      const colSpans = {};
      let previousNodeDetails = null;

      nodes.forEach((node) => {
        if (!rowSpans[depth]) {
          rowSpans[depth] = 0;
        }
        if (!colSpans[depth]) {
          colSpans[depth] = 0;
        }

        const rowSpan = depth === 1 ? calculateTotalLeafNodes(node) : "1";
        const colSpan =
          colSpans[depth] === 0 ? calculateTotalLeafNodes(node) : "1";

        const item = {
          ...node,
          rowSpan: String(rowSpan),
          colSpan: String(colSpan),
        };
        if (!finalArray[depth - 1]) {
          if (node.children.length > 0) item.rowSpan = "1";

          finalArray[depth - 1] = { depth, data: [item] };
        } else {
          if (node.children.length === 0) {
            item.rowSpan = previousNodeDetails?.children
              ? calculateTotalLeafNodes(previousNodeDetails).toString()
              : "1";
          } else {
            item.rowSpan = "1";
          }
          finalArray[depth - 1].data.push(item);
        }
        previousNodeDetails = node;
        rowSpans[depth] += 1;
        colSpans[depth] += 1;

        if (node.children && node.children.length > 0) {
          traverse(node.children, depth + 1);
          rowSpans[depth] += rowSpans[depth + 1];
          colSpans[depth] = Math.max(colSpans[depth], colSpans[depth + 1]);
        }
      });
    };

    const calculateTotalLeafNodes = (node) => {
      if (!node.children || node.children.length === 0) {
        return 1;
      }

      let count = 0;
      node.children.forEach((child) => {
        count += calculateTotalLeafNodes(child);
      });
      return count;
    };

    traverse(data, 1);

    if (axisType === "y") setYAxisData([...finalArray]);
    else {
      let localXAxis = finalArray
        .map((entry) => ({
          depth: entry.depth.toString(),
          data: entry.data.map((item) => ({
            ...item,
            rowSpan: item.rowSpan.toString(),
            colSpan: item.colSpan.toString(),
          })),
        }))
        .reverse();

      let localTemp = [];
      let previousColSpanValue = 1;
      for (let i = 0; i < localXAxis.length; i++) {
        let activeData = localXAxis[i].data;
        let currentColSpanValue = 1;
        for (let j = 0; j < activeData.length; j++) {
          let activeDataElement = activeData[j];
          let rowSpanValue = parseInt(activeDataElement.rowSpan);
          currentColSpanValue += parseInt(activeDataElement.colSpan);
          if (rowSpanValue > 1) {
            let rightIndexPosition =
              localXAxis.length - activeDataElement.rowSpan;
            try {
              let finalIndexPosition =
                currentColSpanValue > previousColSpanValue
                  ? rightIndexPosition - 1
                  : rightIndexPosition;
              localXAxis[
                finalIndexPosition > 0 ? finalIndexPosition : 0
              ].data.push(activeDataElement);
            } catch (e) {
              localXAxis[rightIndexPosition].data.push(activeDataElement);
            }
            activeData.splice(j, 1);
          }
        }
        previousColSpanValue = currentColSpanValue;
      }

      setXAxisData([...localXAxis]);
    }
  };

  const _resetAxisClickEvents = (type = "both") => {
    let localDataXAxis = xAxisData.map((el) => {
      el.data.map((items) => {
        items.isselected = false;
        if (items.children.length > 0) {
          items.children.map((childItems) => {
            childItems.isselected = false;
            return childItems;
          });
        }
        return items;
      });
      return el;
    });
    let localDataYAxis = yAxisData.map((el) => {
      el.data.map((items) => {
        items.isselected = false;
        if (items.children.length > 0) {
          items.children.map((childItems) => {
            childItems.isselected = false;
            return childItems;
          });
        }
        return items;
      });
      return el;
    });
    if (type === "X") setXAxisData([...localDataXAxis]);
    if (type === "Y") setYAxisData([...localDataYAxis]);
    else {
      setXAxisData([...localDataXAxis]);
      setYAxisData([...localDataYAxis]);
    }
  };

  /**
   * Update axis data in onclick events
   * */
  const _updateAxisElementData = (optionDetails, axisType = null) => {
    if (axisType === null || optionDetails.isselected) {
      _resetAxisClickEvents();
      if (axisType !== null) {
        setLeftPanelQuestions([...defaultLeftGridData]);
        _onChangeActionHandler(
          { ...optionDetails, key: "", keyArray: [] },
          false,
          defaultLeftGridData
        );
      }
    } else {
      if (axisType === "X") {
        _resetAxisClickEvents("Y");
        let localData = xAxisData.map((el) => {
          el.data.map((items) => {
            items.isselected = items.key === optionDetails.key;
            if (items.children.length > 0) {
              items.children.map((childItems) => {
                childItems.isselected = childItems.key === optionDetails.key;
                return childItems;
              });
            }
            return items;
          });
          return el;
        });
        setXAxisData([...localData]);
      } else {
        let localData = yAxisData.map((el) => {
          el.data.map((items) => {
            items.isselected = items.key === optionDetails.key;
            if (items.children.length > 0) {
              items.children.map((childItems) => {
                childItems.isselected = childItems.key === optionDetails.key;
                return childItems;
              });
            }
            return items;
          });
          return el;
        });
        setYAxisData([...localData]);
      }
      _onChangeActionHandler(optionDetails, true);
    }
  };

  /**
   * Action handler for -
   * x & y axis options onclick events
   * leftElements onclick events
   * */
  const _onChangeActionHandler = (
    optionDetails,
    isReverseCheck,
    updatedQuestionsData = leftPanelQuestions
  ) => {
    if (isReverseCheck) {
      let localData = leftPanelQuestions.map((el) => {
        if (el.isProduct === optionDetails.isProduct) {
          el.options.map((list) => {
            list.isactive = list.keyArray.some((element) =>
              [optionDetails.key].includes(element)
            );
            return list;
          });
        } else {
          el.options.map((list) => {
            if (selectedProduct !== null)
              list.isactive = list.keyArray.some((element) =>
                [selectedProduct.key].includes(element)
              );
            else list.isactive = false;
            return list;
          });
        }
        return el;
      });
      setLeftPanelQuestions([...localData]);
    }

    let updatedData = graphGridData.map((el) => {
      el.child.map((items) => {
        let marketValidationCheck =
          items.keyArray.length > 0 ? items.keyArray : [items.market_axis_id];
        let productValidationCheck =
          items.keyArray.length > 0 ? items.keyArray : [items.product_axis_id];
        items.isselected = "";
        let prevActiveProduct = optionDetails.isProduct
          ? null
          : selectedProduct;
        updatedQuestionsData.forEach((quest) => {
          if (quest.ans_type === "Checkbox") {
            if (quest.isProduct) {
              let keyArray = [];
              quest.options.forEach((opt) => {
                if (opt.isactive) {
                  keyArray.push(opt.keyArray);
                  items.isselected = keyArray.some((element) =>
                    productValidationCheck.includes(element)
                  );
                  prevActiveProduct = opt;
                }
              });
              setSelectedProduct(prevActiveProduct);
            } else {
              let keyArray = [];
              quest.options.forEach((opt) => {
                if (opt.isactive) {
                  keyArray.push(...opt.keyArray);
                  if (selectedProduct !== null)
                    items.isselected =
                      keyArray.some((element) =>
                        marketValidationCheck.includes(element)
                      ) &&
                      selectedProduct.keyArray.some((element) =>
                        productValidationCheck.includes(element)
                      );
                  else
                    items.isselected = keyArray.some((element) =>
                      marketValidationCheck.includes(element)
                    );
                }
              });
            }
          } else {
            if (quest.isProduct) {
              quest.options.forEach((opt) => {
                if (opt.isactive) {
                  items.isselected = opt.keyArray.some((element) =>
                    productValidationCheck.includes(element)
                  );
                  prevActiveProduct = opt;
                }
              });
              setSelectedProduct(prevActiveProduct);
            } else {
              quest.options.forEach((opt) => {
                if (opt.isactive) {
                  if (selectedProduct !== null)
                    items.isselected =
                      opt.keyArray.some((element) =>
                        marketValidationCheck.includes(element)
                      ) &&
                      selectedProduct.keyArray.some((element) =>
                        productValidationCheck.includes(element)
                      );
                  else
                    items.isselected = opt.keyArray.some((element) =>
                      marketValidationCheck.includes(element)
                    );
                }
              });
            }
          }
          return items;
        });
      });
      return el;
    });
    setGraphGridData([...updatedData]);
  };

  /**
   * Action handler for -
   * graph section onclick events
   * */
  const _onClickGraphActionHandler = (items) => {
    setSelectedProduct(null);
    let marketValidationCheck =
      items.keyArray.length > 0 ? items.keyArray : [items.market_axis_id];
    let productValidationCheck =
      items.keyArray.length > 0 ? items.keyArray : [items.product_axis_id];

    let data = defaultLeftGridData.map((el) => {
      if (el.isProduct) {
        el.options.map((optionDetails) => {
          optionDetails.isactive = optionDetails.keyArray.some((element) =>
            productValidationCheck.includes(element)
          );
          return optionDetails;
        });
      } else {
        el.options.map((optionDetails) => {
          optionDetails.isactive = optionDetails.keyArray.some((element) =>
            marketValidationCheck.includes(element)
          );
          return optionDetails;
        });
      }
      return el;
    });

    let selectedGraphValue = graphGridData.map((el) => {
      el.child.map((list) => {
        list.isselected = list.key === items.key;
        return list;
      });
      return el;
    });
    setGraphGridData([...selectedGraphValue]);
    setLeftPanelQuestions([...data]);
  };

  const handleTemplateClick = () => {
    dispatch({ type: "SET_OLD_SCREEN_ID", payload: screen_id });
  };

  const content = (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          fontSize: "var(--main-heading)",
          fontWeight: "var(--heading-font-weight)",
        }}
      >
        XL Strategy Matrix
      </div>
      {/* <Link
        to="/templates"
        style={{ textDecoration: "none" }}
        onClick={handleTemplateClick}
      >
        <p style={{ fontSize: 15 }}>Go To Ansoff Template</p>
      </Link> */}
      <br />
      <div className="overflow">
        <LeftElement
          questionsData={leftPanelQuestions}
          _onChangeActionHandler={_onChangeActionHandler}
          _updateAxisElementData={_updateAxisElementData}
          handleUpdatedQuestions={handleUpdatedQuestions}
        />
        <div className="ansoffmatrix-display-container">
          <div style={{ width: 380 }}>
            <div
              style={{
                rotate: "-90deg",
                position: "relative",
                top: "166px",
                right: 105,
                zIndex: 0,
              }}
            >
              <table>
                <thead>
                  {yAxisData !== null &&
                    yAxisData.map((list) => (
                      <tr
                        style={{
                          textAlign: "center",
                          fontSize: "var(--captions-font-size)",
                        }}
                      >
                        {list.data.map((el) => (
                          <td
                            rowSpan={el.rowSpan}
                            colSpan={el.colSpan}
                            style={{
                              border: "1px solid #EAEAEA",
                              borderBottom: 0,
                              width: 100 * parseInt(el.colSpan),
                              cursor: "pointer",
                              backgroundColor: el.isselected
                                ? "rgb(151 245 187)"
                                : "",
                              // color: el.isselected ? "white" : "black",
                            }}
                            onClick={() => {
                              _updateAxisElementData(
                                { ...el, isProduct: false },
                                "Y"
                              );
                            }}
                          >
                            {el.value}
                          </td>
                        ))}
                      </tr>
                    ))}
                </thead>
              </table>
            </div>
            <RightElement
              xAxisData={xAxisData}
              data={graphGridData}
              _onClickGraphActionHandler={_onClickGraphActionHandler}
              _updateAxisElementData={_updateAxisElementData}
            />
          </div>
        </div>
      </div>
      <CommentSection />
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
    </div>
  );

  const rcontent = (
    <div style={{ height: "100%", width: "100%" }}>
      {currentDocURL && (
        <>
          <a
            style={{
              float: "right",
              marginBottom: 5,
              textDecoration: "none",
              border: 0,
              padding: 5,
              borderRadius: 10,
              background: "#0A5F59",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            target="_blank"
            href={currentDocURL}
          >
            Popout
          </a>
          <iframe height="100%" width="100%" src={currentDocURL} />
        </>
      )}
    </div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        // showTemplateButton={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        showProducts={true}
        breadcrumbParent={"Onboarding"}
        breadcrumbChild={"XL Strategy Matrix"}
        documentName={"XL Strategy Matrix"}
        helpKey={keys["XL Strategy Matrix"].Name}
        image={"question"}
      />
    );
  }
}

export default AnsoffMatrix;
