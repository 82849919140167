import React from "react";
import "./MaintenancePage.css";
import Maintentance from "../components/assets/images/maintenance.gif";
const MaintenancePage = () => {
  return (
    <>
      <div className="maintenance-image-container">
        <img src={Maintentance} />
        <div className="maintenance-title">Site is under maintenance</div>
        <div className="maintenance-desc">
          We're working hard to improve the user experience. Stay tuned!
        </div>
      </div>
    </>
  );
};

export default MaintenancePage;
