import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Objective from "../../assets/images/Objective.svg";
import Goals from "../../assets/images/Goals.svg";
import Up from "../../assets/images/up.svg";
import Down from "../../assets/images/down.svg";
import ChevronLeft from "../../assets/images/Chevron-left.svg";
import ChevronRight from "../../assets/images/Chevron-right.svg";
import GoalSelector from "./GoalSelector";
import FunctionalGoalMeeting from "./FunctionalGoalMeeting";
import { BiChevronDown } from "react-icons/bi";
import keys from "../../utils/helpInfoKeys.json";
import {
  getBiFGoalStatus,
  getFunctionalGoalReviewMeetingData,
  getInitiativeTrackerDetails,
} from "../../store/actions";
import { url } from "../../store/sagas/API.js";

import {
  getRandomColor,
  createImageFromInitials,
  createProgressBar,
  createStatus,
} from "../Utils";
import UserProfile from "../../assets/images/user-profile.svg";

import "./XLExecutionPlan.css";
import "./InitiativeTracker.css";
import CommentSection from "../../utils/CommentSection.js";
import Login from "../Login.js";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo.js";

function InitiativeTracker() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [objectiveList, setObjectiveList] = useState([]);
  const [goalList, setGoalList] = useState([]);
  const [functionList, setFunctionList] = useState([]);
  const [momentumScore, setMomentumScore] = useState();
  const selectedBI = useSelector((state) => state.selectedBI);
  const [showMeetingPopup, setShowMeetingPopup] = useState(false);
  const [singleGoal, setSingleGoal] = useState(false);
  const [status, setStatus] = useState("");
  const getInitTrackerDetails = useSelector(
    (state) => state.getInitTrackerDetails
  );
  const [biName, setBIName] = useState(null);
  const fetchGetBiFStatusData = useSelector(
    (state) => state.fetchGetBiFStatusData
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(4);
  useEffect(() => {
    if (fetchGetBiFStatusData && fetchGetBiFStatusData.data) {
      console.log("data", fetchGetBiFStatusData.data);
      const data = fetchGetBiFStatusData.data[0]?.value;
      setStatus(data);
    }
  }, [fetchGetBiFStatusData]);
  useEffect(() => {
    dispatch(
      getInitiativeTrackerDetails(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id")
      )
    );
    dispatch(
      getBiFGoalStatus(
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        localStorage.getItem("functional_goal_id")
      )
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1048) {
        setCardsPerPage(2); // For small screens and tablets
      } else {
        setCardsPerPage(4); // For desktop
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial setup based on screen width
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalPages = Math.ceil(functionList.length / cardsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handleSelectFunction = (e) => {
    localStorage.setItem("function_id", e.value);
    localStorage.setItem("function_value", e.label);
    setSelectedFunction(e);
  };

  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  useEffect(() => {
    if (getInitTrackerDetails) {
      setObjectiveList(getInitTrackerDetails.obj_data);
      setGoalList(getInitTrackerDetails.goal_data);
      setFunctionList(getInitTrackerDetails.intiative_data);
    }
  }, [getInitTrackerDetails]);
  useEffect(() => {
    if (objectiveList) {
      const data = objectiveList.map((item) => {
        setMomentumScore(item.momentum_score);
      });
    }
  }, [objectiveList]);

  useEffect(() => {
    if (goalList.length === 1) {
      setSingleGoal(true);
    } else {
      setSingleGoal(false);
    }
  }, [goalList]);

  useEffect(() => {
    if (selectedBI) {
      setBIName(selectedBI.label);
    }
  }, [selectedBI]);

  const handleFunctionalGoalClick = (functional_goal_id) => {
    localStorage.setItem("functional_goal_id", functional_goal_id);
    dispatch(
      getFunctionalGoalReviewMeetingData(
        functional_goal_id,
        localStorage.getItem("bi_id")
      )
    );
    setShowMeetingPopup(true);
    // navigate("/executionplan");
  };

  const handleCloseMeetingPopup = () => {
    setShowMeetingPopup(false);
  };

  const handleClick = () => {
    navigate("/dashboard");
  };
  const handleBack = () => {
    navigate("/workboard");
  };
  const content = (
    <>
      {showMeetingPopup && (
        <FunctionalGoalMeeting onClose={handleCloseMeetingPopup} />
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            paddingRight: "10px",
            fontSize: "var(--main-heading)",
            display: "flex",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          {biName}
        </div>
        <div style={{ fontSize: "var(--sub-heading-font-size)" }}>
          Overall Progress:
        </div>
        <div className="momentum-score">{momentumScore}</div>
      </div>
      <br />
      <div>
        {objectiveList.map((item, index) => (
          <>
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  paddingRight: "10px",
                }}
              >
                <div className="Objective" style={{ wordWrap: "break-word" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      paddingTop: "10px",
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    <img
                      alt="objective"
                      src={Objective}
                      width={"24px"}
                      style={{ marginLeft: "10px" }}
                    />
                    <span style={{ paddingLeft: "5px" }}>Objective</span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      className="ObjectiveData"
                      style={{
                        width: "100%",
                        paddingBottom: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        wordWrap: "break-word",
                        textOverflow: "ellipsis",
                        transition: "text-overflow 0.3s",
                        fontSize: "var(--sub-heading-font-size)",
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {goalList && goalList.length > 0 && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {goalList.map((item, index) => (
                  <div
                    key={item.id}
                    className="goal col-lg-3"
                    style={{ width: singleGoal ? "100%" : "49%" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ wordWrap: "break-word" }}>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            alt="goal"
                            src={Goals}
                            width={"24px"}
                            style={{ marginLeft: "10px" }}
                          />
                          <span
                            style={{
                              paddingLeft: "5px",
                              fontSize: "var(--sub-heading-font-size)",
                            }}
                          >
                            Goal ({item.short_name})
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div
                          className="ObjectiveData"
                          style={{
                            width: "70%",
                            paddingBottom: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            wordWrap: "break-word",
                            textOverflow: "ellipsis",
                            transition: "text-overflow 0.3s",
                            fontSize: "var(--sub-heading-font-size)",
                          }}
                        >
                          {item.name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {functionList && functionList.length > 0 ? (
              <>
                <div className="card-container">
                  {functionList && functionList.length > 0 && (
                    <div className="rows">
                      {functionList
                        .slice(startIndex, endIndex)
                        .map((item, index) => (
                          <div key={item.id} className="card-wrapper">
                            <div className="cards">
                              <div className="card-body">
                                {/* logo */}
                                <div>
                                  <img alt="icon" src={url + item.icon} />
                                </div>
                                {/*logo close  */}
                                <div>
                                  <p className="card-title">{item.name}</p>

                                  {/* <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "5px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <span style={{ fontSize: "11px" }}>
                                      MOMENTUM SCORE:
                                    </span>
                                    {item.momentum_score !== undefined ? (
                                      <span className="momentum-score">
                                        {item.momentum_score > 50 ? (
                                          <div>
                                            <span
                                              style={{
                                                color: "#0A5F59",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item.momentum_score}
                                            </span>
                                            <img
                                              alt="up"
                                              style={{ marginLeft: "10px" }}
                                              src={Up}
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <span
                                              style={{
                                                color: "#EF2C2C",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item.momentum_score}
                                            </span>
                                            <img
                                              alt="down"
                                              style={{ marginLeft: "10px" }}
                                              src={Down}
                                            />
                                          </div>
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div> */}
                                </div>
                                <div>
                                  {item.assigned_to &&
                                  item.assigned_to.length > 0 &&
                                  item.assigned_to.find((j) => j.is_active) ? (
                                    <img
                                      style={{ margin: "10px" }}
                                      alt=""
                                      src={createImageFromInitials(
                                        30,
                                        item.assigned_to.find(
                                          (j) => j.is_active
                                        ).label,
                                        getRandomColor(
                                          item.assigned_to.find(
                                            (j) => j.is_active
                                          ).label
                                        )
                                      )}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src={UserProfile}
                                      style={{ margin: "10px" }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* strategic imperative */}
                            {item.strategic_imperative.map((imperative) => (
                              <div
                                key={imperative.id}
                                className="strategic_card"
                              >
                                <div
                                  className="sub_strategic_card"
                                  onClick={(e) =>
                                    handleFunctionalGoalClick(imperative.id)
                                  }
                                >
                                  <div className="stratgic_card_title">
                                    {imperative.name}
                                  </div>
                                  <div>
                                    {imperative.assigned_to.length > 0 &&
                                    imperative.assigned_to.find(
                                      (item) => item.is_active
                                    ) ? (
                                      <img
                                        style={{ margin: "10px" }}
                                        alt="owner"
                                        src={createImageFromInitials(
                                          30,
                                          imperative.assigned_to.find(
                                            (item) => item.is_active
                                          ).label,
                                          getRandomColor(
                                            imperative.assigned_to.find(
                                              (item) => item.is_active
                                            ).label
                                          )
                                        )}
                                      />
                                    ) : (
                                      <img
                                        alt="userprofile"
                                        src={UserProfile}
                                        style={{ margin: "10px" }}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  <div>
                                    {imperative.status_name &&
                                      createStatus(imperative.status_name)}
                                  </div>

                                  <div
                                    className="selected-goal"
                                    style={{
                                      display: "flex",
                                      marginRight: "15px",
                                      alignItems: "center",
                                      fontSize: "var(--sub-heading-font-size)",
                                    }}
                                  >
                                    {imperative.goals_dt &&
                                      imperative.goals_dt.map(
                                        (goal, index) =>
                                          goal.is_active && (
                                            <div
                                              key={index}
                                              style={{
                                                paddingLeft: "5px",
                                                fontSize:
                                                  "var(--sub-heading-font-size)",
                                              }}
                                            >
                                              {goal.label}
                                            </div>
                                          )
                                      )}
                                    <span style={{ paddingLeft: "5px" }}>
                                      <BiChevronDown />
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  {createProgressBar(imperative.status_name)}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="pagination">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <span style={{ opacity: currentPage == 1 ? 0.3 : 1 }}>
                      <img alt="chevronleft" src={ChevronLeft} width={25} />
                    </span>
                  </button>
                  <span style={{ margin: "0px 5px" }}>
                    {currentPage} / {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <span
                      style={{ opacity: currentPage == totalPages ? 0.3 : 1 }}
                    >
                      <img alt="chevronright" src={ChevronRight} width={25} />
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <p>There is no functions mapped.</p>
            )}
          </>
        ))}
      </div>
      <div>
        <CommentSection />
        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      </div>
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          // showTemplate={true}
          showSelector={true}
          showProducts={true}
          showButtons={true}
          handleNext={handleClick}
          handleBackButton={handleBack}
          breadcrumbParent={"GTM Execution"}
          breadcrumbChild={"Initiative Tracker"}
          documentName={"Initiative Tracker"}
          helpKey={keys["Initiative Tracker"].Name}
          image={"question"}
        />
      </>
    );
  }
}

export default InitiativeTracker;
