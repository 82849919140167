import Swal from "sweetalert2";
import "./Alert.css";

var Alert = (function (window, undefined) {
  var module = {};
  var options = {
    title: "Warning",
    text: "Default Text",
    allowEscapeKey: true,
    customClass: "",
    allowOutsideClick: false,
    showCancelButton: false,
    showConfirmButton: true,
    confirmButtonText: "OK",
    confirmButtonColor: "#0A5F59",
    cancelButtonText: "Cancel",
    cancelButtonColor: "white",
    imageUrl: null,
    imageHeight: 40,
    imageWidth: 40,
    timer: null,
    html: "",
    inputPlaceholder: "",
    inputValue: null,
    showLoaderOnConfirm: false,
  };
  module.input = function (op) {
    show(op);
  };
  module.success = function (op) {
    op = check_string(op);
    show(op);
  };
  module.info = function (op) {
    op = check_string(op);
    show(op);
  };
  module.warning = function (op) {
    op = check_string(op);
    show(op);
  };
  module.error = function (op) {
    op = check_string(op);
    show(op);
  };
  module.input = function (op) {
    op = check_string(op);
    show(op);
  };
  var show = function (op) {
    var clone = Object.assign({}, options); // Deep cloning of options
    fixBootstrapModal();
    op = Object.assign(clone, op);
    if (op.callback !== undefined) {
      Swal.fire(op).then(op.callback);
    } else {
      Swal.fire(op);
    }
  };
  function fixBootstrapModal() {
    var modalNode = document.querySelector('.modal[tabindex="-1"]');
    if (!modalNode) return;
    modalNode.removeAttribute("tabindex");
    modalNode.classList.add("js-swal-fixed");
  }
  var check_string = function (op) {
    if (typeof op === "string") {
      return { text: op };
    } else {
      return op;
    }
  };
  return module;
})(window);

export default Alert;
