import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";

import {
  postFunGoalDependencies,
  postFunGoalKeyDecisions,
} from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { resetExceptionError } from "../../store/actions/index.js";
import Alert from "../Alert.js";

const ConstraintsPopUp = ({
  isEdit,
  isTaskSidebarOpen,
  setIsTaskSidebarOpen,
  setIsEdit,
  sidebarData,
  setConstraintsDisplay,
  setDependencyData,
  dependencyData,
}) => {
  const dispatch = useDispatch();
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const ISError = useSelector((state) => state.ISError);
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    status: null,
  });
  useEffect(() => {
    if (sidebarData && isEdit) {
      console.log(sidebarData);
      setSelectedId(sidebarData.key);
      setFormData({
        title: sidebarData.title || "",
        description: sidebarData.value || "",
        date: sidebarData.date_time || "",
        status: sidebarData.status || "",
      });
    }
  }, [sidebarData]);

  const statusData = [
    {
      label: "Significant Risk/Late",
      value: "Significant Risk/Late",
    },
    {
      label: "At risk",
      value: "At risk",
    },
    {
      label: "On Track",
      value: "On Track",
    },
    {
      label: "Complete",
      value: "Complete",
    },
    {
      label: "On Hold",
      value: "On Hold",
    },
    {
      label: "Open",
      value: "Open",
    },
    {
      label: "In Progress",
      value: "In Progress",
    },
  ];

  const handleStatusSelect = (selectedOption) => {
    handleInputChange("status", selectedOption.value);
  };
  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleFullScreen = () => {
    setFullScreenMode(true);
  };
  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  const closeTaskNav = () => {
    setIsTaskSidebarOpen(false);
    setIsEdit(false);
    setConstraintsDisplay(false);
  };
  const handleSideNav = () => {
    setFullScreenMode(false);
  };

  const handleSubmit = () => {
    if (formData.title !== "") {
      if (isEdit) {
        setIsTaskSidebarOpen(false);
        setIsEdit(false);
        setConstraintsDisplay(false);
        // If it's an edit, update the existing data
        dispatch(
          postFunGoalDependencies(
            false,
            false,
            formData.title,
            formData.description,
            formData.date,
            formData.status,
            "edit",
            selectedId
          )
        );
      } else {
        dispatch(
          postFunGoalDependencies(
            localStorage.getItem("functional_goal_id"),
            localStorage.getItem("goal_review_id"),
            formData.title,
            formData.description,
            formData.date,
            formData.status,
            "add"
          )
        );
        setIsTaskSidebarOpen(false);
        setConstraintsDisplay(false);
      }
    } else {
      Alert.warning({
        title: "Warning",
        text: "Title should not be empty",
        html: ISError,
        icon: "warning",
        preConfirm: (isConfirm) => {
          if (isConfirm) {
            dispatch(resetExceptionError());
          } else {
          }
        },
      });
    }
  };

  const handleDelete = () => {
    if (isEdit) {
      const updatedItems = dependencyData.filter(
        (item) => item.key !== selectedId
      );
      setDependencyData(updatedItems);
    }
    dispatch(
      postFunGoalDependencies(
        false,
        false,
        false,
        false,
        false,
        false,
        "delete",
        selectedId
      )
    );
    setIsTaskSidebarOpen(false);
  };

  return (
    <>
      <div>
        {isTaskSidebarOpen && (
          <div
            style={{
              width: fullScreenMode && "calc(100% - 50px)",
              height: "92vh",
              overflow: "hidden",
              zIndex: "100",
            }}
            className={"sidenav"}
          >
            <div
              style={{
                border: "1px solid lightgrey",
              }}
              className={"gtm-sidebar-header"}
            >
              <h5
                style={{
                  marginLeft: "20px",
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Constraints & Dependencies
              </h5>
              {!fullScreenMode ? (
                <>
                  {(selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin") && (
                    <a href="#" className="sideMenu" onClick={handleMenuClick}>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </a>
                  )}

                  {isMenuOpen && (
                    <div className="menu">
                      <div className="Edit">
                        <div
                          className="DeleteOption"
                          onClick={(e) => handleDelete()}
                        >
                          <img
                            src={DeleteIcon}
                            width={"18px"}
                            style={{ marginRight: "10px" }}
                            alt="Edit Icon"
                          />
                          Delete
                        </div>
                      </div>
                    </div>
                  )}
                  <a href="#" className="fullscreen" onClick={handleFullScreen}>
                    <AiOutlineCaretRight />
                  </a>
                </>
              ) : (
                <>
                  {(selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin") && (
                    <a href="#" className="sideMenu" onClick={handleMenuClick}>
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </a>
                  )}
                  <a href="#" className="fullscreen" onClick={handleSideNav}>
                    <AiOutlineCaretLeft />
                  </a>
                </>
              )}
              <a href="#" className="closebtn" onClick={() => closeTaskNav()}>
                &times;
              </a>
            </div>
            <div className="gtm-sidebar-body">
              <input
                style={{
                  width: "98%",
                  margin: "auto",
                }}
                className="form-control"
                value={formData.title}
                placeholder="Add your title"
                onChange={(e) => handleInputChange("title", e.target.value)}
                disabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
              />
              <br />
              <div
                className="row"
                style={{
                  marginBottom: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="col-5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h6
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Date
                  </h6>
                  <input
                    style={{ width: "60%", fontSize: "var(--text-font-size)" }}
                    type="date"
                    className="form-control"
                    value={formData.date}
                    onChange={(e) => handleInputChange("date", e.target.value)}
                    disabled={
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? false
                        : true
                    }
                  />
                </div>
                <div
                  className="col-5"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <h6
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginBottom: 0,
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Status
                  </h6>
                  <Select
                    required
                    className="status-select"
                    placeholder={
                      <span style={{ fontSize: "var(--text-font-size)" }}>
                        Select Status
                      </span>
                    }
                    value={statusData.find(
                      (option) => option.value === formData.status
                    )}
                    options={statusData}
                    onChange={handleStatusSelect}
                    isSearchable={true}
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            marginLeft: 5,
                            fontSize: "var(--text-font-size)",
                          }}
                        >
                          {e.label}
                        </span>
                      </div>
                    )}
                    isDisabled={
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? false
                        : true
                    }
                  />
                </div>
              </div>
              <h6
                style={{
                  marginLeft: "10px",
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Description
              </h6>
              <textarea
                disabled={
                  selectedSubMenuPermission === "write" ||
                  selectedSubMenuPermission === "admin"
                    ? false
                    : true
                }
                style={{
                  width: "98%",
                  minHeight: "48vh",
                  margin: "auto",
                  fontSize: "var(--text-font-size)",
                }}
                className="form-control"
                value={formData.description}
                placeholder="Add your description"
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </div>
            <br />
            <div
              className="strategy-sidebar-footer"
              style={{
                display: "flex",
                justifyContent: "right",
                width: "95%",
              }}
            >
              <div
                className="col-1"
                style={{
                  display: "flex",
                  marginTop: "2px",
                }}
              >
                <Button
                  type="button"
                  style={{
                    height: "35px",
                    position: "absolute",
                    top: "8px",
                    right: "150px",
                    padding: "4px 10px",
                    backgroundColor: "var(--button-primary-color)",
                    fontSize: "var(--sub-heading-font-size)",
                    border: "1px solid var(--button-primary-color)",
                  }}
                  onClick={() => handleSubmit()}
                  disabled={
                    selectedSubMenuPermission === "write" ||
                    selectedSubMenuPermission === "admin"
                      ? false
                      : true
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConstraintsPopUp;
