/*jshint esversion: 6 */
import React from "react";
import "./BICard.css";
import { getRandomColor, createImageFromInitials } from "./Utils";
import Image from "../assets/images/BIIcon.svg";
import { useDispatch } from "react-redux";
class Card extends React.Component {
  render() {
    return <div className="bicard">{this.props.children}</div>;
  }
}

class SelectableCard extends React.Component {
  render() {
    return (
      <Card className="column">
        <div data-id={this.props.dataid}>{this.props.children}</div>
      </Card>
    );
  }
}

const SelectableTitleCard = ({
  bi_id,
  icon,
  title,
  status,
  dataid,
  owner,
  navigation_url,
}) => {
  const dispatch = useDispatch();
  const handleBICardClick = (navigation_url) => {
    dispatch({
      type: "SET_SELECTED_BI",
      payload: {
        value: bi_id,
        label: title,
      },
    });
    localStorage.setItem("bi_id",bi_id);
    if (navigation_url) {
      window.location.href = navigation_url;
    } else {
      window.location.href = "/bidetails";
    }
  };
  return (
    <SelectableCard dataid={dataid}>
      <div
        className="bicontent"
        onClick={() => handleBICardClick(navigation_url)}
      >
        <div style={{ display: "flex", padding: "16px", alignItems: "center" }}>
          <div style={{ display: "flex" }}>
            <img alt="" src={icon} />
          </div>
          <div style={{ marginLeft: "10px", paddingTop: "10px" }}>
            <div title={title} className="bi_title">
              {title}
            </div>
            <span className="status_desc">
              {status ? status : "Onboarding"}
            </span>
          </div>
        </div>

        <div className="bibottomline">
          <p className="bidescription">
            <span> Owners:</span>
            <img
              style={{ width: 25, borderRadius: 25, marginLeft: "10px" }}
              id="preview"
              src={createImageFromInitials(30, owner, getRandomColor(owner))}
              alt="profile-pic"
            />
          </p>
        </div>
      </div>
    </SelectableCard>
  );
};

class SelectableCardList extends React.Component {
  render() {
    var { contents } = this.props;
    var content = contents.map((cardContent, i) => {
      var {
        bi_name,
        description,
        id,
        bi_owner,
        last_screen_name,
        navigatation_url,
        name,
      } = cardContent;
      return (
        <div style={{ paddingBottom: "10px" }} key={i}>
          <SelectableTitleCard
            bi_id={name}
            key={i}
            icon={Image}
            title={bi_name}
            description={description}
            dataid={id}
            status={last_screen_name}
            owner={bi_owner}
            navigation_url={navigatation_url}
          />
        </div>
      );
    });
    return <div className="bicardlist">{content}</div>;
  }
}

class Example extends React.Component {
  render() {
    return (
      <SelectableCardList
        multiple={this.props.multiple}
        maxSelectable={this.props.maxSelectable}
        contents={this.props.cardContents}
      />
    );
  }
}

export default Example;
