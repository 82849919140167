import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainComponent from "./MainComponent";
import { getNotes, postNotes } from "../store/actions";
import "./Notes.css";
import React, { useRef } from "react";
import { Excalidraw } from "@excalidraw/excalidraw";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Meeting.css";
import QuillEditor from "../utils/QuillEditor.js";
import Login from "./Login.js";

function Notes() {
  const dispatch = useDispatch();
  const { meetingaction } = useParams();
  const [currentDocURL, setCurrentDocURL] = useState(
    "https://docs.google.com/document/d/1vliqjd6M3hEx3vt90z39LPo0Zxe-1ThEjqjBMYe_FOc/edit"
  );
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);
  const isNotesDataFetched = useSelector((state) => state.isNotesDataFetched);
  const getNotesData = useSelector((state) => state.getNotesData);
  const [meetingName, setMeetingName] = useState("Meeting");
  const [meetingDate, setMeetingDate] = useState("");
  const [truncatedName, setTruncatedName] = useState("");

  const [leftElementOnChange, setLeftElementOnChange] = useState(false);
  const [leftElementLabel, setLeftElementLabel] = useState("");
  const [leftElementAction, setLeftElementAction] = useState("");
  const [leftElementContent, setLeftElementContent] = useState("");
  const sec = 3000;

  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(
        "https://docs.google.com/document/d/1vliqjd6M3hEx3vt90z39LPo0Zxe-1ThEjqjBMYe_FOc/edit"
      );
    }
  }, [isURLAdded]);

  const handleLeftsideOnChange = (content) => {
    setLeftElementOnChange(true);
    setLeftElementContent(content);
  };

  useEffect(() => {
    dispatch(getNotes(localStorage.getItem("note_id"), false, meetingaction));
  }, []);

  useEffect(() => {
    if (isNotesDataFetched) {
      if (getNotesData.data) {
        setMeetingName(getNotesData.meeting_name);
        const updatedName =
          getNotesData.meeting_name.length > 10
            ? getNotesData.meeting_name.slice(0, 10) + "..."
            : getNotesData.meeting_name;
        setTruncatedName(updatedName);
        setMeetingDate(getNotesData.meeting_date);
        getNotesData.data.map((obj) => {
          setLeftElementLabel(obj.action_name);
          setLeftElementContent(obj.content);
          setLeftElementAction(obj.action);
        });
      }
    }
  }, [isNotesDataFetched]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (leftElementOnChange && leftElementContent) {
        let content = leftElementContent;
        if (leftElementAction != "drawing_board") {
          content = leftElementContent.replace(
            'src="http://localhost',
            'src="'
          );
        }
        dispatch(
          postNotes(localStorage.getItem("note_id"), content, leftElementAction)
        );
        setLeftElementOnChange(false);
      }
    }, sec);

    return () => clearInterval(interval);
  }, [leftElementOnChange, leftElementContent]);

  const handleDrOnChange = useCallback((elements, state) => {
    if (
      elements.length > 0 &&
      JSON.stringify(elements) !== JSON.stringify(leftElementContent)
    ) {
      setLeftElementContent((prevData) => {
        const copy = { ...state };
        delete copy["collaborators"];
        return { elements: elements, appState: copy };
      });

      setLeftElementOnChange(true);
    }
  }, []);

  const renderTooltip = () => <Tooltip id="tooltip">{meetingName}</Tooltip>;

  const content = (
    <>
      <Navbar className="custom-navbar" data-bs-theme="dark">
        <Container fluid>
          <Row className="w-100">
            <Col xs="auto">
              <Nav>
                <Nav.Link href="/notes">
                  <IoArrowBack
                    style={{
                      color: "white",
                      padding: 6,
                      borderRadius: 35,
                      border: "1px solid #ccc",
                      fontSize: "35px",
                    }}
                  />
                </Nav.Link>
              </Nav>
            </Col>
            <Col className="d-flex justify-content-end">
              <Nav>
                <Nav.Link href="/logout">
                  <AiOutlineLogout
                    style={{
                      color: "white",
                      marginLeft: 5,
                      padding: 6,
                      borderRadius: 35,
                      border: "1px solid #ccc",
                      fontSize: "35px",
                    }}
                  />
                </Nav.Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <div style={{ marginTop: 20, height: "80vh" }}>
        {leftElementAction !== "drawing_board" ? (
          <div style={{ display: "flex", height: "100%" }}>
            <div
              className="left-element"
              style={{
                // marginTop: 25,
                width: "100%",
                height: "100%",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              <div className="flex" style={{ display: "flex" }}>
                <label
                  className="left-element-label"
                  style={{
                    paddingLeft: "5px",
                    fontWeight: 600,
                    marginRight: "auto", // Aligns to the start
                  }}
                >
                  {leftElementLabel}
                </label>
                <label
                  className="left-element-label"
                  style={{
                    textAlign: "end",
                    fontWeight: 600,
                    marginLeft: "auto", // Aligns to the end
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        minWidth: "40px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "100px",
                        whiteSpace: "nowrap",
                      }}
                      title={meetingName}
                    >
                      {meetingName}
                    </div>
                    <span style={{ marginLeft: "5px" }}>:</span>
                    <span style={{ marginLeft: "5px" }}>{meetingDate}</span>
                  </div>
                </label>
              </div>
              <div style={{ height: "600px" }}>
                <QuillEditor
                  value={leftElementContent}
                  onChange={handleLeftsideOnChange}
                  height={"600px"}
                />
              </div>
            </div>
          </div>
        ) : (
          <Container style={{ height: "78vh" }}>
            <Excalidraw
              initialData={leftElementContent}
              onChange={handleDrOnChange}
            />
          </Container>
        )}
      </div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return <>{content}</>;
  }
}

export default Notes;
