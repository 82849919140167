import React, { useEffect, useState } from "react";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMSDocs } from "../../store/actions";
import FolderIcon from "../../assets/images/folder.svg";
import Login from "../Login";

const Attachmentsummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isURLMatching, setIsURLMatching] = useState(false);
  const getMSDocsdata = useSelector((state) => state.getMSDocsdata);
  const [folderData, setfolderData] = useState([]);
  useEffect(() => {
    if (getMSDocsdata) {
      setfolderData(getMSDocsdata.message);
    }
  }, [getMSDocsdata]);
  useEffect(() => {
    dispatch(getMSDocs(localStorage.getItem("bi_id")));
  }, []);
  const handleBack = () => {
    navigate("/projectsummary", { replace: true });
  };

  const handleClick = () => {
    navigate("/dashboard", { replace: true });
  };

  const content = (
    <>
      <div className="projectsummary">
        <h5>Attachments</h5>
        <div>
          {folderData && <FolderStructure rootFolder={folderData[0]} />}
        </div>
      </div>
    </>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Reports"}
        breadcrumbChild={"Attachment Summary"}
      />
    );
  }
};

export default Attachmentsummary;

const FolderItem = ({ folder, level }) => {
  const padding = `${level * 20}px`;

  return (
    <div style={{ margin: "10px 0px 0px 20px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: "flex", flexDirection: "column", height: "20px" }}
        >
          <p
            style={{ cursor: "pointer", paddingLeft: padding, display: "flex" }}
          >
            <img src={FolderIcon} width={19} style={{ marginRight: "5px" }} />
            {folder.name}
          </p>
        </div>
      </div>
      {folder.children && folder.children.length > 0 && (
        <ul>
          {folder.children.map((child, index) => (
            <li key={index}>
              {child.type === "application/vnd.google-apps.folder" ? (
                <FolderItem folder={child} level={level + 1} />
              ) : (
                <a
                  href={child.web_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingLeft: padding, marginLeft: "20px" }}
                >
                  {child.name}
                </a>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const FolderStructure = ({ rootFolder }) => {
  return (
    <div>{rootFolder && <FolderItem folder={rootFolder} level={0} />}</div>
  );
};
