export const ValueChainInitialState = {
  valueChains: [],
};
export const ValueChainMappingReducer = (state, action) => {
  if (action.type === "INIT") {
    const { valueChains } = action.payload;
    let targetValueChain = [...valueChains];
    if (targetValueChain.length > 0) {
      targetValueChain.activeValueChain = targetValueChain[0];
      targetValueChain.activeValueChain.showEditModal = false;
      targetValueChain.activeValueChain.activeMenu =
        targetValueChain[0].categories[0];
    }

    return { valueChains: targetValueChain };
  } else if (action.type === "VALUE_CHAIN_ADD") {
    const { valueChain } = action.payload;
    const activeVC = { ...valueChain };
    const targetValueChain = [...state.valueChains];
    targetValueChain.push(activeVC);
    targetValueChain.activeValueChain = activeVC;
    targetValueChain.activeValueChain.activeMenu = activeVC.categories[0];
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));
    let newState = {
      ...state,
      valueChains: targetValueChain,
    };
    return newState;
  } else if (action.type === "VALUE_CHAIN_REMOVE") {
    const { vcKey } = action.payload;
    let targetValueChain = [...state.valueChains];
    const objWithIdIndex = targetValueChain.findIndex(
      (obj) => obj.key === vcKey
    );
    targetValueChain.splice(objWithIdIndex, 1);
    if (targetValueChain.length > 0) {
      targetValueChain.activeValueChain = targetValueChain[0];
      targetValueChain.activeValueChain.activeMenu =
        targetValueChain[0].categories[0];
    }
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };
    return newState;
  } else if (action.type === "VALUE_CHAIN_CHANGE") {
    const { valueChain } = action.payload;
    let targetValueChain = [...state.valueChains];
    targetValueChain.activeValueChain = valueChain;
    targetValueChain.activeValueChain.activeMenu = valueChain.categories.filter(
      (c) => c.isactive === true
    )[0];
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };
    return newState;
  } else if (action.type === "MENU_CHANGE") {
    const { category, vcKey } = action.payload;

    const currentValueChain = {
      ...state.valueChains.filter((vc) => vc.key === vcKey)[0],
    };
    const targetValueChain = [...state.valueChains];

    targetValueChain.activeValueChain = currentValueChain;
    targetValueChain.activeValueChain.activeMenu = category;
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };

    return newState;
  } else if (action.type === "ON_SELECTION_CHANGE") {
    const { valueChain: updatedValueChain } = action.payload;

    let targetValueChain = [...state.valueChains];

    targetValueChain = targetValueChain.map((obj) => {
      if (obj.key === updatedValueChain.key) {
        return updatedValueChain;
      }
      return obj;
    });
    targetValueChain.activeValueChain = updatedValueChain;
    if (updatedValueChain.categories.length > 0) {
      targetValueChain.activeValueChain.activeMenu =
        updatedValueChain.categories.filter(
          (c) => c.key === updatedValueChain.activeMenu.key
        )[0];
    }
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };

    return newState;
  } else if (action.type === "COLUMNS_CHANGE") {
    const { updated: updatedValueChain } = action.payload;
    let targetValueChain = [...state.valueChains];

    targetValueChain = targetValueChain.map((obj) => {
      if (obj.key === updatedValueChain.key) {
        return updatedValueChain;
      }
      return obj;
    });
    targetValueChain.activeValueChain = updatedValueChain;
    if (updatedValueChain.categories.length > 0) {
      targetValueChain.activeValueChain.activeMenu =
        updatedValueChain.categories.filter((c) => c.isactive === true)[0];
    }
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };

    return newState;
  } else if (action.type === "TOGGLE_EDIT_MODAL") {
    const { vcKey } = action.payload;
    const targetValueChain = [...state.valueChains];
    targetValueChain.activeValueChain = vcKey;
    targetValueChain.activeValueChain.activeMenu = vcKey.categories[0];
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };

    return newState;
  } else if (action.type === "PRODUCTS_CHANGE") {
    const { valueChain: updatedValueChain } = action.payload;
    let targetValueChain = [...state.valueChains];

    targetValueChain = targetValueChain.map((obj) => {
      if (obj.key === updatedValueChain.key) {
        return updatedValueChain;
      }
      return obj;
    });
    targetValueChain.activeValueChain = updatedValueChain;
    targetValueChain.activeValueChain.activeMenu = updatedValueChain.activeMenu;
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };

    return newState;
  } else if (action.type === "VC_UPDATE") {
    const { valueChain: updatedValueChain } = action.payload;
    let targetValueChain = [...state.valueChains];

    targetValueChain = targetValueChain.map((obj) => {
      if (obj.key === updatedValueChain.key) {
        return updatedValueChain;
      }
      return obj;
    });
    targetValueChain.activeValueChain = updatedValueChain;
    if (updatedValueChain.activeMenu) {
      targetValueChain.activeValueChain.activeMenu =
        updatedValueChain.activeMenu;
    } else {
      targetValueChain.activeValueChain.activeMenu =
        updatedValueChain.categories[0];
    }
    localStorage.setItem("vc_json", JSON.stringify(targetValueChain));

    let newState = {
      ...state,
      valueChains: targetValueChain,
    };

    return newState;
  }

  return state;
};
