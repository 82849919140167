/*jshint esversion: 6 */
import React from "react";
import "./MetricsCard.css";
import Image from "../assets/images/Checkmark.png";
import OtherImage from "../assets/images/hash.svg";
import Logo from "../assets/images/logo.svg";
import { url } from "../store/sagas/API";
import { BsPencil } from "react-icons/bs";

class Card extends React.Component {
  render() {
    return <div className="metrics-card">{this.props.children}</div>;
  }
}

class SelectableCard extends React.Component {
  render() {
    var isSelected = this.props.selected ? "selected" : "";
    var className = "selectable " + isSelected;
    return (
      <Card className="metrics-column">
        <div
          className={className}
          data-id={this.props.dataid}
          onClick={this.props.onClick}
          data-ismaster={this.props.is_master}
        >
          {this.props.children}
          {!this.props.is_master && (
            <div onClick={(e) => e.stopPropagation()}>
              <button
                style={{
                  marginLeft: 10,
                  background: "white",
                  height: 17,
                  width: 17,
                  borderRadius: 17,
                  border: "thin ridge",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  position: "absolute",
                  top: 10,
                  right: 30,
                  marginTop: 3,
                }}
                type="button"
                onClick={(e) =>
                  this.props.EditMetric(
                    this.props.dataid,
                    this.props.title,
                    this.props.description,
                    this.props.type
                  )
                }
              >
                <span style={{ fontSize: 10, paddingBottom: 2 }}>
                  <BsPencil />
                </span>
              </button>
              <button
                style={{
                  marginLeft: 5,
                  background: "white",
                  height: 17,
                  width: 17,
                  borderRadius: 17,
                  border: "thin ridge",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  position: "absolute",
                  top: 10,
                  right: 10,
                  marginTop: 3,
                }}
                type="button"
                onClick={(e) => this.props.RemoveMetric(this.props.dataid)}
              >
                <span style={{ fontSize: 12, paddingBottom: 2 }}>x</span>
              </button>
            </div>
          )}
          <div
            className="check"
            style={{ marginRight: this.props.is_master ? 10 : 50 , zIndex:"0"}}
          >
            <img alt="" src={Image} className="checkmark" />
          </div>
        </div>
      </Card>
    );
  }
}

class SelectableTitleCard extends React.Component {
  render() {
    var {
      icon,
      title,
      description,
      selected,
      dataid,
      isrecommended,
      is_master,
      type,
    } = this.props;

    return (
      <SelectableCard
        onClick={this.props.onClick}
        EditMetric={this.props.EditMetric}
        RemoveMetric={this.props.RemoveMetric}
        selected={selected}
        dataid={dataid}
        is_master={is_master}
        title={title}
        type={type}
        description={description}
      >
        <div className="metrics-content">
          <img
            alt=""
            style={{ width: 50, height: 50 }}
            className="icon"
            src={icon}
          />
          <h4 className="metrics-title">{title}</h4>
          <h6 className="metrics-description">{description}</h6>
        </div>
        {isrecommended && (
          <div className="metrics-recommended">
            <img
              alt=""
              // style={{ width: 50, height: 50 }}
              className="icon"
              src={Logo}
            />
            <p>Recommended</p>
          </div>
        )}
      </SelectableCard>
    );
  }
}

class SelectableCardList extends React.Component {
  constructor(props) {
    super(props);
    var selected = props.multiple ? [] : -1;
    var initialState = {
      selected: selected,
      selectionchange: false,
    };
    this.state = initialState;
  }

  onItemSelected(index) {
    this.setState((prevState, props) => {
      if (props.multiple) {
        var selectedIndexes = prevState.selected;
        var selectedIndex = selectedIndexes.indexOf(index);
        if (selectedIndex > -1) {
          selectedIndexes.splice(selectedIndex, 1);
          props.onChange(selectedIndexes);
        } else {
          if (!(selectedIndexes.length >= props.maxSelectable)) {
            selectedIndexes.push(index);
            props.onChange(selectedIndexes);
          }
        }
        return {
          selected: selectedIndexes,
          selectionchange: true,
        };
      } else {
        props.onChange(index);
        return {
          selected: index,
        };
      }
    });
  }

  render() {
    var { contents, multiple, EditMetric, RemoveMetric } = this.props;
    var content = contents.map((cardContent, i) => {
      var {
        key,
        icon,
        title,
        description,
        selected,
        isselected,
        isrecommended,
        is_master,
        type,
      } = cardContent;
      var selected = multiple
        ? this.state.selected.indexOf(i) > -1
        : this.state.selected == i;
      if (!this.state.selectionchange && isselected) {
        this.state.selected.push(i);
        selected = this.state.selected;
      }
      return (
        <SelectableTitleCard
          key={i}
          icon={icon ? url + icon : OtherImage}
          title={title}
          description={description}
          selected={selected}
          dataid={key}
          type={type}
          isrecommended={isrecommended}
          is_master={is_master}
          onClick={(e) => this.onItemSelected(i)}
          EditMetric={EditMetric}
          RemoveMetric={RemoveMetric}
        />
      );
    });
    return <div className="metrics-cardlist">{content}</div>;
  }
}

class Example extends React.Component {
  onListChanged(selected) {
    this.setState({
      selected: selected,
      selectionchange: true,
    });
  }
  submit() {
    window.alert("Selected: " + this.state.selected);
  }
  render() {
    return (
      <div className="" style={{ height: "90%" }}>
        <h1 className="metrics-title" style={{ paddingBottom: 10 }}>
          {this.props.title}
        </h1>
        <SelectableCardList
          multiple={this.props.multiple}
          maxSelectable={this.props.maxSelectable}
          contents={this.props.cardContents}
          onChange={this.onListChanged.bind(this)}
          EditMetric={this.props.EditMetric}
          RemoveMetric={this.props.RemoveMetric}
        />
      </div>
    );
  }
}

export default Example;
